<template>
  <!--性能稼动率日报表-->
  <div class="page-container" ref="boxDiv" v-loading="loading">
    <!--页头-->
    <div class="page-header" ref="myDiv">
      <div style="display: flex;">
        <div class="select-time">
          <div :class="{active: selectTime === 1 }" @click="handleTime(1)">日报</div>
          <div :class="{active: selectTime === 2 }" @click="handleTime(2)">周报</div>
          <div :class="{active: selectTime === 3 }" @click="handleTime(3)">月报</div>
        </div>
      </div>
      <div class="search-box2" v-if="selectTime === 1">
        <el-date-picker
            v-model="date"
            type="date"
            :picker-options="pickerOptionsStart"
            @change="handleDateConfirm"
            placeholder="选择日期">
        </el-date-picker>
      </div>

      <div class="search-box2" style="width: 750px;" v-show="selectTime === 2">
        <!--选择日期-->
        <el-select style="width: 100%;" v-model="time" multiple placeholder="请选择" :multiple-limit="4"
                   @change="handleSelectTime">
          <el-option
              v-for="item in dateArry"
              :key="item.value"
              :label="item.value"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-box2" style="width: 310px;" v-show="selectTime === 3">
        <div class="month-time">
          <el-date-picker
              style="width: 94%;display: block"
              type="months"
              v-model="timeMonth"
              placeholder="选择一个或多个月"
              :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </div>
      <el-button class="loading-btn" v-show="selectTime === 1 " type="primary" @click="handleLoading">
        下载
      </el-button>
      <el-button class="loading-btn" v-show="selectTime === 2 " type="primary" @click="handleLoadingWeek">
        下载
      </el-button>
      <el-button class="loading-btn" v-show="selectTime === 3 " type="primary" @click="handleLoadingMonth">
        下载
      </el-button>
    </div>

    <!--主体-->
    <div v-show="selectTime === 1" class="page-main" id="section-box" style="width: 1000px;"
         :style=" selectShow ? '' : 'margin: 0 auto 0'" >
      <div style="position: relative;font-size: 0">
        <img class="frontPage" src="@/assets/frontPage.png" alt="">
        <div class="year">{{ year }}</div>
        <div class="title-name">印刷管家数据报告</div>
        <div class="company-name">{{ teamName }}</div>
        <div class="info-box">
          <img src="@/assets/time-icon.png" alt="">
          <img src="@/assets/time-icon2.png" alt="">
          <div>
            <ul>
              <li>数据日期: <span>{{ dateTime }}</span></li>
              <li>{{ dateDay }}</li>
              <li>印工社（青岛）数字科技有限公司</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section">
        <!--        <div>-->
        <!--          <img src="@/assets/frontPage.png" alt="">-->
        <!--        </div>-->
        <div style="position: relative" class="label-icon">
          <!--          <img class="logo" src="@/assets/logoReport.png" alt="">-->
          <img class="label-icon-img" src="@/assets/label-icon.png" alt="">
        </div>
        <div class="title-box" :style="formDate.ysslInfo.length < 2 ? 'margin-bottom: 0px' : ''">
          <img src="@/assets/title-icon.png" alt="">
          <div>1.印刷数量</div>
        </div>
        <div class="echart-title" v-if="formDate.ysslInfo.length > 1">
          <div>单位:张</div>
          <div class="time-data">实际印刷数量/产能利用率</div>
        </div>
        <div style="display: flex;" v-if="formDate.ysslInfo.length > 1">
          <BarLineChart
              style="width: 100%"
              v-if="yssl.source.length > 0"
              :id="'chart'"
              :data="yssl"
              :legend-show="true"
              :grid-left="30"
              :gridLeft="5"
              :percent="'%'"
              :height="350"
              :gridRight="15"
              :left="'center'"
              :smooth="false"
              :gridTop="35"
          />
        </div>
        <div class="color-box" v-if="formDate.ysslInfo.length > 1">
          <div v-for="(item,index) in name" :key="index" style="display: inline-block" class="color-line">
            <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span><span>{{ item }}</span>
          </div>
        </div>
        <!--        <div class="color-box" v-if="formDate.ysslInfo.length > 0">-->
        <!--          <div v-for="(item,index) in name" :key="index" style="display: inline-block">-->
        <!--            <div class="color-line">-->
        <!--              <div :style="{ backgroundColor: colorArr[index] }" class="color-item"></div><div class="color-name">{{ item }}</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="div-padding">
          <div>
            <el-table :data="ysslData" border :row-class-name="tableRowClassName" width="100%">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="bzyl" label="标准印量(张)" align="center">
                <template slot-scope="scope">
                  {{ scope.row.bzyl }}
                </template>
              </el-table-column>
              <el-table-column prop="sjyl" label="实际印量(张)" align="center">
                <template slot-scope="scope">
                  {{ scope.row.sjyl }}
                </template>
              </el-table-column>
              <el-table-column prop="ylRate" label="产能利用率" align="center">
                <template slot-scope="scope">
                  {{ scope.row.ylRate }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="div-margin">
          <div class="copywriter">
            <div>数据描述:</div>
            <div style="white-space: pre-wrap;">{{ formDate.ylDescribe }}</div>
          </div>
        </div>
        <div>
          <div class="title-box2" style="margin-bottom: 0"
               :style="formDate.ysslInfo.length < 2 ? 'margin-bottom: 0px' : ''">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.性能稼动率</div>
          </div>
          <div class="div-padding" v-if="formDate.ysslInfo.length > 1">
            <div class="echart-title" >
              <div>单位:印张/小时</div>
              <div class="time-data">实际运行速度 / 性能稼动率</div>
            </div>
            <div style="display: flex;" v-if="formDate.ysslInfo.length > 1">
              <BarLineChart
                  style="width: 100%"
                  v-if="xnjdl.source.length > 0"
                  :id="'chart4'"
                  :data="xnjdl"
                  :legend-show="false"
                  :grid-left="30"
                  :gridLeft="5"
                  :height="350"
                  :percent="'%'"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
            </div>
            <div class="color-box" v-if="formDate.ysslInfo.length > 1">
              <div v-for="(item,index) in name" :key="index" style="display: inline-block" class="color-line">
                <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span>{{ item }}
              </div>
            </div>
          </div>
        </div>

        <div class="div-padding">
          <div>
            <el-table :data="xnjdlData" border :row-class-name="tableRowClassName">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="bzsd" label="额定运行速度" align="center">
              </el-table-column>
              <el-table-column prop="sjsd" label="实际运行速度" align="center">
              </el-table-column>
              <el-table-column prop="sdRate" label="性能稼动率" align="center">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="div-margin">
          <div class="copywriter" style="margin-bottom: 0;margin-top: 0">
            <div>数据描述:</div>
            <div style="white-space: pre-wrap;">{{ formDate.sdDescribe }}</div>
          </div>
        </div>
        <div>
          <div class="div-padding">
            <div class="title-box3" :style="formDate.ysslInfo.length < 2 ? 'margin-bottom: 0px' : ''">
              <img src="@/assets/title-icon.png" alt="">
              <div>3.有效时间稼动率</div>
            </div>
          </div>
          <div class="div-padding" v-if="formDate.ysslInfo.length >1">
            <div class="echart-title">
              <div>单位:分钟/天 <br>单位:小时/天</div>
              <div class="time-data">有效印刷时长 / 有效时间稼动率</div>
            </div>
          </div>
          <div style="display: flex;padding-top: 10px" v-if="formDate.ysslInfo.length > 1">
            <BarLineChart
                style="width: 100%"
                v-if="sjjdl.source.length > 0"
                :id="'chart6'"
                :data="sjjdl"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="5"
                :percent="'%'"
                :height="350"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
          <div class="color-box" v-if="formDate.ysslInfo.length > 1">
            <div v-for="(item,index) in name" :key="index" style="display: inline-block" class="color-line">
              <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span>{{ item }}
            </div>
          </div>
        </div>
        <div>
          <div class="div-padding">
            <el-table :data="sjjdlData" border :row-class-name="tableRowClassName" width="100%">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <!--              <el-table-column prop="bzTime" label="额定运行时长(分钟/小时)" width="85px" align="center">-->
              <!--                <template slot-scope="scope">-->
              <!--                  {{ scope.row.bzTime }} / {{ scope.row.bzHour }}-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <!--        width="85px"       -->
              <el-table-column prop="sjTime" label="有效印刷时长(分钟/小时)" align="center">
                <template slot="header">
                  <span>有效印刷时长<br>(分钟/小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.sjTime }} / {{ scope.row.sjHour }}
                </template>
              </el-table-column>
              <!--       width="70px"        -->
              <el-table-column prop="timeRate" label="有效时间稼动率" align="center">
                <template slot-scope="scope">
                  {{ scope.row.timeRate }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="div-margin">
          <div class="copywriter" style="margin-bottom: 0">
            <div>数据描述:</div>
            <div style="white-space: pre-wrap;">{{ formDate.timeDescribe }}</div>
          </div>
        </div>
        <div>
          <div class="div-padding">
            <div class="title-box3" >
              <img src="@/assets/title-icon.png" alt="">
              <div>4.异常待机</div>
            </div>
          </div>
          <div class="div-padding" v-show="formDate.ysslInfo.length > 1">
            <div class="echart-title2">
              <div>单位:分/天 <br>单位:次/天</div>
              <div class="time-data2">待机时长（分钟）/ 待机次数（次）</div>
            </div>
          </div>
          <div style="display: flex;" v-if="formDate.ysslInfo.length > 1">
            <BarLineChart
                style="width: 100%"
                v-if="ycdj.source.length > 0"
                :id="'chart8'"
                :data="ycdj"
                :legend-show="false"
                :grid-left="30"
                :height="350"
                :gridLeft="5"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
          <div class="color-box" v-if="formDate.ysslInfo.length > 1">
            <div v-for="(item,index) in name" :key="index" style="display: inline-block" class="color-line">
              <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span>{{ item }}
            </div>
          </div>
        </div>

        <div class="div-padding" v-if="formDate.ysslInfo.length > 1">
          <div class="echart-title echarts-title echarts-title2">
            <div>单位：次/天</div>
            <div>待机原因已提交（次）/ 待机原因未提交（次）</div>
          </div>
          <div style="display: flex;">
            <BarLineChart
                style="width: 100%"
                v-if="reason.source.length > 0"
                :id="'chart10'"
                :data="reason"
                :legend-show="false"
                :grid-left="30"
                :height="350"
                :gridLeft="5"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
          <div class="color-box">
            <div v-for="(item,index) in name" :key="index" style="display: inline-block" class="color-line">
              <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span><span
                :style="{ backgroundColor: DeepColorArrDevice[index] }" class="color-item"></span>{{ item }}
            </div>
          </div>
        </div>
        <div class="div-padding" v-if="formDate.ysslInfo.length > 1">
          <div class="echart-title echarts-title echarts-title2">
            <div>单位：次/天</div>
            <div>待机原因已审核（次）/ 待机原因未审核（次）</div>
          </div>
          <div style="display: flex;">
            <BarLineChart
                style="width: 100%"
                v-if="audit.source.length > 0"
                :id="'chart12'"
                :data="audit"
                :height="350"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="5"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
          <div class="color-box">
            <div v-for="(item,index) in name" :key="index" style="display: inline-block" class="color-line">
              <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span><span
                :style="{ backgroundColor: DeepColorArrDevice[index] }" class="color-item"></span>{{ item }}
            </div>
          </div>
        </div>
        <div class="div-padding">
          <el-table :data="formDate.ysslInfo" border :row-class-name="tableRowClassName" width="100%"
                    class="el-table-statistics reson-table reson-table2 ">
            <el-table-column prop="name" label="设备" width="180" align="center">
            </el-table-column>
            <el-table-column prop="panelName" label="待机状况" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>时长<br>(分钟)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.abnormalTime }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.abnormalCount }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" label="待机原因提交状况" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>原因<br>已提交</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.ytj }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>原因<br>未提交</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.wtj }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" label="待机原因审核状况" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>原因<br>已审核</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.ysh }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>原因<br>未审核</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.wsh }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>

        <div style="padding-top: 10px">
          <div class="copywriter" style="margin-bottom: 0">
            <div>数据描述:</div>
            <div style="white-space: pre-wrap;">{{ formDate.abnormalDescribe }}</div>
          </div>
        </div>
        <div class="div-padding">
          <div class="title-box2 " style="margin-top: 0;margin-bottom: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>5.异常待机主要原因统计</div>
          </div>
          <div v-if="formDate.ysslInfo.length > 1" class="reason-box">
            <div class="div-margin" v-if="formDate.ysslInfo[1].name !== '合计' ">
              <div class="reason-name">
                <span>{{ formDate.ysslInfo[1].name }}</span><span>未填写的次数({{
                  formDate.ysslInfo[1].wtj
                }})和时长({{ formDate.ysslInfo[1].wtjTime }})</span>
              </div>
              <div class="echart-title-reason">
                <div>已填写-次数/时长</div>
              </div>
              <BarLineChart
                  style="width: 100%"
                  v-if="formDate.ysslInfo[1].listDeviceTotal.source.length > 1"
                  :id="`chartDate`"
                  :data="formDate.ysslInfo[1].listDeviceTotal"
                  :legend-show="false"
                  :grid-left="30"
                  :height="350"
                  :gridLeft="5"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
              <div class="color-box">
                <div v-for="(item,index) in formDate.ysslInfo[1].reasonName" :key="index" style="display: inline-block"
                     class="color-line">
                  <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span>{{ item }}
                </div>
              </div>
            </div>

          </div>
        </div>
        <div v-if="formDate.ysslInfo.length > 1">
          <div v-for="(item,index) in formDate.ysslInfo.slice(2)" :key="index" class="reason-box">
            <div class="div-margin" v-if="item.name !== '合计' ">
              <div class="reason-name">
                <span>{{ item.name }}</span><span>未填写的次数({{ item.wtj }})和时长({{ item.wtjTime }})</span></div>
              <div class="echart-title-reason">
                <div>已填写-次数/时长</div>
              </div>
              <BarLineChart
                  style="width: 100%"
                  v-if="item.listDeviceTotal.source.length > 1"
                  :id="`chart12 ${index}`"
                  :data="item.listDeviceTotal"
                  :legend-show="false"
                  :grid-left="30"
                  :height="350"
                  :gridLeft="5"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
              <div class="color-box">
                <div v-for="(item,index) in item.reasonName" :key="index" style="display: inline-block"
                     class="color-line">
                  <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span>{{ item }}
                </div>
              </div>
            </div>

          </div>
        </div>

        <!--<div>
          <div class="statistics">
            <div>单位:次/天</div>
            <div>(1)待机次数主要原因统计</div>
          </div>
            <el-table :data="ycdjData" border :row-class-name="tableRowClassName" class="el-table-statistics reson-table" >
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="shiYinJiaoSe" label="试印校色" width="32px" align="center">
              </el-table-column>
              <el-table-column prop="qianYangDengDai" label="签样等待" width="32px" align="center">
              </el-table-column>
              <el-table-column prop="luoBanGaiWenJian" label="落版改文件" width="32px" align="center">
              </el-table-column>
              <el-table-column prop="tiaoZhuanSeDengDai" label="调专色等待" width="32px" align="center">
              </el-table-column>
              <el-table-column prop="caiLiaoYuanYin" label="材料原因" width="32px" align="center">
              </el-table-column>
              <el-table-column prop="guZhangQiangXiu" label="故障抢修" width="32px" align="center">
              </el-table-column>
              <el-table-column prop="dengLiao" label="等料" width="32px" align="center">
              </el-table-column>
              <el-table-column prop="qiTaYuanYin" label="其他原因" width="32px" align="center">
              </el-table-column>
            </el-table>
        </div>
        <div>
          <div class="statistics">
            <div>单位:小时/天</div>
            <div>(2) 待机时长主要原因统计</div>
          </div>
          <el-table :data="timeData" border :row-class-name="tableRowClassName" class="el-table-statistics reson-table" >
            <el-table-column prop="name" label="设备" align="center">
            </el-table-column>
            <el-table-column prop="shiYinJiaoSeTime" label="试印校色" width="32px" align="center">
            </el-table-column>
            <el-table-column prop="qianYangDengDaiTime" label="签样等待" width="32px" align="center">
            </el-table-column>
            <el-table-column prop="luoBanGaiWenJianTime" label="落版改文件" width="32px" align="center">
            </el-table-column>
            <el-table-column prop="tiaoZhuanSeDengDaiTime" label="调专色等待" width="32px" align="center">
            </el-table-column>
            <el-table-column prop="caiLiaoYuanYinTime" label="材料原因" width="32px" align="center">
            </el-table-column>
            <el-table-column prop="guZhangQiangXiuTime" label="故障抢修" width="32px" align="center">
            </el-table-column>
            <el-table-column prop="dengLiaoTime" label="等料" width="32px" align="center">
            </el-table-column>
            <el-table-column prop="qiTaYuanYinTime" label="其他原因" width="32px" align="center">
            </el-table-column>
          </el-table>
        </div>-->
        <div class="div-margin">
          <div class="copywriter" style="margin-bottom: 0">
            <div>材料包括:</div>
            <div>印版、纸张、油墨、胶辊、橡皮布、润版液、喷粉、光油</div>
          </div>
        </div>
        <div style="padding-top: 25px">
          <div style="position: relative;margin-top: 0" class="label-icon">
            <img class="label-icon-img" src="@/assets/label-icon2.png" alt="">
          </div>
          <div class="title-box" style="margin-bottom: 0px">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.印刷设备维保</div>
          </div>
          <div class="div-padding">
            <el-table :data="formDate.ysInfo" border :row-class-name="tableRowClassName" width="100%"
                      class="el-table-statistics" style="margin-bottom: 0">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="panelName" label="当日保养" align="center">
                <el-table-column prop="value1" label="保养日保"  align="center">
                  <template slot-scope="scope">
                    {{ scope.row.byrb }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检日保" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.djrb }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="日保养完成率" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.rbyRate }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="div-padding">
          <div class="title-box" style="margin-bottom: 0px;margin-top: 0;padding-top: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.印前设备维保</div>
          </div>
          <div class="div-padding">
            <el-table :data="formDate.yqInfo" border :row-class-name="tableRowClassName" width="100%"
                      style="margin-top: 0">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="panelName" label="当日保养" align="center">
                <el-table-column prop="value1" label="保养日保" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.byrb }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检日保" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.djrb }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="日保养完成率" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.rbyRate }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="div-margin">
          <div class="copywriter" style="margin-top: 0px">
            <div>数据描述:</div>
            <div style="white-space: pre-wrap;">{{ formDate.maintainDescribe }}</div>
          </div>
        </div>
        <div>
          <div style="position: relative" class="label-icon">
            <!--          <img class="logo" src="@/assets/logoReport.png" alt="">-->
            <img class="label-icon-img" src="@/assets/label-icon3.png" alt="">
          </div>
          <div class="title-box">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.PQES报告上传</div>
          </div>
          <div>
            <div class="statistics2" v-if="this.formDate.pqesInfos.length > 0">
              <!--              <div>单位:分钟/小时</div>-->
              <div>实际上传次数/ 日平均得分</div>
            </div>
            <!--            <div :id="`selected`" class="echatsImg"></div>-->
            <BarLineChart2
                v-if="pqesData.source.length > 0 && this.formDate.pqesInfos.length > 0"
                :id="'chart13'"
                :data="pqesData"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="15"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
            />
            <div class="color-box" v-if="this.formDate.pqesInfos.length > 0">
              <div v-for="(item,index) in namePQES" :key="index" style="display: inline-block" class="color-line">
                <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span>{{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="div-padding">
          <el-table :data="formDate.pqesInfos" border :row-class-name="tableRowClassName"
                    :span-method="objectSpanMethod"
                    class="el-table-statistics " style="margin-bottom: 0">
            <el-table-column prop="name" label="设备"  align="center">
            </el-table-column>
            <el-table-column prop="panelName" label="班组" align="center">
              <template slot-scope="scope">
                {{ scope.row.groupName }}
              </template>
            </el-table-column>
            <el-table-column prop="value1" label="应上传次数"  align="center">
              <template slot-scope="scope">
                {{ scope.row.bzCount }}
              </template>
            </el-table-column>
            <el-table-column prop="value2" label="实际上传次数"  align="center">
              <template slot-scope="scope">
                {{ scope.row.sjCount }}
              </template>
            </el-table-column>
            <el-table-column prop="value2" label="平均得分"  align="center">
              <template slot-scope="scope">
                {{ scope.row.score }}
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!--        <div style="padding-top: 10px">-->
        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div></div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div style=";background-color: #fff;" :style="selectShow ? 'padding-top: 25px' : 'padding-top: 0' ">
        <img class="frontPage" src="@/assets/frontPage2.jpg" alt="">
      </div>
    </div>
    <div v-if="selectTime === 2" class="page-main" id="section-box2" style="width: 1000px;"
         :style=" selectShow ? '' : 'margin: 0 auto 0;'" >
      <div style="position: relative;font-size: 0">
        <img class="frontPage" src="@/assets/frontPage.png" alt="">
        <div class="year">{{ year }}</div>
        <div class="title-name">印刷管家数据报告</div>
        <div class="company-name">{{ teamName }}</div>
        <div class="info-box">
          <img src="@/assets/time-icon.png" alt="">
          <img src="@/assets/time-icon2.png" alt="">
          <div>
            <ul>
              <li>数据日期: <span>4个周</span></li>
              <li>{{ dateDay }}</li>
              <li>印工社（青岛）数字科技有限公司</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section">
        <div style="position: relative" class="label-icon">
          <!--          <img class="logo" src="@/assets/logoReport.png" alt="">-->
          <img class="label-icon-img" src="@/assets/label-icon.png" alt="">
        </div>
        <div class="title-first" style="margin: 20px 0">一、印刷数量</div>
        <div class="title-box">
          <img src="@/assets/title-icon.png" alt="">
          <div>1.印厂总印刷数量</div>
        </div>
        <div class="echart-title" v-if="formDateWeek.yinLiangs.length > 0">
          <div>单位:张/周</div>
          <div class="time-data">实际印刷数量/产能利用率</div>
        </div>
        <div style="display: flex;" v-if="formDateWeek.yinLiangs.length > 0">
          <BarLineChart
              style="width: 100%"
              v-if="ysslWeek.source.length > 0"
              :id="'weekChart'"
              :data="ysslWeek"
              :legend-show="true"
              :grid-left="30"
              :gridLeft="5"
              :height="350"
              :percent="'%'"
              :gridRight="15"
              :left="'center'"
              :smooth="false"
              :gridTop="35"
          />
        </div>
        <div class="div-padding">

          <el-table :data="ysslDataZong" border :row-class-name="tableRowClassName" width="100%">
            <el-table-column prop="name" label="时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
              </template>
            </el-table-column>
            <!--            <el-table-column prop="value" label="周标准印量" width="70px" align="center">-->
            <!--              <template slot-scope="scope">-->
            <!--                {{ parseInt(scope.row.value) }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--     width="70px"       -->
            <el-table-column prop="value1" label="周实际印量"  align="center">
              <template slot-scope="scope">
                {{ parseInt(scope.row.value1) }}
              </template>
            </el-table-column>
            <!--      width="100px"      -->
            <el-table-column prop="value2" :label="`周平均产能利用率`"  align="center">
              <template slot-scope="scope">
                {{ scope.row.value2 / 10 }}%
              </template>
            </el-table-column>
          </el-table>
          <!--          <div class="point">备注：本数据只展示整数部分，小数部分未运算</div>-->
        </div>
        <div class="title-box">
          <img src="@/assets/title-icon.png" alt="">
          <div>2.单机台(4周)印刷数量</div>
        </div>

        <el-select v-model="device" multiple placeholder="请选择设备"
                   v-show="selectShow"
                   @change="handleSelectDevice" style="width: 100%;margin-bottom: 25px"
                   :style="formDateWeek.xingNengs.length < 2 ? 'margin-bottom: 0px' : ''">
          <el-option
              v-for="item in deviceName"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
        <div style="" v-if="formDateWeek.yinLiangs.length > 0">
          <div v-for="(item,key) in chartList" :key="key" class="device-div device-divNumber">
            <div v-if="item.show !== false" class="service div-padding">{{ item.name }}</div>
            <div class="echart-title echart-title-week" v-if="item.show !== false">
              <div>单位:张</div>
              <div class="time-data">实际印刷数量/产能利用率</div>
            </div>
            <BarLineChart
                style="width: 100%"
                class="echarts-div"
                v-if="item.chartData.source.length > 0 && item.chartData && item.show !== false"
                :id="`chart22${key}`"
                :data="item.chartData"
                :legend-show="true"
                :grid-left="30"
                :gridLeft="5"
                :height="350"
                :percent="'%'"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
        </div>
        <div class="table-pad div-padding">

          <el-table :data="ysslDataWeek" border :row-class-name="tableRowClassName" width="100%">
            <el-table-column prop="name" label="设备" width="200" align="center">
            </el-table-column>
            <el-table-column prop="value" label="标准印量" align="center">
              <template slot-scope="scope">
                {{ parseInt(scope.row.value) }}
              </template>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
              <el-table-column prop="value1" label="周印量" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>产能<br>利用率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value2 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
              <el-table-column prop="value1" label="周印量" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value3) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>产能<br>利用率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value4 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
              <el-table-column prop="value1" label="周印量" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value5) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>产能<br>利用率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value6 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
              <el-table-column prop="value1" label="周印量" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value7) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>产能<br>利用率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value8 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <!--          <div class="point">备注：本数据只展示整数部分，小数部分未运算</div>-->
        </div>
        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div style="white-space: pre-wrap;">{{ formDateWeek.ylDescribe }}</div>-->
        <!--          </div>-->
        <div class="div-padding">
          <div class="title-first" style="margin-top: 0">二、性能稼动率</div>
          <div class="title-box">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.印厂总性能稼动率</div>
          </div>
          <div>
            <div class="echart-title" v-if="formDateWeek.xingNengs.length > 0">
              <div>单位:印张/小时</div>
              <div class="time-data">实际运行速度 / 性能稼动率</div>
            </div>
            <div style="display: flex;" v-if="formDateWeek.xingNengs.length > 0">
              <BarLineChart
                  style="width: 100%"
                  v-if="xnjdlWeek.source.length > 0"
                  :id="'weekChart4'"
                  :data="xnjdlWeek"
                  :legend-show="false"
                  :grid-left="30"
                  :height="350"
                  :gridLeft="5"
                  :percent="'%'"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
            </div>
          </div>
        </div>
        <div class="div-padding">
          <el-table :data="xnjdlDataZong" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
              </template>
            </el-table-column>
            <!--            <el-table-column prop="bzsd" label="周额定速度" width="65px" align="center">-->
            <!--              <template slot-scope="scope">-->
            <!--                {{ scope.row.value }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--     width="85px"       -->
            <el-table-column prop="value1" label="周平均运行速度"  align="center">
              <template slot-scope="scope">
                {{ scope.row.value1 }}
              </template>
            </el-table-column>
            <!--      width="95px"      -->
            <el-table-column prop="value2" label="周平均性能稼动率"  align="center">
              <template slot-scope="scope">
                {{ scope.row.value2 / 10 }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="div-padding">
          <div class="title-box" style="margin-top: 0;"
               :style="formDateWeek.xingNengs.length < 2 ? 'margin-bottom: 0px' : ''">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.单机台(4周汇总)平均性能稼动率</div>
          </div>
          <div>
            <div class="echart-title" v-if="formDateWeek.xingNengs.length > 1">
              <div>单位:印张/小时</div>
              <div class="time-data">实际运行速度 / 性能稼动率</div>
            </div>
            <div v-if="formDateWeek.xingNengs.length > 1">
              <div>
                <BarLineChart
                    style="width: 100%"
                    v-if="chartDataZong.source.length > 0"
                    :id="`chart41`"
                    :data="chartDataZong"
                    :legend-show="false"
                    :grid-left="30"
                    :height="350"
                    :gridLeft="5"
                    :percent="'%'"
                    :gridRight="15"
                    :left="'center'"
                    :smooth="false"
                    :gridTop="35"
                />
                <div class="color-box" v-if="formDateWeek.xingNengs.length > 0">
                  <div v-for="(item,index) in nameWeek" :key="index" style="display: inline-block" class="color-line">
                    <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span>{{ item }}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="div-padding">
          <el-table :data="xnjdlDataWeek" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="设备" align="center">
            </el-table-column>
            <el-table-column prop="value" label="额定速度" align="center">
              <template slot-scope="scope">
                {{ scope.row.value }}
              </template>
            </el-table-column>
            <el-table-column prop="value9" label="4周平均运行速度" align="center">
              <template slot="header">
                <span>4周平均<br>运行速度</span>
              </template>
              <template slot-scope="scope">
                {{ scope.row.value9 }}
              </template>
            </el-table-column>
            <el-table-column prop="value10" label="4周平均性能稼动率" align="center">
              <template slot="header">
                <span>4周平均<br>性能稼动率</span>
              </template>
              <template slot-scope="scope">
                {{ scope.row.value10 / 10 }}%
              </template>
            </el-table-column>
          </el-table>

        </div>

        <div class="div-padding">
          <div class="title-box" style="margin-top: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>3.单机台(4周)平均性能稼动率</div>
          </div>
          <el-select v-model="devicexn" multiple placeholder="请选择设备"
                     v-show="selectShow"
                     style="width: 100%;margin-bottom: 40px"
                     :style="formDateWeek.xingNengs.length < 2 ? 'margin-bottom: 0px' : ''"
                     @change="handleSelectxn">
            <el-option
                v-for="item in deviceName"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <div v-if="chartXnjdlList.length > 0" class="device-div">
            <div v-if="chartXnjdlList[0].show" class="service" style="padding-top: 0">{{ chartXnjdlList[0].name }}</div>
            <div class="echart-title echart-title-week" v-if="chartXnjdlList[0].show">
              <div>单位:印张/小时</div>
              <div class="time-data">实际运行速度 / 性能稼动率</div>
            </div>
            <BarLineChart
                style="width: 100%"
                class="echarts-div"
                v-if="chartXnjdlList[0].chartData.source.length > 0 && chartXnjdlList[0].chartData && chartXnjdlList[0].show"
                :id="`chart361`"
                :data="chartXnjdlList[0].chartData"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="5"
                :height="350"
                :percent="'%'"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
        </div>
        <div v-if="formDateWeek.xingNengs.length > 1">
          <div v-for="(item,key) in chartXnjdlList.slice(1)" :key="key" class="device-div">
            <div v-if="item.show !== false" class="service div-padding">{{ item.name }}</div>
            <div class="echart-title echart-title-week" v-if="item.show !== false">
              <div>单位:印张/小时</div>
              <div class="time-data">实际运行速度 / 性能稼动率</div>
            </div>
            <BarLineChart
                style="width: 100%"
                class="echarts-div"
                v-if="item.chartData.source.length > 0 && item.chartData && item.show !== false"
                :id="`chart42${key}`"
                :data="item.chartData"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="5"
                :height="350"
                :percent="'%'"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>

        </div>
        <div class="table-pad div-padding">
          <el-table :data="xnjdlDataWeek" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="设备" width="200" align="center">
            </el-table-column>
            <el-table-column prop="value" label="额定速度" align="center">
              <template slot-scope="scope">
                {{ scope.row.value }}
              </template>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>运行速度</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value1 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>性能<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value2 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>运行速度</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value3 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>性能<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value4 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>运行速度</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value5 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>性能<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value6 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>运行速度</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value7 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>性能<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value8 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div style="white-space: pre-wrap;">{{ formDateWeek.sdDescribe }}</div>-->
        <!--          </div>-->
        <div class="div-padding" style="padding-top: 30px">
          <div class="title-first" style="margin-top: 0;">三、有效时间稼动率</div>
          <div class="title-box">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.印厂总有效时间稼动率</div>
          </div>
          <div>
            <div class="echart-title" v-if="formDateWeek.shiJians.length > 0">
              <div>单位:小时/周</div>
              <div class="time-data">有效印刷时长 / 有效时间稼动率</div>
            </div>
            <div style="display: flex;" v-if="formDateWeek.shiJians.length > 0">
              <BarLineChart
                  style="width: 100%"
                  v-if="sjjdlWeek.source.length > 0"
                  :id="'weekChart6'"
                  :data="sjjdlWeek"
                  :legend-show="false"
                  :grid-left="30"
                  :height="350"
                  :gridLeft="5"
                  :percent="'%'"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
            </div>
          </div>
        </div>
        <div class="div-padding">
          <el-table :data="timeDataWeek" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
              </template>
            </el-table-column>
            <!--            <el-table-column prop="panelName" label="周额定运行时长(小时)" width="85px" align="center">-->
            <!--              <template slot-scope="scope">-->
            <!--                {{ scope.row.value }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--    width="85px"        -->
            <el-table-column prop="value1" label="周有效印刷时长(小时)"  align="center">
              <template slot-scope="scope">
                {{ scope.row.value1 }}
              </template>
            </el-table-column>
            <!--      width="70px"       -->
            <el-table-column prop="value2" label="周平均有效时间稼动率" align="center">
              <template slot-scope="scope">
                {{ scope.row.value2 / 10 }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="div-padding">
          <div class="title-box" style="margin-top: 0;"
               :style="formDateWeek.shiJians.length < 2 ? 'margin-bottom: 0px' : ''">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.单机台（4周汇总）平均有效时间稼动率</div>
          </div>
          <div>
            <div class="echart-title" v-if="formDateWeek.shiJians.length > 1">
              <div>单位:小时/周</div>
              <div class="time-data">有效印刷时长 / 有效时间稼动率</div>
            </div>
            <div v-if="formDateWeek.shiJians.length > 1">
              <BarLineChart
                  style="width: 100%"
                  v-if="chartDataTimeZong.source.length > 0"
                  :id="'weekChart61'"
                  :data="chartDataTimeZong"
                  :legend-show="false"
                  :grid-left="30"
                  :height="350"
                  :gridLeft="5"
                  :percent="'%'"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
              <div class="color-box" v-if="formDateWeek.xingNengs.length > 1">
                <div v-for="(item,index) in nameWeek" :key="index" style="display: inline-block" class="color-line">
                  <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span>{{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="div-padding">
          <el-table :data="timeDatazong" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="设备" align="center">
            </el-table-column>
            <!--            <el-table-column prop="panelName" width="80px" align="center">-->
            <!--              <template slot="header">-->
            <!--                <span>4周额定运行<br>时长(小时)</span>-->
            <!--              </template>-->
            <!--              <template slot-scope="scope">-->
            <!--                {{ scope.row.value }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--       width="80px"      -->
            <el-table-column prop="value1" align="center">
              <template slot="header">
                <span>4周有效印刷<br>时长(小时)</span>
              </template>
              <template slot-scope="scope">
                {{ scope.row.value9 }}
              </template>
            </el-table-column>
            <!--      width="80px"      -->
            <el-table-column prop="value2"  align="center">
              <template slot="header">
                <span>4周平均有效<br>时间稼动率</span>
              </template>
              <template slot-scope="scope">
                {{ scope.row.value10 / 10 }}%
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="div-padding">
          <div class="title-box" v-show="selectShow" style="margin-top: 0;">
            <img src="@/assets/title-icon.png" alt="">
            <div>3.单机台（4周）平均有效时间稼动率</div>
          </div>
          <el-select v-model="devicesj" multiple placeholder="请选择设备"
                     v-show="selectShow"
                     style="width: 100%;margin-bottom: 40px"
                     :style="formDateWeek.xingNengs.length < 2 ? 'margin-bottom: 0px' : ''"
                     @change="handleSelectsj">
            <el-option
                v-for="item in deviceName"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <div v-if="formDateWeek.shiJians.length > 0">
            <div v-for="(item,key) in chartTimeList" :key="key" class="device-div">
              <div class="title-box" v-if="key===0 && !selectShow" style="margin-top: 0;margin-bottom: 0">
                <img src="@/assets/title-icon.png" alt="">
                <div>3.单机台（4周）平均有效时间稼动率</div>
              </div>
              <div v-if="item.show !== false" class="service div-padding">{{ item.name }}</div>
              <div class="echart-title echart-title-week" v-if="item.show">
                <div>单位:小时/周</div>
                <div class="time-data">有效印刷时长 / 有效时间稼动率</div>
              </div>
              <BarLineChart
                  style="width: 100%"
                  v-if="item.chartData.source.length > 0 && item.chartData && item.show"
                  :id="`chart43${key}`"
                  :data="item.chartData"
                  :legend-show="false"
                  :grid-left="30"
                  :gridLeft="5"
                  :height="350"
                  :percent="'%'"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
            </div>
          </div>
        </div>
        <div class="div-padding table-pad">
          <el-table :data="timeDatazong" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="设备" width="200" align="center">
            </el-table-column>
            <!--            <el-table-column prop="panelName" label="额定运行时长" width="55px" align="center">-->
            <!--              <template slot-scope="scope">-->
            <!--                {{ scope.row.value }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">

              <el-table-column prop="value1" label="有效印刷时长" align="center">
                <template slot="header">
                  <span>有效印刷<br>时长</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value1 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>有效时间<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value2 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">

              <el-table-column prop="value1" label="有效印刷时长" align="center">
                <template slot="header">
                  <span>有效印刷<br>时长</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value3 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>有效时间<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value4 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
              <el-table-column prop="value1" label="有效印刷时长" align="center">
                <template slot="header">
                  <span>有效印刷<br>时长</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value5 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>有效时间<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value6 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
              <el-table-column prop="value1" label="有效印刷时长" align="center">
                <template slot="header">
                  <span>有效印刷<br>时长</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value7 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>有效时间<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value8 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div style="white-space: pre-wrap;">{{formDateWeek.timeDescribe}}</div>-->
        <!--          </div>-->

        <div class="div-padding">
          <div class="title-first" style="margin-top: 10px;">四、异常待机(数据)</div>
          <div class="title-box">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.印厂待机情况汇总</div>
          </div>

          <div class="echart-title" v-if="formDateWeek.yichangs1.length > 0">
            <div>单位:小时</div>
            <div class="time-data time-data2">待机时长/ 待机原因已提交 / 待机原因未提交</div>
          </div>
          <div v-if="formDateWeek.yichangs1.length > 0">
            <BarLineChart
                style="width: 100%"
                v-if="ycdjTotalWeek.source.length > 0"
                :id="'weekChart8'"
                :data="ycdjTotalWeek"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="5"
                :height="350"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
        </div>
        <div class="div-padding">
          <div class="echart-title " v-if="formDateWeek.yichangs1.length > 0">
            <div>单位:次</div>
            <div class="time-data time-data2">待机次数 / 待机原因已提交 / 待机原因未提交</div>
          </div>
          <div v-if="formDateWeek.yichangs1.length > 0">
            <BarLineChart
                style="width: 100%"
                v-if="ycdjWeek.source.length > 0"
                :id="'weekChart82'"
                :data="ycdjWeek"
                :legend-show="false"
                :grid-left="30"
                :height="350"
                :gridLeft="5"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
        </div>
        <div :style="selectShow ? 'padding-top: 40px' : 'padding-top: 20px'">
          <el-table :data="yichangs1" border :row-class-name="tableRowClassName" width="100%"
                    class="el-table-statisticsa reson-table reson-table2">
            <el-table-column prop="name" label="时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column prop="panelName" style="padding: 2px" label="异常待机状况" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? (Math.round((scope.row.value3 / 60) * 10) / 10 + Math.round((scope.row.value5 / 60) * 10) / 10).toFixed(1) : scope.row.value1.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value2 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" label="待机原因已提交" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? Math.round((scope.row.value3 / 60) * 10) / 10 : scope.row.value3.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value4 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" label="待机原因未提交" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? Math.round((scope.row.value5 / 60) * 10) / 10 : scope.row.value5.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value6 }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>

        <div class="div-padding">
          <div class="title-box" style="margin-top: 0"
               :style="formDateWeek.yichangs.length < 2 ? 'margin-bottom: 0px' : ''">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.单机台（4周汇总）异常待机数据</div>
          </div>
          <div class="echart-title" v-if="formDateWeek.yichangs.length > 1">
            <div>单位:小时</div>
            <div class="time-data time-data2">待机时长/ 待机原因已提交 / 待机原因未提交</div>
          </div>
          <div v-if="formDateWeek.yichangs.length > 1">
            <BarLineChart
                style="width: 100%"
                v-if="chartDataYichangZong.source.length > 0"
                :id="'weekChart81'"
                :data="chartDataYichangZong"
                :legend-show="false"
                :grid-left="30"
                :height="350"
                :gridLeft="5"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
            <div class="color-box" v-if="formDateWeek.xingNengs.length > 1">
              <div v-for="(item,index) in nameWeek" :key="index" style="display: inline-block" class="color-line">
                <span :style="{ backgroundColor: colorDevice[index] }" class="color-item"></span><span
                  :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span><span
                  :style="{ backgroundColor: DeepColorArrDevice[index] }" class="color-item"></span>{{ item }}
              </div>
            </div>
          </div>
        </div>
        <div :style="selectShow ? 'padding-top: 40px' : 'padding-top: 30px'" v-if="formDateWeek.yichangs.length > 1">
          <div class="echart-title " v-if="formDateWeek.yichangs.length > 1">
            <div>单位:次</div>
            <div class="time-data time-data2">待机次数 / 待机原因已提交 / 待机原因未提交</div>
          </div>
          <div v-if="formDateWeek.yichangs.length > 1">
            <BarLineChart
                style="width: 100%"
                v-if="chartDataYichangZongCount.source.length > 0"
                :id="'weekChart83'"
                :data="chartDataYichangZongCount"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="5"
                :height="350"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
            <div class="color-box" v-if="formDateWeek.xingNengs.length > 1">
              <div v-for="(item,index) in nameWeek" :key="index" style="display: inline-block" class="color-line">
                <span :style="{ backgroundColor: colorDevice[index] }" class="color-item"></span><span
                  :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span><span
                  :style="{ backgroundColor: DeepColorArrDevice[index] }" class="color-item"></span>{{ item }}
              </div>
            </div>
          </div>
        </div>
        <div :style="selectShow ? 'padding-top: 40px' : 'padding-top: 30px'">
          <el-table :data="yichangDatazong" border :row-class-name="tableRowClassName" width="100%"
                    class="el-table-statistics reson-table reson-table2">
            <el-table-column prop="name" label="设备" width="200" align="center">
            </el-table-column>
            <el-table-column prop="panelName" style="padding: 2px" label="异常待机状况" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? Math.round((scope.row.value9 / 60) * 10) / 10 : scope.row.value9.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value10 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" label="待机原因已提交" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? Math.round((scope.row.value11 / 60) * 10) / 10 : scope.row.value11.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value12 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" label="待机原因未提交" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? Math.round((scope.row.value13 / 60) * 10) / 10 : scope.row.value13.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value14 }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>


        <div :style="selectShow ? 'padding-top: 30px' : 'padding-top: 20px'">
          <div class="title-box" style="margin-top: 0" v-show="selectShow">
            <img src="@/assets/title-icon.png" alt="">
            <div>3.单机台（4周）每周待机数据</div>
          </div>
          <el-select v-model="devicedjsj" multiple placeholder="请选择设备"
                     v-show="selectShow"
                     style="width: 100%;margin-bottom: 25px"
                     :style="formDateWeek.xingNengs.length < 2 ? 'margin-bottom: 0px' : ''"
                     @change="handleSelectdjsj">
            <el-option
                v-for="item in deviceName"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <div v-if="formDateWeek.yichangs.length > 1">
            <div v-for="(item,indexData) in chartYichangList" :key="indexData"
                 class="device-div">
              <div class="title-box" style="margin-top: 0;margin-bottom: 0" v-if="indexData===0 && !selectShow">
                <img src="@/assets/title-icon.png" alt="">
                <div>3.单机台（4周）每周待机数据</div>
              </div>
              <div v-if="item.show" :style="selectShow ? 'padding-top: 30px' : 'padding-top: 40px'"
                   class="service div-padding" style="padding-bottom: 10px">{{ item.name }}
              </div>
              <div class="echart-title echart-title-week" v-if="item.show">
                <div>单位:小时 <br>单位:次</div>
                <div class="time-data time-data2">待机时长/ 待机次数</div>
              </div>
              <div v-if="item.show !== false">
                <BarLineChart
                    style="width: 100%"
                    class="echarts-div"
                    v-if="item.chartData.source.length > 0 && item.show"
                    :id="`chart72${indexData}`"
                    :data="item.chartData"
                    :legend-show="false"
                    :height="350"
                    :grid-left="30"
                    :gridLeft="15"
                    :gridRight="0"
                    :left="'center'"
                    :smooth="false"
                    :gridTop="35"
                />
              </div>

            </div>

          </div>
        </div>
        <!--<div v-if="formDateWeek.yichangs.length > 2">
          <div v-for="(item,indexData) in chartYichangList.slice(2)" :key="indexData"
               class="device-div">
            <div v-if="item.show !== false" :style="selectShow ? 'padding-top: 30px' : 'padding-top: 40px'"
                 class="service div-padding" style="padding-bottom: 10px">{{ item.name }}
            </div>
            <div class="echart-title echart-title-week" v-if="item.show !== false">
              <div>单位:小时 <br>单位:次</div>
              <div class="time-data time-data2">待机时长/ 待机次数</div>
            </div>
            <div v-if="item.show !== false">
              <BarLineChart
                  style="width: 100%"
                  class="echarts-div"
                  v-if="item.chartData.source.length > 0 && item.show !== false"
                  :id="`chart72${indexData}`"
                  :data="item.chartData"
                  :legend-show="false"
                  :grid-left="30"
                  :gridLeft="15"
                  :gridRight="0"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
            </div>

          </div>

        </div>-->
        <div :style="selectShow ? 'padding-top: 40px' : 'padding-top: 30px'">
          <el-table :data="yichangDatazong" border  :row-class-name="tableRowClassName" width="100%"
                    class="el-table-statistics reson-table reson-table2">
            <el-table-column prop="name" label="设备" width="200" align="center">
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ (scope.row.value1 / 60).toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value2 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ (scope.row.value3 / 60).toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value4 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ (scope.row.value5 / 60).toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value6 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
              <el-table-column prop="value1" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ (scope.row.value7 / 60).toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value8 }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div style="white-space: pre-wrap;">{{formDateWeek.abnormalDescribe}}</div>-->
        <!--          </div>-->
        <div :style="selectShow ? 'padding-top: 40px' : 'padding-top: 30px'">
          <div class="title-first" style="margin-top: 0;">五、异常待机(原因)</div>
          <div class="title-box">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.待机原因汇总</div>
          </div>
          <div class="title-three">(1)4周待机次数主要原因统计</div>
          <div class="div-padding">
            <el-table :data="reasonCount" border :row-class-name="tableRowClassName" width="100%"
                      class="el-table-statistics reson-table reson-table2 reson-table3">
              <el-table-column prop="name" label="时间" align="center">
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="总次数" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value1 }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="未填写" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value2 }}
                </template>
              </el-table-column>
              <el-table-column v-for="(item, index) in formDateWeek.countList" :key="index"
                               :label="`第${index + 1}多`">
                <el-table-column :prop="`value${index + 3}`" :label="item" align="center">

                </el-table-column>
              </el-table-column>
              <el-table-column prop="value9" label="前6占比"  align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.value1 === 0 ? 0 : parseInt((scope.row.value3 + scope.row.value4 + scope.row.value5 + scope.row.value6 + scope.row.value7 + scope.row.value8) * 100 / scope.row.value1)
                  }}%
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="copywriter">
          <div>材料包括:</div>
          <div>印版、纸张、油墨、胶辊、橡皮布、润版液、喷粉、光油</div>
        </div>
        <div :style="selectShow ? 'padding-top: 40px' : 'padding-top: 30px'">
          <div class="title-three">(2)4周待机时间主要原因统计</div>
          <div class="div-padding">
            <el-table :data="reasonTime" border :row-class-name="tableRowClassName" width="100%"
                      class="el-table-statistics reson-table reson-table2 reson-table3">
              <el-table-column prop="name" label="时间" align="center">
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="总时长" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value1 }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="未填写" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value2 }}
                </template>
              </el-table-column>
              <el-table-column v-for="(item, index) in formDateWeek.timeList" :key="index"
                               :label="`第${index + 1}多`">
                <el-table-column :prop="`value${index + 3}`" :label="item" align="center">

                </el-table-column>
              </el-table-column>
              <el-table-column prop="value9" label="前6占比" align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.value1 === 0 ? 0 : parseInt((scope.row.value3 + scope.row.value4 + scope.row.value5 + scope.row.value6 + scope.row.value7 + scope.row.value8) * 100 / scope.row.value1)
                  }}%
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!--<div :style="selectShow ? 'padding-top: 40px' : 'padding-top: 30px'">
          <el-table :data="reasonTime" border :row-class-name="tableRowClassName" width="100%"
                    class="el-table-statistics reson-table reson-table2 reson-table3">
            <el-table-column prop="name" label="时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column prop="value1" label="总时长" width="40px" align="center">
              <template slot-scope="scope">
                {{ scope.row.value1 }}
              </template>
            </el-table-column>
            <el-table-column prop="value1" label="未填写" width="40px" align="center">
              <template slot-scope="scope">
                {{ scope.row.value2 }}
              </template>
            </el-table-column>
            <el-table-column v-for="(item, index) in formDateWeek.timeList.slice(3)" :key="index"
                             :label="`第${index + 4}多`">
              <el-table-column :prop="`value${index + 6}`" :label="item" width="53px" align="center">

              </el-table-column>
            </el-table-column>
            <el-table-column prop="value9" label="4-6占比" width="50px" align="center">
              <template slot-scope="scope">
                {{
                  scope.row.value1 === 0 ? 0 : parseInt((scope.row.value6 + scope.row.value7 + scope.row.value8) * 100 / scope.row.value1)
                }}%
              </template>
            </el-table-column>
          </el-table>
        </div>-->
        <div class="copywriter">
          <div>材料包括:</div>
          <div>印版、纸张、油墨、胶辊、橡皮布、润版液、喷粉、光油</div>
        </div>
        <div :style="selectShow ? 'padding-top: 0px' : 'padding-top: 10px'">
          <div class="title-box" style="margin-top: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.单印刷机待机原因</div>
          </div>
          <el-select v-model="devicedjyy" placeholder="请选择设备" v-show="selectShow"
                     style="width: 100%;" multiple
                     @change="handleSelectdjyy">
            <el-option
                v-for="item in deviceName"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <div v-if="formDateWeek.abnormalInfos.length > 0" class="device-div">
            <div v-if="formDateWeek.abnormalInfos[0].show" class=" ">{{ formDateWeek.abnormalInfos[0].name }}</div>
            <div style="display: flex;justify-content: space-between">
              <div v-if="formDateWeek.abnormalInfos[0].show" class="title-three2">待机时间原因-次数</div>
              <div v-if="formDateWeek.abnormalInfos[0].show" class="title-three2">该6项原因占总次数的{{
                  formDateWeek.abnormalInfos[0].countRate
                }}%
              </div>
            </div>
            <BarLineChart2
                class="echarts-div"
                v-if="formDateWeek.abnormalInfos[0].chartData.source.length > 0 && formDateWeek.abnormalInfos[0].show "
                :id="`chart131s`"
                :data="formDateWeek.abnormalInfos[0].chartData"
                :legend-show="false"
                :grid-left="30"
                :height="350"
                :gridLeft="15"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
            />
            <div style="display: flex;justify-content: space-between">
              <div v-if="formDateWeek.abnormalInfos[0].show" class="title-three2">待机时间原因-时间</div>
              <div v-if="formDateWeek.abnormalInfos[0].show" class="title-three2">该6项原因占总时间{{
                  formDateWeek.abnormalInfos[0].timeRate
                }}%
              </div>
            </div>
            <BarLineChart2
                class="echarts-div"
                v-if="formDateWeek.abnormalInfos[0].chartDataTime.source.length > 0 && formDateWeek.abnormalInfos[0].show"
                :id="`chart132q`"
                :data="formDateWeek.abnormalInfos[0].chartDataTime"
                :legend-show="false"
                :grid-left="30"
                :height="350"
                :gridLeft="15"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
            />
          </div>
        </div>

        <div v-if="formDateWeek.abnormalInfos.length > 1">
          <div v-for="(item, index) in formDateWeek.abnormalInfos.slice(1)" :key="index" class="device-div">
            <div v-if="item.show !== false" :style="selectShow ? 'padding-top: 30px' : 'padding-top: 30px'">{{
                item.name
              }}
            </div>
            <div style="display: flex;justify-content: space-between">
              <div v-if="item.show" class="title-three2">待机时间原因-次数</div>
              <div v-if="item.show" class="title-three2">该6项原因占总次数的{{
                  item.countRate
                }}%
              </div>
            </div>
            <BarLineChart2
                v-if="item.chartData.source.length > 0 && item.show "
                :id="`chart131${index}`"
                :data="item.chartData"
                :legend-show="false"
                :grid-left="30"
                :height="350"
                :gridLeft="15"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
            />
            <div style="display: flex;justify-content: space-between" v-if="item.show">
              <div v-if="item.show" class="title-three2">待机时间原因-时间</div>
              <div v-if="item.show" class="title-three2">该6项原因占总时间{{
                  item.timeRate
                }}%
              </div>
            </div>
            <BarLineChart2
                class="echarts-div"
                v-if="item.chartDataTime.source.length > 0 && item.show"
                :id="`chart132` + index"
                :data="item.chartDataTime"
                :legend-show="false"
                :grid-left="30"
                :height="350"
                :gridLeft="15"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
            />
          </div>
        </div>

        <!--          <div v-for="(item,key) in formDateWeek.abnormalInfos" :key="key">-->
        <!--            <div class="title-three">{{ item.name }}</div>-->
        <!--           -->
        <!--          </div>-->

        <div :style="selectShow ? '' : 'padding-top: 0px'">
          <div style="position: relative" class="label-icon">
            <img class="label-icon-img" src="@/assets/label-icon2.png" alt="">
          </div>
          <div class="title-box" style="margin-bottom: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.印刷设备维保</div>
          </div>
          <div class="table-pad div-padding">
            <el-table :data="formDateWeek.ysInfo" border :row-class-name="tableRowClassName" width="100%"
                      class="el-table-statistics">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
                <el-table-column prop="value1" label="保养周保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检周保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value2 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="周保养完成率" width="70" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value3 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
                <el-table-column prop="value1" label="保养周保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value4 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检周保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value5 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="周保养完成率" width="70" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value6 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
                <el-table-column prop="value1" label="保养周保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value7 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检周保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value8 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="周保养完成率" width="70" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value9 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
                <el-table-column prop="value1" label="保养周保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value10 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检周保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value11 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="周保养完成率" width="70" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value12 }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div :style="selectShow ? '' : 'padding-top: 0px'">
          <div class="title-box" style="margin-bottom: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.印前设备维保</div>
          </div>
          <div class="table-pad div-padding">
            <el-table :data="formDateWeek.yqInfo" border :row-class-name="tableRowClassName" width="100%">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
                <el-table-column prop="value1" label="保养周保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检周保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value2 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="周保养完成率" width="70" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value3 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
                <el-table-column prop="value1" label="保养周保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value4 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检周保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value5 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="周保养完成率" width="70" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value6 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
                <el-table-column prop="value1" label="保养周保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value7 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检周保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value8 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="周保养完成率" width="70" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value9 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
                <el-table-column prop="value1" label="保养周保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value10 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检周保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>周保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value11 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="周保养完成率" width="70" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value12 }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>

        </div>
        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div style="white-space: pre-wrap;">{{formDateWeek.maintainDescribe}}</div>-->
        <!--          </div>-->
        <!--        <div :style="selectShow ? '' : ''">-->
        <div :style="selectShow ? '' : 'padding-top: 0px'">
          <div style="position: relative" class="label-icon">
            <img class="label-icon-img" src="@/assets/label-icon3.png" alt="" style="margin-top: 0">
          </div>
          <div class="title-box" :style="selectShow ? '' : 'padding-top: 0px;margin-top: 0'"
               style="margin-bottom: 0;margin-top: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.PQES报告上传</div>
          </div>
          <div class="table-pad el-table-PQES" :style="selectShow ? 'padding-top:20px' : 'padding-top: 20px'">
            <el-table :data="formDateWeek.pqesInfos" border :row-class-name="tableRowClassName"
                      :span-method="objectSpanMethodWeek" class="el-table-statistics " style="margin-bottom: 0">
              <el-table-column prop="name" label="设备" width="200" align="center">
              </el-table-column>
              <el-table-column prop="panelName" label="班组" align="center">
                <template slot-scope="scope">
                  {{ scope.row.groupName }}
                </template>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
                <el-table-column prop="value1" label="应该上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="实际上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value2 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
                <el-table-column prop="value1" label="应该上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value3 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="实际上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value4 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
                <el-table-column prop="value1" label="应该上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value5 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="实际上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value6 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
                <el-table-column prop="value1" label="应该上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value7 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="实际上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value8 }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!--        </div>-->

        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div></div>-->
        <!--          </div>-->
      </div>
      <div :style="selectShow ? 'padding-top: 30px' : 'padding-top: 0px;margin-top:20px'" style="background: #fff;">
        <img class="frontPage" src="@/assets/frontPage2.jpg" alt="">
      </div>

    </div>
    <month class="page-main" v-if="selectTime === 3" :month="timeMonth" :selectShow="selectShow" :months="timeMonths"
           :timeTables="timeTableMonth"></month>
  </div>
</template>

<script>
import moment from 'moment'
import BarLineChart from '@/components/Charts/BarLineChart'
import BarLineChart2 from '@/components/Charts/BarLineChart2'
import html2pdf from 'html2pdf.js'
import API from "@/api";
import month from '@/views/HomePage/PrintPage/dataReportPage/dataReportMonth'
import Vue from "vue";
// import {saveFile} from "@/utils/callAPP";
export default {
  components: {BarLineChart, BarLineChart2, month},
  data() {
    return {
      pickerOptionsStart:{
        disabledDate: time => {
          return time.getTime(new Date()) > Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      btnShow:false,
      countList: [],
      selectShow: true,
      timeMonth: '',
      timeMonths: '',
      time: [],
      times: [],
      timeTable: [],
      timeTableMonth: [],
      dateArry: [],
      selectTime: 1,
      reasonName: [],
      year: '',
      dateTime: '',
      dateDay: '',
      teamName: '',
      date: '', // 日期：用于展示、提交
      currentDate: '', // 当前选择日期
      maxDate: '', // 最大可选择日期
      showDatePopup: false,// 日期弹窗
      formDate: {
        abnormalDescribe: '',
        sdDescribe: '',
        timeDescribe: '',
        ylDescribe: '',
        maintainDescribe: '',
        ycdjReasonInfo: [],
        ysInfo: [],
        yqInfo: [],
        pqesInfos: [],
        ysslInfo: [],
        yinLiangs:[],
      },
      formDateWeek: {
        abnormalDescribe: '',
        sdDescribe: '',
        timeDescribe: '',
        ylDescribe: '',
        maintainDescribe: '',
        ysInfo: [],
        yqInfo: [],
        pqesInfos: [],
        yinLiangs: [],
        xingNengs: [],
        shiJians: [],
        yichangs: [],
        yichangs1: [],
        countList: [],
        timeList: [],
        abnormalInfos: []
      },
      abnormalInfos: [],
      yichangs1: [],
      chartList: [],
      chartXnjdlList: [],
      chartTimeList: [],
      chartYichangList: [],
      device: [],
      deviceyy: [],
      devicexn: [],
      devicesj: [],
      devicedjsj: [],
      devicedjyy: [],
      deviceName: [],
      ysslTotal: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            // maxBarWidth: 15,
            barWidth: 10,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: '#5470c6',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  // show: true, //开启显示
                  // position: 'top', //在上方显示
                  // textStyle: { //数值样式
                  //   color: '#555555',
                  //   fontSize: 10
                  // },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#5470c6',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 10
                  },
                  formatter: function (res) {
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      yssl: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  //{{a}}{abg|}{b}{c}
                  // formatter:'{{c}}',
                  formatter: function (res) {
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            },
            lineStyle: {
              width: 0 // 设置线宽为0，不显示线
            }
          }
        ],
      },
      ysslData: [],
      xnjdlTotal: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [{
          type: 'bar',
          yAxisIndex: 0, connectNulls: true,
          barWidth: 30,
          color: '#ffc553',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              // borderWidth: 7,
              label: {
                // show: true, //开启显示
                // position: 'top', //在上方显示
                // textStyle: { //数值样式
                //   color: '#555555',
                //   fontSize: 10
                // },

              }
            },

          }
        }, {
          type: 'line',
          // barWidth: 30,
          yAxisIndex: 1, connectNulls: true,
          color: '#F6BB17',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 14
                },
                formatter: function (res) {
                  if (res.value) {
                    return res.value[2] + '%'
                  } else {
                    return 0
                  }
                },
              }
            },

          }
        }],
      },
      xnjdl: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  formatter: function (res) {
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      xnjdlData: [],
      sjjdlTotal: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [{
          type: 'bar',
          yAxisIndex: 0, connectNulls: true,
          // maxBarWidth: 10,
          barWidth: 10,
          color: '#ffc553',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              // borderWidth: 7,
              label: {
                // show: true, //开启显示
                // position: 'top', //在上方显示
                // textStyle: { //数值样式
                //   color: '#555555',
                //   fontSize: 10
                // },

              }
            },

          }
        }, {
          type: 'line',
          // barWidth: 30,
          yAxisIndex: 1, connectNulls: true,
          color: '#F6BB17',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 10
                },
                formatter: function (res) {
                  if (res.value) {
                    return res.value[2] + '%'
                  } else {
                    return 0
                  }
                },
              }
            },

          }
        }],
      },
      sjjdl: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  formatter: function (res) {
                    // console.log(res)
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      sjjdlData: [],
      ycdjTotal: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            // max: 120,
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [{
          type: 'bar',
          yAxisIndex: 0, connectNulls: true,
          // maxBarWidth: 15,
          barWidth: 10,
          color: '#ffc553',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              // borderWidth: 7,
              label: {
                // show: true, //开启显示
                // position: 'top', //在上方显示
                // textStyle: { //数值样式
                //   color: '#555555',
                //   fontSize: 10
                // },

              }
            },

          }
        }, {
          type: 'line',
          // barWidth: 30,
          yAxisIndex: 1, connectNulls: true,
          color: '#F6BB17',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 10
                },
              }
            },

          }
        }],
      },
      ycdj: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            // max: 120,
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  var colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            barWidth: 10,
            maxBarWidth: 10,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }
        ],
      },
      reasonTotal: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            // max: 120,
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [{
          type: 'bar',
          yAxisIndex: 0, connectNulls: true,
          // maxBarWidth: 15,
          barWidth: 10,
          color: '#ffc553',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              // borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 10
                },

              }
            },

          }
        }, {
          type: 'bar',
          barWidth: 10,
          yAxisIndex: 1, connectNulls: true,
          color: '#F6BB17',
          itemStyle: {
            normal: {
              color: '#4A63B3',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 10
                },
                // formatter: `{c}`
                // formatter: function (res) {
                //   // console.log(res.value)
                //   if (res.value) {
                //     return res.value.splice(',')[2] + '%'
                //   }
                // },
              }
            },

          }
        }],
      },
      reason: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            // max: 120,
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'bar',
            maxBarWidth: 30,
            barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#7EAD5E', '#EEB951', '#CF6158', '#69B0C8', '#E87340', '#6CCC8C', '#D2965C', '#6964C2', '#D2D15B', '#67B999', '#DC7F61', '#D65C87', '#6689C5', '#D98B42', '#65C1BB', '#9BCE58', '#725AAF', '#5166B8', '#5559A5', '#BBA962']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }
        ],
      },
      auditTotal: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            // max: 120,
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [{
          type: 'bar',
          yAxisIndex: 0, connectNulls: true,
          // maxBarWidth: 15,
          barWidth: 30,
          color: '#ffc553',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              // borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 14
                },

              }
            },

          }
        }, {
          type: 'bar',
          barWidth: 30,
          yAxisIndex: 1, connectNulls: true,
          color: '#F6BB17',
          itemStyle: {
            normal: {
              color: '#4A63B3',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 14
                },
                // formatter: `{c}`
                // formatter: function (res) {
                //   // console.log(res.value)
                //   if (res.value) {
                //     return res.value.splice(',')[2] + '%'
                //   }
                // },
              }
            },

          }
        }],
      },
      audit: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'bar',
            barWidth: 30,
            maxBarWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#7EAD5E', '#EEB951', '#CF6158', '#69B0C8', '#E87340', '#6CCC8C', '#D2965C', '#6964C2', '#D2D15B', '#67B999', '#DC7F61', '#D65C87', '#6689C5', '#D98B42', '#65C1BB', '#9BCE58', '#725AAF', '#5166B8', '#5559A5', '#BBA962']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }
        ],
      },
      ycdjData: [],
      numberData: [],
      subTables2: [],
      timeData: [],
      subTables: [],
      pqesData: {
        tooltip: {
          // show:false, // 提示框不显示
          trigger: 'axis',
          confine: false, // 限制在图表区域内
          // formatter: function (params) {
          //   console.log(params)
          //   let relVal = params.length ? params[0].data.date : "";
          //   for (let i = 0, l = params.length; i < l; i++) {
          //       relVal += `<br/><span>${params[i].seriesName}</span> <span>${params[i].value[1]},${params[i].value[2]}</span> `;
          //     }
          //   return relVal;
          // }
        },
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 25,
            barWidth: 30,
            color: '#ffc553',
            stack: 1,
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  // show: true, //开启显示
                  // position: 'top', //在上方显示
                  // textStyle: { //数值样式
                  //   color: '#555555',
                  //   fontSize: 10
                  // },
                  formatter: function (res) {
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                },

              },

            }
          },
          {
            type: 'line',
            maxBarWidth: 15,
            barWidth: 10,
            stack: 2,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            },
            lineStyle: {
              width: 0 // 设置线宽为0，不显示线
            }
          }
        ],
      },
      pqesInfos: [],
      width: '',
      height: '',
      loading: false,
      // 周
      ysslDataZong: [],
      xnjdlDataZong: [],
      timeDatazong: [],
      reasonCount: [],
      reasonTime: [],
      yichangDatazong: [],
      ysslTotalWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 11, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            // maxBarWidth: 15,
            barWidth: 10,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: '#5470c6',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  // show: true, //开启显示
                  // position: 'top', //在上方显示
                  // textStyle: { //数值样式
                  //   color: '#555555',
                  //   fontSize: 10
                  // },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#5470c6',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 10
                  },
                  formatter: function (res) {
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      ysslWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            },
            interval: 0,
            formatter: function (value) {
              // 使用正则表达式分割文本
              // var ret = value.split('\n');
              // 如果有两行，则返回两行
              // console.log(value.match(/\((\d{2}\.\d{2}-\d{2}\.\d{2})\)/)[1].split('-'))
              // console.log(value.match(/\((\d{2}\.\d{2}-\d{2}\.\d{2})\)/)[1].split('-')[0] + '\n' + value.match(/\((\d{2}\.\d{2}-\d{2}\.\d{2})\)/)[1].split('-')[1])
              if (value) {
                return value.match(/\((\d{2}\.\d{2}-\d{2}\.\d{2})\)/)[1];
              } else {
                return value;
              }
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  //{{a}}{abg|}{b}{c}
                  // formatter:'{{c}}',
                  formatter: function (res) {
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            },
            lineStyle: {
              width: 0 // 设置线宽为0，不显示线
            }
          }
        ],
      },
      chartDataZong: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  formatter: function (res) {
                    // console.log(res)
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      chartDataTimeZong: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  formatter: function (res) {
                    // console.log(res)
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      chartDataYichangZong: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            connectNulls: true,
            maxBarWidth: 30,
            barMaxWidth: 30,
            // barWidth: 10,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#AAE581', '#FFD279', '#FF8177', '#80DFFF', '#FF9669', '#82FAAA', '#FFCA6E', '#8A84FA', '#FFFD71', '#7EE2BC', '#FFA182', '#FF79A8', '#7EA8F1', '#FFB46E', '#7EEEE7', '#C3FF74', '#A184EA', '#6D88F7', '#7A80EB', '#F7E28E']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'bar',
            maxBarWidth: 30,
            barMaxWidth: 30,
            connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  // formatter: function (res) {
                  //   console.log(res)
                  //   if (res.value) {
                  //     return res.value[2] + '%'
                  //   } else {
                  //     return 0
                  //   }
                  // },
                }
              },

            }
          },
          {
            type: 'bar',
            maxBarWidth: 30,
            barMaxWidth: 30,
            // barWidth: 10,
            connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#7EAD5E', '#EEB951', '#CF6158', '#69B0C8', '#E87340', '#6CCC8C', '#D2965C', '#6964C2', '#D2D15B', '#67B999', '#DC7F61', '#D65C87', '#6689C5', '#D98B42', '#65C1BB', '#9BCE58', '#725AAF', '#5166B8', '#5559A5', '#BBA962']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }
        ],
      },
      chartDataYichangZongCount: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            connectNulls: true,
            maxBarWidth: 30,
            barMaxWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#AAE581', '#FFD279', '#FF8177', '#80DFFF', '#FF9669', '#82FAAA', '#FFCA6E', '#8A84FA', '#FFFD71', '#7EE2BC', '#FFA182', '#FF79A8', '#7EA8F1', '#FFB46E', '#7EEEE7', '#C3FF74', '#A184EA', '#6D88F7', '#7A80EB', '#F7E28E']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'bar',
            maxBarWidth: 30,
            barMaxWidth: 30,
            connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  // formatter: function (res) {
                  //   // console.log(res)
                  //   if (res.value) {
                  //     return res.value[2] + '%'
                  //   } else {
                  //     return 0
                  //   }
                  // },
                }
              },

            }
          },
          {
            type: 'bar',
            maxBarWidth: 30,
            barMaxWidth: 30,
            connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#7EAD5E', '#EEB951', '#CF6158', '#69B0C8', '#E87340', '#6CCC8C', '#D2965C', '#6964C2', '#D2D15B', '#67B999', '#DC7F61', '#D65C87', '#6689C5', '#D98B42', '#65C1BB', '#9BCE58', '#725AAF', '#5166B8', '#5559A5', '#BBA962']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }
        ],
      },
      ysslDataWeek: [],
      xnjdlTotalWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [{
          type: 'bar',
          yAxisIndex: 0, connectNulls: true,
          barWidth: 10,
          color: '#ffc553',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              // borderWidth: 7,
              label: {
                // show: true, //开启显示
                // position: 'top', //在上方显示
                // textStyle: { //数值样式
                //   color: '#555555',
                //   fontSize: 10
                // },

              }
            },

          }
        }, {
          type: 'line',
          // barWidth: 30,
          yAxisIndex: 1, connectNulls: true,
          color: '#F6BB17',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 10
                },
                formatter: function (res) {
                  if (res.value) {
                    return res.value[2] + '%'
                  } else {
                    return 0
                  }
                },
              }
            },

          }
        }],
      },
      xnjdlWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            },
            interval: 0,
            formatter: function (value) {
              if (value) {
                return value.match(/\((\d{2}\.\d{2}-\d{2}\.\d{2})\)/)[1];
              } else {
                return value;
              }
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  formatter: function (res) {
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      xnjdlDataWeek: [],
      sjjdlTotalWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [{
          type: 'bar',
          yAxisIndex: 0, connectNulls: true,
          // maxBarWidth: 10,
          barWidth: 10,
          color: '#ffc553',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              // borderWidth: 7,
              label: {
                // show: true, //开启显示
                // position: 'top', //在上方显示
                // textStyle: { //数值样式
                //   color: '#555555',
                //   fontSize: 10
                // },

              }
            },

          }
        }, {
          type: 'line',
          // barWidth: 30,
          yAxisIndex: 1, connectNulls: true,
          color: '#F6BB17',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 10
                },
                formatter: function (res) {
                  if (res.value) {
                    return res.value[2] + '%'
                  } else {
                    return 0
                  }
                },
              }
            },

          }
        }],
      },
      sjjdlWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            },
            interval: 0,
            formatter: function (value) {
              if (value) {
                return value.match(/\((\d{2}\.\d{2}-\d{2}\.\d{2})\)/)[1];
              } else {
                return value;
              }
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  formatter: function (res) {
                    // console.log(res)
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      ycdjTotalWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            },
            interval: 0,
            formatter: function (value) {
              if (value) {
                return value;
              } else {
                return value;
              }
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: false},
            position: 'left',
            // max: 120,
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
              // textStyle: { color: '#E96D63', },
              // show: true
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar', connectNulls: true,
            // maxBarWidth: 15,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: '#97CE71',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            },
          },
          {
            type: 'bar',
            // barWidth: 30, connectNulls: true,
            color: '#F6BB17',
            barWidth: 30,
            itemStyle: {
              normal: {
                color: '#FFC553',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          },
          {
            type: 'bar',
            barWidth: 30, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#E96D63',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }],
      },
      ycdjWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: false},
            position: 'left',
            // max: 120,
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
              // textStyle: { color: '#E96D63', },
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar', connectNulls: true,
            // maxBarWidth: 15,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: '#97CE71',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'bar',
            barWidth: 30, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#FFC553',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          },
          {
            type: 'bar',
            barWidth: 30,
            connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#E96D63',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }
        ],
      },
      timeDataWeek: [],
      yichangData: [],
      name: [],
      nameWeek: [],
      namePQES: [],
      colorDevice: ['#AAE581', '#FFD279', '#FF8177', '#80DFFF', '#FF9669', '#82FAAA', '#FFCA6E', '#8A84FA', '#FFFD71', '#7EE2BC', '#FFA182', '#FF79A8', '#7EA8F1', '#FFB46E', '#7EEEE7', '#C3FF74', '#A184EA', '#6D88F7', '#7A80EB', '#F7E28E', '#84EB76', '#FFE95B', '#FF7F6B', '#9BFFDF', '#FF9466', '#6EFF9E', '#FFFD6E', '#84A1FF', '#FFF6A4', '#94F4F4', '#FF9E8F', '#FF79D2', '#A4C5FF', '#E5FFB0', '#B1E9FF', '#F3FF98','#BC99F7', '#86C5FF', '#84ACFA' ],
      colorArrPQES: ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583','#65DB5F', '#EBD231', '#FF624A', '#FF6F31', '#56E886', '#DFDD43', '#6E90FF', '#FAEA53', '#54E7E7', '#FF816E', '#FF67CC', '#7AAAFF', '#C7F36E', '#6ED6FF', '#DDEB72', '#A97BF7' , '#67B6FD', '#5E95FF', '#FFC46B'],
      colorArrPQES2: ['#bd13ac', '#ed7fc6', '#63ba66', '#8436be', '#6bb4f1', '#5b64f9', '#d5e425', '#5cd64', '#5955f5', '#becf5b', '#629562', '#919bbf', '#1060e7', '#628636', '#886de1', '#dd68b', '#b3619', '#18cd9', '#c78a72', '#9775ef', '#b8a3e', '#165f99', '#e4dbe4', '#5569c6', '#187cc5', '#3eda59', '#efeb2e', '#7eb829', '#6ed77b', '#81673d', '#5aa6b5', '#35aaf6', '#6cdb2a', '#731fb0', '#aa4bee', '#a69082', '#262315', '#d7559a', '#59d95a', '#e6aa44'],
      colorArr: ['#5470c6', '#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583'],
      DeepColorArrDevice: ['#7EAD5E', '#EEB951', '#CF6158', '#69B0C8', '#E87340', '#6CCC8C', '#D2965C', '#6964C2', '#D2D15B', '#67B999', '#DC7F61', '#D65C87', '#6689C5', '#D98B42', '#65C1BB', '#9BCE58', '#725AAF', '#5166B8', '#5559A5', '#BBA962', '#5AC155', '#D6BE25', '#F14C33', '#21DFA2', '#F46324', '#38D06B', '#CBC91B', '#5378F1', '#E7D741', '#34CDCD', '#F36B56', '#EE4FB9', '#5D92F0', '#B0D95D', '#52BEE8', '#C1D13F', '#9465E2', '#54A3EA', '#447DEB', '#EEB155' ],
      DeepColorArr: ['#4A63B3', '#7EAD5E', '#EEB951', '#CF6158', '#69B0C8', '#E87340', '#6CCC8C', '#D2965C', '#6964C2', '#D2D15B', '#67B999', '#DC7F61', '#D65C87', '#6689C5', '#D98B42', '#65C1BB', '#9BCE58', '#725AAF', '#5166B8', '#5559A5', '#BBA962'],
      pickerOptions: {
        disabledDate(time) {
          // console.log(time)
          // 获取当前日期
          const currentDate = new Date();
          // 获取当前月份
          const currentMonth = currentDate.getMonth();
          // 获取选择的月份
          const selectedMonth = time.getMonth();
          // 计算当前月份和选择的月份的差值
          const diffMonths = (currentDate.getFullYear() - time.getFullYear()) * 12 + currentMonth - selectedMonth;
          // console.log(currentDate.getDate())
          // 只允许选择当前月份和之前的月份，并且最多选择四个月
          return diffMonths < 0;
        }
      },
    }
  },
  watch: {
    timeMonth(newVal) {
      // console.log(newVal)
      // 将日期字符串转换为日期对象并进行排序
      const sortedDates = newVal.map(dateStr => new Date(dateStr)).sort((a, b) => b - a);

// 将排序后的日期对象转换回字符串
      const sortedDateStrings = sortedDates.map(date => date.toISOString());
      if (newVal !== this.timeMonths && newVal.length > 3) {
        this.timeTableMonth = []
        this.timeMonths = sortedDateStrings
        this.timeMonths.map(range => {
          this.timeTableMonth.unshift(this.dateFmtYmTable(range))
        })
        // this.getListWeek()
      }
      if (newVal.length > 3) {
        //
        this.pickerOptions.disabledDate = (time) => {
          const currentDate = new Date();
          const currentMonth = currentDate.getMonth();
          const selectedMonth = time.getMonth();
          const diffMonths = (currentDate.getFullYear() - time.getFullYear()) * 12 + currentMonth - selectedMonth;

          // 大于等于4个日期，选择之外的日期禁用
          return diffMonths < 0 || !this.timeMonth.some(item => item.getMonth() === time.getMonth() && item.getFullYear() === time.getFullYear());
        }
      } else {
        this.$message({
          message: '必须选择四个日期',
          type: 'warning',
        });
        this.pickerOptions.disabledDate = (time) => {
          const currentDate = new Date();
          const currentMonth = currentDate.getMonth();
          const selectedMonth = time.getMonth();
          const diffMonths = (currentDate.getFullYear() - time.getFullYear()) * 12 + currentMonth - selectedMonth;

          // 禁用当前月份之后的月份
          return diffMonths < 0;
        };
      }
    }
  },
  created() {
    // this.checkOpenMethod();
    // setToken(this.$route.query.token);
    // 获取当前日期
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    this.timeMonth = [
      new Date(currentDate.getFullYear(), currentMonth, 1),
      new Date(currentDate.getFullYear(), currentMonth - 1, 1),
      new Date(currentDate.getFullYear(), currentMonth - 2, 1),
      new Date(currentDate.getFullYear(), currentMonth - 3, 1),
    ];
    this.timeMonths = this.timeMonth
    this.timeMonths.map(range => {
      this.timeTableMonth.unshift(this.dateFmtYmTable(range))
    })
    currentDate.setDate(currentDate.getDate() - 1);
// 初始化一个空数组，用于存储每周的周一日期
    const mondays = [];
    const sundays = []
// 往前推半年，共26周
    for (let i = 0; i < 26; i++) {
      // 计算当前周的周一日期
      const monday = new Date(currentDate);
      monday.setDate(currentDate.getDate() - currentDate.getDay() + 1 - i * 7); // 获取当前周的周一日期
      // console.log(this.dateFmt(new Date()))
      // console.log(this.dateFmt(monday))
      if (this.dateFmt(monday) !== this.dateFmt(new Date())) {
        mondays.unshift(this.dateFmt(monday));
      }
      // 计算当前周的周日日期
      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6); // 获取当前周的周日日期
      if (sunday > currentDate) {
        // console.log(this.dateFmt(currentDate))
        sundays.unshift(this.dateFmt(currentDate))
      } else {
        // console.log(this.dateFmt(sunday))
        sundays.unshift(this.dateFmt(sunday))
      }
    }
    mondays.forEach((item, index) => {
      this.dateArry.unshift({text: index, value: item + " -- " + sundays[index]})
    })
    this.time = this.dateArry.slice(0, 4).map(item => item.value)
    this.time.forEach(item => {
      this.times.unshift(item)
    })
    this.times.map(range => {
      let [startDate, endDate] = range.split(' -- ');
      let [startMonth, startDay] = startDate.split('-').slice(1);
      let [endMonth, endDay] = endDate.split('-').slice(1);
      this.timeTable.push(`${startMonth}.${startDay}-${endMonth}.${endDay}`)
    })
    this.getDate()
    this.getList()
    this.getListWeek()
  },
  mounted() {
    window.goBack = this.goBack
  },
  methods: {
    checkOpenMethod() {
      const isInApp = this.isInApp();
      console.log(isInApp)
      this.btnShow = !isInApp
      console.log(isInApp ? 'App' : 'Browser');
    },
    isInApp() {
      // 通过URL参数或者Hash来判断
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('from_app') === 'true' || window.location.hash.includes('from_app=true');
    },
    dateFmtYmTable(timestamp) {
      return moment(timestamp).format('YYYY.MM')
    },
    handleSelectTime(val) {
      this.times = []
      if (val.length !== 4) {
        this.$message({
          message: '必须选择四个日期',
          type: 'warning',
        });
        return
      } else {
        this.timeTable = []
        const sortedDates = val.map(date => {
          const [startDate, endDate] = date.split(' -- ');
          return {start: new Date(startDate), end: new Date(endDate)};
        });
        sortedDates.sort((a, b) => a.start - b.start);
        sortedDates.forEach((item) => {
          this.times.push(this.dateFmt(item.start) + ' -- ' + this.dateFmt(item.end))
        })
        this.times.map(range => {
          let [startDate, endDate] = range.split(' -- ');
          let [startMonth, startDay] = startDate.split('-').slice(1);
          let [endMonth, endDay] = endDate.split('-').slice(1);
          this.timeTable.push(`${startMonth}.${startDay}-${endMonth}.${endDay}`)
        })
        this.getListWeek()
      }
    },
    handleSelectDevice() {
      this.chartList.forEach(item => {
        if (this.device.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    handleSelectReason() {
      this.formDate.ysslInfo.forEach(item => {
        if (this.deviceyy.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    handleSelectxn() {
      this.chartXnjdlList.forEach(item => {
        if (this.devicexn.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    handleSelectdjsj() {
      this.chartYichangList.forEach(item => {
        if (this.devicedjsj.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    handleSelectdjyy() {
      this.formDateWeek.abnormalInfos.forEach(item => {
        if (this.devicedjyy.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    handleSelectsj() {
      this.chartTimeList.forEach(item => {
        if (this.devicesj.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    handleTime(index) {
      this.$refs.boxDiv.scrollTop = 0
      this.selectTime = index
    },
    // 日期
    getDate() {
      const date = new Date()
      const timestampDay = date.getTime()
      const timestamp = date.getTime() - 24 * 60 * 60 * 1000 //昨日时间戳
      const year = date.getFullYear() // 年
      let month = date.getMonth() + 1 // 月
      let day = new Date(timestamp).getDate() // 昨日
      let day2 = new Date(timestampDay).getDate()
      if ((date.getDate() - 1) === 0) {
        month = month - 1
      }
      month = month < 10 ? `0${month}` : month;
      day = day < 10 ? `0${day}` : day;
      this.year = `${year}`
      this.date = `${year}-${month}-${day}` // 用于展示、提交：默认昨日
      this.dateDay = `${year}年${month}月${day2}日`
      this.dateTime = `${year}年${month}月${day}日`
      this.currentDate = new Date(timestamp) // 默认选择昨日
      this.maxDate = new Date(timestamp) // 最大选择昨日

      // console.log(this.date)
    },
    // 获取列表
    getList() {
      // let xData = []
      // let dateData = []
      // let series = []
      // let legend = []
      this.xnjdlData = []
      this.sjjdlData = []
      this.ycdjData = []
      this.numberData = []
      this.timeData = []
      this.pqesInfos = []
      this.ysslData = []
      API.teamDayInfoReport({time: this.date}).then(res => {
        if (this.$store.getters.userInfo.teamName) {
          if (this.$store.getters.userInfo.teamName.includes('股份有限公司')) {
            this.teamName = this.$store.getters.userInfo.teamName.split('股份有限公司')[0]
          } else if (this.$store.getters.userInfo.teamName.includes('有限责任公司')) {
            this.teamName = this.$store.getters.userInfo.teamName.split('有限责任公司')[0]
          } else if (this.$store.getters.userInfo.teamName.includes('责任有限公司')) {
            this.teamName = this.$store.getters.userInfo.teamName.split('责任有限公司')[0]
          } else if (this.$store.getters.userInfo.teamName.includes('产业园有限公司')) {
            this.teamName = this.$store.getters.userInfo.teamName.split('产业园有限公司')[0]
          } else if (this.$store.getters.userInfo.teamName.includes('管理有限公司')) {
            this.teamName = this.$store.getters.userInfo.teamName.split('管理有限公司')[0]
          } else if (this.$store.getters.userInfo.teamName.includes('有限公司')) {
            this.teamName = this.$store.getters.userInfo.teamName.split('有限公司')[0]
          } else {
            this.teamName = this.$store.getters.userInfo.teamName
          }
        }
        this.formDate = res.message.data
        // 印刷数量
        const listDeviceTotal = [];
        const listDevice = []
        // 性能稼动率
        const xnjdlTotal = []
        const xnjdlList = []
        // 时间稼动率
        const sjjdlTotal = []
        const sjjdlList = []
        // 异常待机
        const ycdjTotal = []
        const ycdjList = []
        const reasonTotal = []
        const reasonList = []
        const auditTotal = []
        const auditList = []
        this.reasonName = []
        for (let i = 0; i < this.formDate.ysslInfo.length; i++) {
          let item = this.formDate.ysslInfo[i]
          item.show = false
          item.reasonName = []
          item.listDeviceTotal = {
            // 数据集
            source: [],
            xAxis: [{
              type: 'category',
              // name: '', // 设置为空字符串
              // nameTextStyle: {
              //   show: false // 设置不显示名称
              // },
              axisLabel: {
                textStyle: {
                  fontSize: 1, // 字体大小
                  fontFamily: 'Arial' // 字体类型
                }
              },
              data: null
            }],
            // 系列
            yAxis: [
              {
                type: 'value',
                splitLine: {show: true},
                position: 'left',
                axisLine: {
                  // show: true,
                  lineStyle: {
                    color: '#9E9E9E',
                  },
                },
                axisLabel: {
                  formatter: '{value}',
                },
              },
              {
                type: 'value',
                splitLine: {show: false},
                position: 'right',
                offset: 0,
                axisLine: {
                  // show: true,
                  lineStyle: {
                    color: '#9E9E9E',
                  },
                },
                axisLabel: {
                  formatter: '{value}',
                },
              },
            ],
            // 系列
            series: [
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 20,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: function (params) {
                      // 根据params的
                      const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583','#65DB5F', '#EBD231', '#FF624A', '#FF6F31', '#56E886', '#DFDD43', '#6E90FF', '#FAEA53', '#54E7E7', '#FF816E', '#FF67CC', '#7AAAFF', '#C7F36E', '#6ED6FF', '#DDEB72', '#A97BF7' , '#67B6FD', '#5E95FF', '#FFC46B']
                      //返回对应的颜色
                      return colorsMap[params.dataIndex]
                    },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'inside', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 12
                      },

                    }
                  },

                }
              },
              {
                type: 'line',
                // barWidth: 30,
                yAxisIndex: 1, connectNulls: true,
                color: '#F6BB17',
                itemStyle: {
                  normal: {
                    color: '#F6BB17',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 12
                      },
                      //{{a}}{abg|}{b}{c}
                      // formatter:'{{c}}',
                      // formatter: function (res) {
                      //   if (res.value) {
                      //     return res.value[2] + '%'
                      //   } else {
                      //     return 0
                      //   }
                      // },
                    }
                  },

                },
                lineStyle: {
                  width: 0 // 设置线宽为0，不显示线
                }
              }
            ],
          }
          const source = []
          if (item.name !== '合计') {
            for (const line of item.abnormalDtos) {
              item.reasonName.push(line.reason)
              source.push([
                line.reason,
                Number(line.count),
                line.time,
              ])
            }
            item.listDeviceTotal.source = [
              ['product', '已提交次数', '已提交时长'],
              ...source,
            ]
          }
        }
        this.name = []
        this.namePQES = []
        for (const item of this.formDate.ysslInfo) {
          if (item.name !== '合计') {
            this.name.push(item.name)
          }

          // 印刷数量
          this.ysslData.push({
            name: item.name,
            bzyl: Number(item.bzyl),
            sjyl: item.sjyl,
            ylRate: item.ylRate + '%',
          })
          // 性能稼动率
          this.xnjdlData.push({
            name: item.name,
            bzsd: Number(item.bzsd),
            sjsd: Number(item.sjsd),
            sdRate: Number(item.sdRate) + '%',
          })
          // console.log(this.xnjdlData)
          // 时间稼动率
          this.sjjdlData.push({
            name: item.name,
            sjHour: Number(item.sjHour),
            sjTime: Number(item.sjTime),
            bzHour: Number(item.bzHour),
            bzTime: Number(item.bzTime),
            timeRate: item.timeRate + '%',
          })
          this.ycdjData.push({
            name: item.name,
            shiYinJiaoSe: item.shiYinJiaoSe,
            qianYangDengDai: item.qianYangDengDai,
            luoBanGaiWenJian: item.luoBanGaiWenJian,
            tiaoZhuanSeDengDai: item.tiaoZhuanSeDengDai,
            caiLiaoYuanYin: item.caiLiaoYuanYin,
            guZhangQiangXiu: item.guZhangQiangXiu,
            dengLiao: item.dengLiao,
            qiTaYuanYin: item.qiTaYuanYin
          })
          this.timeData.push({
            name: item.name,
            shiYinJiaoSeTime: item.shiYinJiaoSeTime,
            qianYangDengDaiTime: item.qianYangDengDaiTime,
            luoBanGaiWenJianTime: item.luoBanGaiWenJianTime,
            tiaoZhuanSeDengDaiTime: item.tiaoZhuanSeDengDaiTime,
            caiLiaoYuanYinTime: item.caiLiaoYuanYinTime,
            guZhangQiangXiuTime: item.guZhangQiangXiuTime,
            dengLiaoTime: item.dengLiaoTime,
            qiTaYuanYinTime: item.qiTaYuanYinTime
          })
          // 异常待机
          if (item.name === '合计') {
            // 印刷数量
            listDeviceTotal.push([
              item.name,
              Number(item.sjyl),
              item.ylRate,
            ])
            // 性能稼动率
            xnjdlTotal.push([
              item.name,
              Number(item.sjsd),
              item.sdRate,
            ])
            // 时间稼动率
            sjjdlTotal.push([
              item.name,
              Number(item.sjTime),
              item.timeRate,
            ])
            // 异常待机
            ycdjTotal.push([
              item.name,
              Number(item.abnormalTime),
              item.abnormalCount,
            ])
            reasonTotal.push([
              item.name,
              Number(item.ytj),
              item.wtj,
            ])
            auditTotal.push([
              item.name,
              Number(item.ysh),
              item.wsh,
            ])
          } else {
            // 印刷数量
            listDevice.push([
              item.name,
              Number(item.sjyl),
              Number(item.ylRate),
            ]);
            // 性能稼动率
            xnjdlList.push([
              item.name,
              Number(item.sjsd),
              item.sdRate,
            ])
            //时间稼动率
            sjjdlList.push([
              item.name,
              Number(item.sjTime),
              item.timeRate,
            ])
            // 异常待机
            ycdjList.push([
              item.name,
              Number(item.abnormalTime),
              item.abnormalCount,
            ])
            reasonList.push([
              item.name,
              Number(item.ytj),
              item.wtj,
            ])
            auditList.push([
              item.name,
              Number(item.ysh),
              item.wsh,
            ])
            // this.numberData.push({
            //   label:item.name,
            //   prop:item.prop1
            // })
            // if (this.numberData.length > 4) {
            //   const subTableSize = 4; // 每个子表格的最大列数
            //   const tableCount = Math.ceil(this.numberData.length / subTableSize);
            //
            //   this.subTables2 = [];
            //
            //   for (let i = 0; i < tableCount; i++) {
            //     const start = i * subTableSize;
            //     const end = Math.min((i + 1) * subTableSize, this.numberData.length);
            //
            //     // 创建子表格数据
            //     this.subTables2.push({
            //       headers: this.numberData.slice(start, end),
            //       data: this.formDate.ycdjReasonInfo.map(row => {
            //         const subRow = { name: row.name }; // 保留 "设备" 列
            //         for (let j = start; j < end; j++) {
            //           subRow[this.numberData[j].prop] = row[this.numberData[j].prop]; // 对应列的数据
            //         }
            //         return subRow;
            //       })
            //     });
            //   }
            // } else {
            //   this.subTables2 = [{
            //     headers: this.numberData,
            //     data: this.formDate.ycdjReasonInfo
            //   }];
            // }
            // this.timeData.push({
            //   label:item.name,
            //   prop:item.prop2
            // })
            // if (this.timeData.length > 4) {
            //   const subTableSize = 4; // 每个子表格的最大列数
            //   const tableCount = Math.ceil(this.timeData.length / subTableSize);
            //
            //   this.subTables = [];
            //
            //   for (let i = 0; i < tableCount; i++) {
            //     const start = i * subTableSize;
            //     const end = Math.min((i + 1) * subTableSize, this.timeData.length);
            //
            //     // 创建子表格数据
            //     this.subTables.push({
            //       headers: this.timeData.slice(start, end),
            //       data: this.formDate.ycdjReasonInfo.map(row => {
            //         const subRow = { name: row.name }; // 保留 "设备" 列
            //         for (let j = start; j < end; j++) {
            //           subRow[this.timeData[j].prop] = row[this.timeData[j].prop]; // 对应列的数据
            //         }
            //         return subRow;
            //       })
            //     });
            //   }
            // } else {
            //   this.subTables = [{
            //     headers: this.timeData,
            //     data: this.formDate.ycdjReasonInfo
            //   }];
            // }
          }
        }
        // 印刷数量
        this.ysslTotal.source = [
          ['product', '实际印量', '产能利用率'],
          ...listDeviceTotal,
        ]
        this.yssl.source = [
          ['product', '实际印量', '产能利用率'],
          ...listDevice,
        ];
        // 性能稼动率
        this.xnjdlTotal.source = [
          ['product', '实际运行速度', '性能稼动率'],
          ...xnjdlTotal,
        ]
        this.xnjdl.source = [
          ['product', '实际运行速度', '性能稼动率'],
          ...xnjdlList,
        ]
        // 时间稼动率
        this.sjjdlTotal.source = [
          ['product', '有效印刷时长', '有效时间稼动率'],
          ...sjjdlTotal,
        ]
        this.sjjdl.source = [
          ['product', '有效印刷时长', '有效时间稼动率'],
          ...sjjdlList,
        ]
        // 异常待机
        this.ycdjTotal.source = [
          ['product', '待机时长', '待机次数'],
          ...ycdjTotal,
        ]
        this.ycdj.source = [
          ['product', '待机时长', '待机次数'],
          ...ycdjList,
        ]
        this.reasonTotal.source = [
          ['product', '已提交', '未提交'],
          ...reasonTotal,
        ]
        this.reason.source = [
          ['product', '已提交', '未提交'],
          ...reasonList,
        ]
        this.auditTotal.source = [
          ['product', '已审核', '未审核'],
          ...auditTotal,
        ]
        this.audit.source = [
          ['product', '已审核', '未审核'],
          ...auditList,
        ]
        const pqesList = []
        for (const line of this.formDate.pqesInfos) {
          this.namePQES.push(line.name + ": " + line.groupName)
          pqesList.push([
            line.name + ": " + line.groupName,
            Number(line.sjCount),
            Number(line.score),
          ]);
        }
        this.pqesData.source = [
          ['product', '次数', '得分'],
          ...pqesList,
        ]
      })
    },
    getListWeek() {
      this.xnjdlDataWeek = []
      this.timeDataWeek = []
      this.ysslDataWeek = []
      this.timeDatazong = []
      this.chartList = []
      // this.chartDataYichangZong = []
      // this.chartDataYichangZongCount = []
      this.yichangDatazong = []
      this.chartYichangList = []
      this.reasonCount = []
      this.reasonTime = []
      this.deviceName = []
      this.chartXnjdlList = []
      this.chartTimeList = []
      this.nameWeek = []
      this.yichangs1 = []
      this.formDateWeek.countList = []
      this.formDateWeek.timeList = []
      this.formDateWeek.abnormalInfos = []
      // this.abnormalInfos = []


      API.teamDayWeekReport({
        time1: this.times[3],
        time2: this.times[2],
        time3: this.times[1],
        time4: this.times[0]
      }).then(res => {
        console.log(res)
        this.formDateWeek = res.message.data
        //   // 印刷数量
        const listDevice = []
        for (const item of this.formDateWeek.yinLiangs) {
          if (item.name !== '合计') {
            this.chartList.push(item)
            this.nameWeek.push(item.name)
          }
          // 印刷数量
          this.ysslDataWeek.push({
            name: item.name,
            value: item.value,
            value1: item.value1,
            value2: item.value2,
            value3: item.value3,
            value4: item.value4,
            value5: item.value5,
            value6: item.value6,
            value7: item.value7,
            value8: item.value8,
          })
        }
        // console.log(this.nameWeek)
        this.ysslDataZong = [
          {
            name: '合计',
            value: Number(this.ysslDataWeek[0].value * 4),
            value1: Number(this.ysslDataWeek[0].value1) + Number(this.ysslDataWeek[0].value3) + Number(this.ysslDataWeek[0].value5) + Number(this.ysslDataWeek[0].value7),
            value2: Math.round((Number(this.ysslDataWeek[0].value2) + Number(this.ysslDataWeek[0].value4) + Number(this.ysslDataWeek[0].value6) + Number(this.ysslDataWeek[0].value8)) / 4),
          },
          {
            name: '本周' + '(' + (this.timeTable[3]) + ')',
            value: Number(this.ysslDataWeek[0].value),
            value1: this.ysslDataWeek[0].value1,
            value2: this.ysslDataWeek[0].value2,
          },
          {
            name: '上周' + '(' + this.timeTable[2] + ')',
            value: this.ysslDataWeek[0].value,
            value1: this.ysslDataWeek[0].value3,
            value2: this.ysslDataWeek[0].value4,
          },
          {
            name: '上上周' + '(' + (this.timeTable[1]) + ')',
            value: this.ysslDataWeek[0].value,
            value1: this.ysslDataWeek[0].value5,
            value2: this.ysslDataWeek[0].value6,
          },
          {
            name: '上数三周' + '(' + (this.timeTable[0]) + ')',
            value: this.ysslDataWeek[0].value,
            value1: this.ysslDataWeek[0].value7,
            value2: this.ysslDataWeek[0].value8,
          }
        ]
        this.ysslDataZong.forEach(item => {
          if (item.name !== '合计') {
            listDevice.push([
              item.name,
              Number(item.value1),
              Number(item.value2 / 10),
            ]);
          }
        })
        this.deviceName = []
        this.device = []
        this.deviceyy = []
        this.devicexn = []
        this.devicesj = []
        this.devicedjsj = []
        this.devicedjyy = []
        this.chartList.forEach((item) => {
          if (item.name !== '合计') {
            this.deviceName.push(item.name)
            this.device.push(item.name)
            this.deviceyy.push(item.name)
            this.devicexn.push(item.name)
            this.devicesj.push(item.name)
            this.devicedjsj.push(item.name)
            this.devicedjyy.push(item.name)
            item.chartData = {
              // 数据集
              source: [],
              xAxis: [{
                type: 'category',
                name: '', // 设置为空字符串
                // nameTextStyle: {
                //   show: false // 设置不显示名称
                // },
                axisLabel: {
                  textStyle: {
                    fontSize: 14, // 字体大小
                    fontFamily: 'Arial' // 字体类型
                  },
                  interval: 0,
                  formatter: function (value) {
                    // 使用正则表达式分割文本
                    // var ret = value.split('\n');
                    if (value) {
                      return value.match(/\((\d{2}\.\d{2}-\d{2}\.\d{2})\)/)[1];
                    } else {
                      return value;
                    }
                  }
                },
                data: null
              }],
              // 系列
              yAxis: [
                {
                  type: 'value',
                  splitLine: {show: true},
                  position: 'left',
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
                {
                  type: 'value',
                  splitLine: {show: false},
                  position: 'right',
                  offset: 0,
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}%',
                  },
                },
              ],
              // 系列
              series: [
                {
                  type: 'bar',
                  yAxisIndex: 0, connectNulls: true,
                  // maxBarWidth: 15,
                  barWidth: 30,
                  color: '#ffc553',
                  itemStyle: {
                    normal: {
                      color: function (params) {
                        // 根据params的
                        const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                        //返回对应的颜色
                        return colorsMap[params.dataIndex]
                      },
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      // borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'inside', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },

                      }
                    },

                  }
                },
                {
                  type: 'line',
                  // barWidth: 30,
                  yAxisIndex: 1, connectNulls: true,
                  color: '#F6BB17',
                  itemStyle: {
                    normal: {
                      color: '#F6BB17',
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                        formatter: function (res) {
                          if (res.value) {
                            return res.value[2] + '%'
                          } else {
                            return 0
                          }
                        },
                      }
                    },

                  }
                }
              ],
            }
            if (!item.show) {
              item.show = true
            }
            const listDeviceEchart = [];
            this.ysslDataZong.forEach(line => {
              if (line.name.split('(')[0] === '本周') {
                listDeviceEchart.push([
                  line.name,
                  item.value1,
                  item.value2 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上周') {
                listDeviceEchart.push([
                  line.name,
                  item.value3,
                  item.value4 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上上周') {
                listDeviceEchart.push([
                  line.name,
                  item.value5,
                  item.value6 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上数三周') {
                listDeviceEchart.push([
                  line.name,
                  item.value7,
                  item.value8 / 10,
                ]);
              }
            })
            item.chartData.source = [
              ['product', '周实际印量', '周平均产能利用率'],
              ...listDeviceEchart,
            ]
          }
        })
        this.ysslWeek.source = [
          ['product', '周实际印量', '周平均产能利用率'],
          ...listDevice,
        ];

        // 性能稼动率
        const listXnjdlDevice = []
        const listDeviceEchartzong = [];
        for (const item of this.formDateWeek.xingNengs) {
          if (item.name !== '合计') {
            this.chartXnjdlList.push(item)
          }
          this.xnjdlDataWeek.push({
            name: item.name,
            value: item.value,
            value1: item.value1,
            value2: item.value2,
            value3: item.value3,
            value4: item.value4,
            value5: item.value5,
            value6: item.value6,
            value7: item.value7,
            value8: item.value8,
            value9: item.value9,
            value10: item.value10,
          })
        }
        this.xnjdlDataZong = [
          {
            name: '合计',
            value: this.xnjdlDataWeek[0].value * 4,
            value1: Math.round((Number(this.xnjdlDataWeek[0].value1) + Number(this.xnjdlDataWeek[0].value3) + Number(this.xnjdlDataWeek[0].value5) + Number(this.xnjdlDataWeek[0].value7)) / 4),
            value2: Math.round(((Number(this.xnjdlDataWeek[0].value2) + Number(this.xnjdlDataWeek[0].value4) + Number(this.xnjdlDataWeek[0].value6) + Number(this.xnjdlDataWeek[0].value8)) / 4)),
          },
          {
            name: '本周' + '(' + (this.timeTable[3]) + ')',
            value: this.xnjdlDataWeek[0].value,
            value1: this.xnjdlDataWeek[0].value1,
            value2: this.xnjdlDataWeek[0].value2,
          },
          {
            name: '上周' + '(' + (this.timeTable[2]) + ')',
            value: this.xnjdlDataWeek[0].value,
            value1: this.xnjdlDataWeek[0].value3,
            value2: this.xnjdlDataWeek[0].value4,
          },
          {
            name: '上上周' + '(' + (this.timeTable[1]) + ')',
            value: this.xnjdlDataWeek[0].value,
            value1: this.xnjdlDataWeek[0].value5,
            value2: this.xnjdlDataWeek[0].value6,
          },
          {
            name: '上数三周' + '(' + (this.timeTable[0]) + ')',
            value: this.xnjdlDataWeek[0].value,
            value1: this.xnjdlDataWeek[0].value7,
            value2: this.xnjdlDataWeek[0].value8,
          }
        ]
        this.xnjdlDataZong.forEach(item => {
          if (item.name !== '合计') {
            listXnjdlDevice.push([
              item.name,
              Number(item.value1),
              Number(item.value2 / 10),
            ]);
          }
        })
        this.chartXnjdlList.forEach((item) => {
          if (item.name !== '合计') {
            if (!item.show) {
              item.show = true
            }
            item.chartData = {
              // 数据集
              source: [],
              xAxis: [{
                type: 'category',
                name: '', // 设置为空字符串
                // nameTextStyle: {
                //   show: false // 设置不显示名称
                // },
                axisLabel: {
                  textStyle: {
                    fontSize: 14, // 字体大小
                    fontFamily: 'Arial' // 字体类型
                  },
                  interval: 0,
                  formatter: function (value) {
                    if (value) {
                      return value.match(/\((\d{2}\.\d{2}-\d{2}\.\d{2})\)/)[1];
                    } else {
                      return value;
                    }
                  }
                },
                data: null
              }],
              // 系列
              yAxis: [
                {
                  type: 'value',
                  splitLine: {show: true},
                  position: 'left',
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
                {
                  type: 'value',
                  splitLine: {show: false},
                  position: 'right',
                  offset: 0,
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}%',
                  },
                },
              ],
              // 系列
              series: [
                {
                  type: 'bar',
                  yAxisIndex: 0, connectNulls: true,
                  // maxBarWidth: 15,
                  barWidth: 30,
                  color: '#ffc553',
                  itemStyle: {
                    normal: {
                      color: function (params) {
                        // 根据params的
                        const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                        //返回对应的颜色
                        return colorsMap[params.dataIndex]
                      },
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      // borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'inside', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },

                      }
                    },

                  }
                },
                {
                  type: 'line',
                  // barWidth: 30,
                  yAxisIndex: 1, connectNulls: true,
                  color: '#F6BB17',
                  itemStyle: {
                    normal: {
                      color: '#F6BB17',
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                        formatter: function (res) {
                          if (res.value) {
                            return res.value[2] + '%'
                          } else {
                            return 0
                          }
                        },
                      }
                    },

                  }
                }
              ],
            }
            if (!item.show) {
              item.show = true
            }

            const listDeviceEchart = [];
            if (item.name !== '合计') {
              listDeviceEchartzong.push([
                item.name,
                item.value9,
                item.value10 / 10,
              ])
            }
            this.xnjdlDataZong.forEach(line => {
              if (line.name.split('(')[0] === '本周') {
                listDeviceEchart.push([
                  line.name,
                  item.value1,
                  item.value2 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上周') {
                listDeviceEchart.push([
                  line.name,
                  item.value3,
                  item.value4 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上上周') {
                listDeviceEchart.push([
                  line.name,
                  item.value5,
                  item.value6 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上数三周') {
                listDeviceEchart.push([
                  line.name,
                  item.value7,
                  item.value8 / 10,
                ]);
              }
            })
            item.chartData.source = [
              ['product', '实际运行速度', '性能稼动率'],
              ...listDeviceEchart,
            ]

          }
        })
        //

        this.xnjdlWeek.source = [
          ['product', '实际运行速度', '平均性能稼动率'],
          ...listXnjdlDevice,
        ];
        this.chartDataZong.source = [
          ['product', '4周实际运行速度', '4周平均性能稼动率'],
          ...listDeviceEchartzong,
        ]
        // 时间稼动率
        const listTimeDevice = []
        const listTimeEchartzong = [];
        for (const item of this.formDateWeek.shiJians) {
          if (item.name !== '合计') {
            this.chartTimeList.push(item)
          }
          this.timeDatazong.push({
            name: item.name,
            value: item.value,
            value1: item.value1,
            value2: item.value2,
            value3: item.value3,
            value4: item.value4,
            value5: item.value5,
            value6: item.value6,
            value7: item.value7,
            value8: item.value8,
            value9: item.value9,
            value10: item.value10,
          })
        }
        this.timeDataWeek = [
          {
            name: '合计',
            value: this.timeDatazong[0].value * 4,
            value1: Number(this.timeDatazong[0].value1) + Number(this.timeDatazong[0].value3) + Number(this.timeDatazong[0].value5) + Number(this.timeDatazong[0].value7),
            value2: Math.round(((Number(this.timeDatazong[0].value2) + Number(this.timeDatazong[0].value4) + Number(this.timeDatazong[0].value6) + Number(this.timeDatazong[0].value8)) / 4)),
          },
          {
            name: '本周' + '(' + (this.timeTable[3]) + ')',
            value: this.timeDatazong[0].value,
            value1: this.timeDatazong[0].value1,
            value2: this.timeDatazong[0].value2,
          },
          {
            name: '上周' + '(' + this.timeTable[2] + ')',
            value: this.timeDatazong[0].value,
            value1: this.timeDatazong[0].value3,
            value2: this.timeDatazong[0].value4,
          },
          {
            name: '上上周' + '(' + this.timeTable[1] + ')',
            value: this.timeDatazong[0].value,
            value1: this.timeDatazong[0].value5,
            value2: this.timeDatazong[0].value6,
          },
          {
            name: '上数三周' + '(' + this.timeTable[0] + ')',
            value: this.timeDatazong[0].value,
            value1: this.timeDatazong[0].value7,
            value2: this.timeDatazong[0].value8,
          }
        ]
        this.timeDataWeek.forEach(item => {
          if (item.name !== '合计') {
            listTimeDevice.push([
              item.name,
              Number(item.value1),
              Number(item.value2 / 10),
            ]);
          }
        })
        this.chartTimeList.forEach((item) => {
          if (item.name !== '合计') {
            if (!item.show) {
              item.show = true
            }
            item.chartData = {
              // 数据集
              source: [],
              xAxis: [{
                type: 'category',
                name: '', // 设置为空字符串
                // nameTextStyle: {
                //   show: false // 设置不显示名称
                // },
                axisLabel: {
                  textStyle: {
                    fontSize: 14, // 字体大小
                    fontFamily: 'Arial' // 字体类型
                  },
                  interval: 0,
                  formatter: function (value) {
                    if (value) {
                      return value.match(/\((\d{2}\.\d{2}-\d{2}\.\d{2})\)/)[1];
                    } else {
                      return value;
                    }
                  }
                },
                data: null
              }],
              // 系列
              yAxis: [
                {
                  type: 'value',
                  splitLine: {show: true},
                  position: 'left',
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
                {
                  type: 'value',
                  splitLine: {show: false},
                  position: 'right',
                  offset: 0,
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}%',
                  },
                },
              ],
              // 系列
              series: [
                {
                  type: 'bar',
                  yAxisIndex: 0, connectNulls: true,
                  // maxBarWidth: 15,
                  barWidth: 30,
                  color: '#ffc553',
                  itemStyle: {
                    normal: {
                      color: function (params) {
                        // 根据params的
                        const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                        //返回对应的颜色
                        return colorsMap[params.dataIndex]
                      },
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      // borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'inside', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                      }
                    },

                  }
                },
                {
                  type: 'line',
                  // barWidth: 30,
                  yAxisIndex: 1, connectNulls: true,
                  color: '#F6BB17',
                  itemStyle: {
                    normal: {
                      color: '#F6BB17',
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                        formatter: function (res) {
                          if (res.value) {
                            return res.value[2] + '%'
                          } else {
                            return 0
                          }
                        },
                      }
                    },

                  }
                }
              ],
            }
            if (!item.show) {
              item.show = true
            }

            const listDeviceEchart = [];
            if (item.name !== '合计') {
              listTimeEchartzong.push([
                item.name,
                item.value9,
                item.value10 / 10,
              ])
            }
            this.timeDataWeek.forEach(line => {
              if (line.name.split('(')[0] === '本周') {
                listDeviceEchart.push([
                  line.name,
                  item.value1,
                  item.value2 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上周') {
                listDeviceEchart.push([
                  line.name,
                  item.value3,
                  item.value4 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上上周') {
                listDeviceEchart.push([
                  line.name,
                  item.value5,
                  item.value6 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上数三周') {
                listDeviceEchart.push([
                  line.name,
                  item.value7,
                  item.value8 / 10,
                ]);
              }
            })
            item.chartData.source = [
              ['product', '周有效印刷时长', '周平均有效时间稼动率'],
              ...listDeviceEchart,
            ]

          }
        })
        //
        this.sjjdlWeek.source = [
          ['product', '周有效印刷时长', '周平均有效时间稼动率'],
          ...listTimeDevice,
        ];
        this.chartDataTimeZong.source = [
          ['product', '4周有效印刷时长', '4周平均有效时间稼动率'],
          ...listTimeEchartzong,
        ]

        // 四、异常待机数据
        const listYichangTotal = [];
        const listYichangDevice = []
        const listYichangEchartzong = [];
        const listYichangEchartzongCount = []
        let valuedj1 = 0
        let valuedj3 = 0
        let valuedj5 = 0
        for (const item of this.formDateWeek.yichangs) {
          if (item.name !== '合计') {
            this.chartYichangList.push(item)
            valuedj1 += Math.round((item.value11 / 60) * 10) / 10 + Math.round((item.value13 / 60) * 10) / 10 ;
            valuedj3 += Math.round((item.value11 / 60) * 10) / 10;
            valuedj5 += Math.round((item.value13 / 60) * 10) / 10;
            this.yichangDatazong.push({
              name: item.name,
              value: item.value,
              value1: item.value1,
              value2: item.value2,
              value3: item.value3,
              value4: item.value4,
              value5: item.value5,
              value6: item.value6,
              value7: item.value7,
              value8: item.value8,
              value9: item.value9,
              value10: item.value10,
              value11: item.value11,
              value12: item.value12,
              value13: item.value13,
              value14: item.value14,
            })
          }

        }
        this.yichangDatazong.unshift({
          name: '合计',
          value: this.formDateWeek.yichangs[0].value,
          value1: this.formDateWeek.yichangs[0].value1,
          value2: this.formDateWeek.yichangs[0].value2,
          value3: this.formDateWeek.yichangs[0].value3,
          value4: this.formDateWeek.yichangs[0].value4,
          value5: this.formDateWeek.yichangs[0].value5,
          value6: this.formDateWeek.yichangs[0].value6,
          value7: this.formDateWeek.yichangs[0].value7,
          value8: this.formDateWeek.yichangs[0].value8,
          value9: valuedj1,
          value10: this.formDateWeek.yichangs[0].value10,
          value11: valuedj3,
          value12: this.formDateWeek.yichangs[0].value12,
          value13: valuedj5,
          value14: this.formDateWeek.yichangs[0].value14,
        })
        this.yichangData = [
          {
            name: '合计',
            value: this.yichangDatazong[0].value,
            value1: Number(this.yichangDatazong[0].value1) + Number(this.yichangDatazong[0].value3) + Number(this.yichangDatazong[0].value5) + Number(this.yichangDatazong[0].value7),
            value2: Number(this.yichangDatazong[0].value2) + Number(this.yichangDatazong[0].value4) + Number(this.yichangDatazong[0].value6) + Number(this.yichangDatazong[0].value8),
          },
          {
            name: '本周',
            value: this.yichangDatazong[0].value,
            value1: this.yichangDatazong[0].value1,
            value2: this.yichangDatazong[0].value2,
          },
          {
            name: '上周',
            value: this.yichangDatazong[0].value,
            value1: this.yichangDatazong[0].value3,
            value2: this.yichangDatazong[0].value4,
          },
          {
            name: '上上周',
            value: this.yichangDatazong[0].value,
            value1: this.yichangDatazong[0].value5,
            value2: this.yichangDatazong[0].value6,
          },
          {
            name: '上数三周',
            value: this.yichangDatazong[0].value,
            value1: this.yichangDatazong[0].value7,
            value2: this.yichangDatazong[0].value8,
          }
        ]
        this.yichangData.forEach(item => {
          if (item.name === '合计') {
            listYichangTotal.push([
              item.name,
              Number(item.value1),
              Number(item.value2),
            ]);
          } else {
            listYichangDevice.push([
              item.name,
              Number(item.value1),
              Number(item.value2),
            ]);
          }
        })
        this.chartYichangList.forEach((item) => {
          if (item.name !== '合计') {
            if (!item.show) {
              item.show = true
            }
            item.showZong = false
            item.chartData = {
              // 数据集
              source: [],
              xAxis: [{
                type: 'category',
                name: '', // 设置为空字符串
                // nameTextStyle: {
                //   show: false // 设置不显示名称
                // },
                axisLabel: {
                  textStyle: {
                    fontSize: 14, // 字体大小
                    fontFamily: 'Arial' // 字体类型
                  },
                  interval: 0,
                  formatter: function (value) {
                    if (value) {
                      return value;
                    } else {
                      return value;
                    }
                  }
                },
                data: null
              }],
              // 系列
              yAxis: [
                {
                  type: 'value',
                  splitLine: {show: true},
                  position: 'left',
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
                {
                  type: 'value',
                  splitLine: {show: false},
                  position: 'right',
                  offset: 0,
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
              ],
              // 系列
              series: [
                {
                  type: 'bar',
                  yAxisIndex: 0, connectNulls: true,
                  // maxBarWidth: 15,
                  barWidth: 30,
                  color: '#ffc553',
                  itemStyle: {
                    normal: {
                      color: function (params) {
                        // 根据params的
                        const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                        //返回对应的颜色
                        return colorsMap[params.dataIndex]
                      },
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      // borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'inside', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },

                      }
                    },

                  }
                },
                {
                  type: 'line',
                  // barWidth: 30,
                  yAxisIndex: 1, connectNulls: true,
                  color: '#F6BB17',
                  itemStyle: {
                    normal: {
                      color: '#F6BB17',
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                        // formatter: function (res) {
                        //   if (res.value) {
                        //     return res.value[2] + '%'
                        //   } else {
                        //     return 0
                        //   }
                        // },
                      }
                    },

                  }
                }
              ],
            }

            const listDeviceEchart = [];
            if (item.name !== '合计') {
              listYichangEchartzong.push([
                item.name,
                (Math.round((item.value11 / 60) * 10) / 10 + Math.round((item.value13 / 60) * 10) / 10).toFixed(1),
                Math.round((item.value11 / 60) * 10) / 10,
                Math.round((item.value13 / 60) * 10) / 10,
              ])
              listYichangEchartzongCount.push([
                item.name,
                item.value10,
                item.value12,
                item.value14,
              ])
            }
            this.yichangData.forEach(line => {
              if (line.name === '本周') {
                listDeviceEchart.push([
                  this.timeTable[3],
                  (item.value1 / 60).toFixed(1),
                  item.value2,
                ]);
              } else if (line.name === '上周') {
                listDeviceEchart.push([
                  this.timeTable[2],
                  (item.value3 / 60).toFixed(1),
                  item.value4,
                ]);
              } else if (line.name === '上上周') {
                listDeviceEchart.push([
                  this.timeTable[1],
                  (item.value5 / 60).toFixed(1),
                  item.value6,
                ]);
              } else if (line.name === '上数三周') {
                listDeviceEchart.push([
                  this.timeTable[0],
                  (item.value7 / 60).toFixed(1),
                  item.value8,
                ]);
              }
            })
            item.chartData.source = [
              ['product', '待机时长', '待机次数'],
              ...listDeviceEchart,
            ]
          }
        })
        this.chartDataYichangZong.source = [
          ['product', '异常待机时长', '待机原因已提交时长', '待机原因未提交时长'],
          ...listYichangEchartzong,
        ]
        this.chartDataYichangZongCount.source = [
          ['product', '异常待机次数', '待机原因已提交次数', '待机原因未提交次数'],
          ...listYichangEchartzongCount,
        ]
        const weekHourEchart = []
        const weekCountEchart = []
        //
        let value1 = 0
        let value3 = 0
        let value5 = 0
        this.formDateWeek.yichangs1.forEach((item, index) => {
          if (item.name !== '合计') {
            value1 += Math.round((item.value3 / 60) * 10) / 10 + Math.round((item.value5 / 60) * 10) / 10 ;
            value3 += Math.round((item.value3 / 60) * 10) / 10;
            value5 += Math.round((item.value5 / 60) * 10) / 10;
          }
          if (item.name !== '合计') {
            this.yichangs1.push({
              name: item.name + '(' + (this.timeTable[4 - index]) + ')',
              value1: item.value1,
              value2: item.value2,
              value3: item.value3,
              value4: item.value4,
              value5: item.value5,
              value6: item.value6,
            })
            weekHourEchart.push([
              this.timeTable[4 - index],
              (Math.round((item.value3 / 60) * 10) / 10 + Math.round((item.value5 / 60) * 10) / 10).toFixed(1),
              Math.round((item.value3 / 60) * 10) / 10,
              Math.round((item.value5 / 60) * 10) / 10,
            ]);
            weekCountEchart.push([
              this.timeTable[4 - index],
              item.value2,
              item.value4,
              item.value6,
            ]);
          }
        })
        this.yichangs1.unshift({
          name: '合计',
          value1: value1,
          value2: this.formDateWeek.yichangs1[0].value2,
          value3: value3,
          value4: this.formDateWeek.yichangs1[0].value4,
          value5: value5,
          value6: this.formDateWeek.yichangs1[0].value6,
        })
        this.ycdjTotalWeek.source = [
          ['product', '异常待机时长', '待机原因已提交时长', '待机原因未提交时长'],
          ...weekHourEchart,
        ]
        this.ycdjWeek.source = [
          ['product', '异常待机次数', '待机原因已提交次数', '待机原因未提交次数'],
          ...weekCountEchart,
        ];

        // 异常待机原因
        const arr = []
        const arrTime = []
        this.formDateWeek.abnormalInfos.forEach(item => {
          let sumValues = Array.from({length: 10}, () => 0); // 初始化一个长度为10的数组，用于存储每个value的和
          let sumTime = Array.from({length: 10}, () => 0); // 初始化一个长度为10的数组，用于存储每个value的和
          item.ycCounts.forEach((line, index) => {
            arr.push({
              name: line.name + '(' + (this.timeTable[3 - index]) + ')',
              value1: line.value1,
              value2: line.value2,
              value3: line.value3,
              value4: line.value4,
              value5: line.value5,
              value6: line.value6,
              value7: line.value7,
              value8: line.value8,
              value9: line.value9,
              value10: line.value10,
            })
            for (let i = 0; i < 10; i++) {
              sumValues[i] += line[`value${i + 1}`];
            }
          })
          // 添加合计对象到arr数组中
          arr.unshift({
            name: '合计',
            value1: sumValues[0],
            value2: sumValues[1],
            value3: sumValues[2],
            value4: sumValues[3],
            value5: sumValues[4],
            value6: sumValues[5],
            value7: sumValues[6],
            value8: sumValues[7],
            value9: sumValues[8],
            value10: sumValues[9],
          });
          item.ycTimes.forEach((line, index) => {
            arrTime.push({
              name: line.name + '(' + (this.timeTable[3 - index]) + ')',
              value1: line.value1,
              value2: line.value2,
              value3: line.value3,
              value4: line.value4,
              value5: line.value5,
              value6: line.value6,
              value7: line.value7,
              value8: line.value8,
              value9: line.value9,
              value10: line.value10,
            })
            for (let i = 0; i < 10; i++) {
              sumTime[i] += line[`value${i + 1}`];
            }
          })
          arrTime.unshift({
            name: '合计',
            value1: sumTime[0],
            value2: sumTime[1],
            value3: sumTime[2],
            value4: sumTime[3],
            value5: sumTime[4],
            value6: sumTime[5],
            value7: sumTime[6],
            value8: sumTime[7],
            value9: sumTime[8],
            value10: sumTime[9],
          });
        })
        let temp = {};

        arr.forEach(obj => {
          if (temp[obj.name]) {
            temp[obj.name].value1 += Number(obj.value1);
            temp[obj.name].value2 += obj.value2;
            temp[obj.name].value3 += obj.value3;
            temp[obj.name].value4 += obj.value4;
            temp[obj.name].value5 += obj.value5;
            temp[obj.name].value6 += obj.value6;
            temp[obj.name].value7 += obj.value7;
            temp[obj.name].value8 += obj.value8;
            temp[obj.name].value9 += obj.value9;
            temp[obj.name].value10 += obj.value10;
          } else {
            temp[obj.name] = {...obj};
          }
        });
        for (const key in temp) {
          this.reasonCount.push(temp[key]);
        }
        let tempTime = {};

        arrTime.forEach(obj => {
          if (tempTime[obj.name]) {
            tempTime[obj.name].value1 += Number(obj.value1);
            tempTime[obj.name].value2 += obj.value2;
            tempTime[obj.name].value3 += obj.value3;
            tempTime[obj.name].value4 += obj.value4;
            tempTime[obj.name].value5 += obj.value5;
            tempTime[obj.name].value6 += obj.value6;
            tempTime[obj.name].value7 += obj.value7;
            tempTime[obj.name].value8 += obj.value8;
            tempTime[obj.name].value9 += obj.value9;
            tempTime[obj.name].value10 += obj.value10;
          } else {
            tempTime[obj.name] = {...obj};
          }
        });
        for (const key in tempTime) {
          this.reasonTime.push(tempTime[key]);
        }

        this.formDateWeek.abnormalInfos.map(item => {
          const countEchart = []
          if (!item.show) {
            item.show = true
          }
          item.valueCount = 0
          item.valueTime = 0
          item.showTime = false
          item.chartData = {
            // 数据集
            source: [],
            xAxis: [{
              type: 'category',
              name: '', // 设置为空字符串
              // nameTextStyle: {
              //   show: false // 设置不显示名称
              // },
              axisLabel: {
                textStyle: {
                  fontSize: 14, // 字体大小
                  fontFamily: 'Arial' // 字体类型
                },
                interval: 0,
                formatter: function (value) {
                  if (value) {
                    return value;
                  } else {
                    return value;
                  }
                }
              },
              data: null
            }],
            // 系列
            yAxis: [
              {
                type: 'value',
                splitLine: {show: true},
                position: 'left',
                axisLine: {
                  // show: true,
                  lineStyle: {
                    color: '#9E9E9E',
                  },
                },
                axisLabel: {
                  formatter: '{value}',
                },
              },
              {
                type: 'value',
                splitLine: {show: false},
                position: 'right',
                offset: 0,
                axisLine: {
                  // show: true,
                  lineStyle: {
                    color: '#9E9E9E',
                  },
                },
                axisLabel: {
                  formatter: '{value}%',
                },
              },
            ],
            series: [
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#97CE71',
                    // color: function (params) {
                    //   // 根据params的
                    //   const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                    //   //返回对应的颜色
                    //   return colorsMap[params.dataIndex]
                    // },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#FFC553',
                    // color: function (params) {
                    //   // 根据params的
                    //   const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                    //   //返回对应的颜色
                    //   return colorsMap[params.dataIndex]
                    // },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#E96D63',
                    // color: function (params) {
                    //   // 根据params的
                    //   const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                    //   //返回对应的颜色
                    //   return colorsMap[params.dataIndex]
                    // },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#71C1DC',
                    // color: function (params) {
                    //   // 根据params的
                    //   const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                    //   //返回对应的颜色
                    //   return colorsMap[params.dataIndex]
                    // },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#FE814B',
                    // color: function (params) {
                    //   // 根据params的
                    //   const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                    //   //返回对应的颜色
                    //   return colorsMap[params.dataIndex]
                    // },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#71DC95',
                    // color: function (params) {
                    //   // 根据params的
                    //   const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                    //   //返回对应的颜色
                    //   return colorsMap[params.dataIndex]
                    // },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
            ]
          };
          item.ycCounts.forEach((line, index) => {
            countEchart.push([
              this.timeTable[3 - index],
              line.value3,
              line.value4,
              line.value5,
              line.value6,
              line.value7,
              line.value8,
            ]);
            item.valueCount += Number(line.value9)
          })
          item.chartData.source = []
          item.chartData.source = [
            ['product', ...this.formDateWeek.countList],
            ...countEchart,
          ]

          const timeEchart = []
          item.chartDataTime = {
            // 数据集
            source: [],
            xAxis: [{
              type: 'category',
              name: '', // 设置为空字符串
              // nameTextStyle: {
              //   show: false // 设置不显示名称
              // },
              axisLabel: {
                textStyle: {
                  fontSize: 14, // 字体大小
                  fontFamily: 'Arial' // 字体类型
                },
                interval: 0,
                formatter: function (value) {
                  if (value) {
                    return value
                  } else {
                    return value;
                  }
                }
              },
              data: null
            }],
            // 系列
            yAxis: [
              {
                type: 'value',
                splitLine: {show: true},
                position: 'left',
                axisLine: {
                  // show: true,
                  lineStyle: {
                    color: '#9E9E9E',
                  },
                },
                axisLabel: {
                  formatter: '{value}',
                },
              },
              {
                type: 'value',
                splitLine: {show: false},
                position: 'right',
                offset: 0,
                axisLine: {
                  // show: true,
                  lineStyle: {
                    color: '#9E9E9E',
                  },
                },
                axisLabel: {
                  formatter: '{value}%',
                },
              },
            ],
            series: [
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#97CE71',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#FFC553',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#E96D63',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#71C1DC',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#FE814B',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#71DC95',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
            ]
          };
          item.ycTimes.forEach((line, index) => {
            timeEchart.push([
              this.timeTable[3 - index],
              line.value3,
              line.value4,
              line.value5,
              line.value6,
              line.value7,
              line.value8,
            ]);
            item.valueTime += Number(line.value9)
          })
          item.chartDataTime.source = []
          item.chartDataTime.source = [
            ['product', ...this.formDateWeek.timeList],
            ...timeEchart,
          ]
        });
        // this.abnormalInfos = []
        // this.abnormalInfos.push(...this.formDateWeek.abnormalInfos)
        // console.log(this.formDateWeek.abnormalInfos)
        // this.$forceUpdate()
        // this.abnormalInfos.push(...this.formDateWeek.abnormalInfos)
        // console.log(this.abnormalInfos)
        // arrDate = Object.values(temp);
      })
    },

    handleLoading() {
      this.selectShow = false
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        // message('warning',"此功能暂未开放")
        if (this.selectShow === false) {
          this.width = ''
          this.height = ''
          if (document.getElementsByClassName('page-main')[0]) {
            this.width = document.getElementsByClassName('page-main')[0].clientWidth
            this.height = window.screen.height
          }
          const element = document.getElementById('section-box');
          console.log(element)
          html2pdf().from(element).set({
            pagebreak: {mode: 'avoid-all'},
            margin: [0, 0, 0, 0],
            html2canvas: {
              scale: 1, // 设置缩放比例
              // windowWidth: 200,
            },
            jsPDF: {
              format: [this.width / 3.78, 1080 / 2.428], // 设置 PDF 页面大小为 A4
              orientation: 'portrait' // 设置页面方向为纵向
            }
          }).save(this.teamName + '数据报告(日报)').then(() => {
            this.selectShow = true
            setTimeout(() => {
              loading.close();
            }, 2000);
          });
        }
      },2000)
    },
    handleLoadingWeek() {
      this.selectShow = false
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // message('warning',"此功能暂未开放")
      setTimeout(() => {
        if (this.selectShow === false) {
          const element = document.getElementById('section-box2');
          const formatWidth = 1000/ 3.78; // 根据实际情况调整
          const formatHeight = 1080 / 2.42; // 根据实际情况调整

          html2pdf().from(element).set({
            pagebreak: {mode: 'avoid-all'},
            margin: [0, 0, 0, 0],
            html2canvas: {
              scale: 0.88, // 设置缩放比例
            },
            jsPDF: {
              format: [formatWidth, formatHeight], // 设置 PDF 页面大小为 A4
              orientation: 'portrait' // 设置页面方向为纵向
            }
          }).save(this.teamName + '数据报告(周报)').then(() => {
            this.loading = false
            this.selectShow = true
            setTimeout(() => {
              loading.close();
            }, 2000);
          });
        }
      },3000)
    },
    handleLoadingMonth() {
      this.selectShow = false
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // message('warning',"此功能暂未开放")
      setTimeout(() => {
        if (this.selectShow === false) {
          const element = document.getElementById('section-box3');
          const formatWidth = 1000/ 3.78; // 根据实际情况调整
          const formatHeight = 1080 / 2.42; // 根据实际情况调整

          html2pdf().from(element).set({
            pagebreak: {mode: 'avoid-all'},
            margin: [0, 0, 0, 0],
            html2canvas: {
              scale: 0.86, // 设置缩放比例
            },
            jsPDF: {
              format: [formatWidth, formatHeight], // 设置 PDF 页面大小为 A4
              orientation: 'portrait' // 设置页面方向为纵向
            }
          }).save(this.teamName + '数据报告(月报)').then(() => {
            this.loading = false
            this.selectShow = true
            setTimeout(() => {
              loading.close();
            }, 2000);
          });
        }
      },2000)
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      }
    },
    // 日期转换
    dateFmt(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD')
    },
    dateFmt2(timestamp) {
      return moment(timestamp).format('YYYY年MM月DD日')
    },
    dateFmtYear(timestamp) {
      return moment(timestamp).format('YYYY')
    },
    // 点击日期
    onClickDate() {
      this.showDatePopup = true;
    },
    // 选中日期后
    handleDateConfirm(val) {
      // console.log(val)
      this.date = this.dateFmt(val) // 日期转换
      this.dateTime = this.dateFmt2(val)
      this.year = this.dateFmtYear(val)
      this.showDatePopup = false
      this.getList()
    },
    test(index) {
      const arr4 = JSON.parse(JSON.stringify(this.formDate.pqesInfos));
      arr4.forEach((v) => {
        v.rowspan = 1;
      });
      for (let i = 0; i < arr4.length; i++) {
        // 内层循环，上面已经给所有的行都加了v.rowspan = 1
        // 这里进行判断
        // 如果当前行的id和下一行的id相等
        // 就把当前v.rowspan + 1
        // 下一行的v.rowspan - 1
        for (let j = i + 1; j < arr4.length; j++) {
          // console.log(arr4[i].order)
          if (arr4[i].name === arr4[j].name) {
            arr4[i].rowspan++;
            arr4[j].rowspan--;
          }
          // return
        }
        // 这里跳过已经重复的数据
        i = (i + arr4[i].rowspan - 1);
      }
      return arr4[index].rowspan
    },
    test2(index) {
      const arr4 = JSON.parse(JSON.stringify(this.formDateWeek.pqesInfos));
      arr4.forEach((v) => {
        v.rowspan = 1;
      });
      for (let i = 0; i < arr4.length; i++) {
        // 内层循环，上面已经给所有的行都加了v.rowspan = 1
        // 这里进行判断
        // 如果当前行的id和下一行的id相等
        // 就把当前v.rowspan + 1
        // 下一行的v.rowspan - 1
        for (let j = i + 1; j < arr4.length; j++) {
          // console.log(arr4[i].order)
          if (arr4[i].name === arr4[j].name) {
            arr4[i].rowspan++;
            arr4[j].rowspan--;
          }
          // return
        }
        // 这里跳过已经重复的数据
        i = (i + arr4[i].rowspan - 1);
      }
      return arr4[index].rowspan
    },
    objectSpanMethod({rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        return {
          rowspan: this.test(rowIndex),
          colspan: 1
        };
      }
    },
    objectSpanMethodWeek({rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        return {
          rowspan: this.test2(rowIndex),
          colspan: 1
        };
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-btn{
  position: fixed;right: 40px;top: 80px
}
//#section-box {
//  width: 390px;
//}
.section {
  padding: 0 10px;
}
.page-container {
  padding: 0;
  overflow: auto;
}
.page-container .page-header {
  position: fixed;
  width: 89%;
  z-index: 9;
  background-color: #fff;
  align-items: baseline;
  top: 62px;
}
//
.page-container .page-main {
  //background-color: #fff;
  margin: 76px auto 0
}

.content {
  padding: 0 10px;
}

::v-deep .van-nav-bar__title {
  color: #ffffff;
  font-weight: bold;
}

::v-deep .van-nav-bar__text {
  color: #ffffff;
}

::v-deep .van-nav-bar__arrow {
  color: #ffffff;
}

::v-deep .van-nav-bar__content {
  background-color: #212c64;
}

::v-deep .van-dropdown-menu__title {
  font-weight: bold;
}

.search-box {
  position: relative;
  z-index: 9;
  margin: 15px 0px;
  background-color: #F4F4F5;
  box-shadow: none !important;
  //box-shadow: 0 4px 4px 1px rgba(184, 184, 184, 0.25);
  width: 31%;

  .search-item {
    color: #737373;
    font-weight: normal !important;
  }
}

.frontPage {
  width: 100%;
}

.year {
  position: absolute;
  font-size: 80px;
  color: #332E2F;
  top: 23%;
  left: 4%;
  font-weight: bold;
}

.title-name {
  position: absolute;
  color: #F3BC16;
  font-size: 70px;
  top: 24%;
  right: 10%;
  font-weight: bold;
}

.company-name {
  position: absolute;
  font-size: 55px;
  color: #000000;
  top: 30%;
  right: 10%;
  font-weight: bold;
}

.info-box {
  position: absolute;
  font-size: 25px;
  color: #707070;
  bottom: 8%;
  left: 5%;

  ul {
    margin-top: 8px;
  }

  ul li {
    line-height: 45px;
    list-style-type: disc;
    list-style-position: inside; /* 将标记放置在内容内部，可选 */
    list-style-color: #E7B021; /* 设置标记颜色为黑色，可选 */
  }

  ul li::marker {
    //font-size: 16px; /* 根据需要调整字体大小 */
    fill: #E7B021; /* 设置填充颜色为黑色 */
    color: #E7B021;
  }

  img {
    width: 35px;
    margin-right: 18px;
  }
}

.logo {
  width: 100px;
}

.label-icon {
  padding-top: 25px;
}

.label-icon-img {
  position: relative;
  width: 350px;
  right: -65.5%;
}

.title-box {
  display: flex;
  font-size: 26px;
  font-weight: bold;
  align-items: center;
  margin-top: 20px;
  padding-top: 10px;
  margin-bottom: 30px;

  img {
    width: 40px;
    margin-right: 5px;

  }
}

.title-box2 {
  display: flex;
  font-size: 26px;
  font-weight: bold;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 20px;

  img {
    width: 40px;
    margin-right: 5px;

  }
}

.title-box3 {
  display: flex;
  font-size: 26px;
  font-weight: bold;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 20px;

  img {
    width: 40px;
    margin-right: 5px;

  }
}

::v-deep .el-table__header-wrapper {
  border-bottom: 0rem solid #ffb500;
}

::v-deep .custom-table-header .el-table__header-wrapper {
  background-color: #f0f0f0; /* 你想要的表头颜色 */
}

::v-deep .el-table thead tr th {
  background-color: #FADD8B;
  color: #333333;
  //padding: 7px 0;
}

::v-deep .el-table--border th, ::v-deep .el-table--border td {
  border-bottom: 0.01rem solid #d1caca;
}

::v-deep .el-table--border th, ::v-deep .el-table--border td {
  border-right: 0.01rem solid #d1caca;
}

::v-deep .el-table td {
  //border-bottom: 0.02667rem solid #d1caca;
  //padding: 7px 0;
}

::v-deep .el-table th > .cell {
  //padding: 0 5px;
}

::v-deep .el-table {
  //margin-top: 10px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  font-size: 14px;
}

::v-deep .el-table .warning-row {
  background: #FFF3D2;
  // color:red;
}

.copywriter {
  border-radius: 6px;
  margin-top: 15px;
  padding: 15px 15px;
  background-color: #FFF3D2;
  color: #333333;
  font-size: 16px;
  margin-bottom: 20px;

  div:nth-child(2) {
    word-wrap: break-word; /* 允许在边界内折行长单词 */
    overflow-wrap: break-word;
    margin-top: 10px;
  }
}

.statistics {
  position: relative;

  div:nth-child(1) {
    color: #9E9E9E;
    font-size: 10px;
    position: absolute;
    left: 3%;
  }

  div:nth-child(2) {
    font-size: 12px;
    color: #000000;
    text-align: center;
  }
}

.statistics2 {
  position: relative;

  div {
    font-size: 12px;
    color: #000000;
    text-align: center;
  }
}

.echart-title {
  position: relative;
  top: -5%;
  //font-size: 10px;
  div:nth-child(1) {
    color: #9E9E9E;
    font-size: 18px;
    position: absolute;
    left: 3%;
    bottom: 0;
  }

  div:nth-child(2) {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }
}

.echart-title2 {
  position: relative;
  top: -5%;
  //font-size: 10px;
  div:nth-child(1) {
    color: #9E9E9E;
    font-size: 18px;
    position: absolute;
    left: 3%;
    bottom: 0;
  }

  div:nth-child(2) {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }

  .time-data2 {
    position: relative;
    right: -24%;
    width: 58%;
  }
}

.echarts-title {
  margin-top: 25px !important;

  div:nth-child(2) {
    font-size: 12px;
    color: #000000;
    font-weight: bold;
    text-align: right;
  }
}

.echarts-title2 {
  margin-top: 0 !important;

  div:nth-child(2) {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }
}

.echart-title-reason {
  margin-top: 10px !important;
  font-size: 15px;
  color: #9E9E9E;
  position: absolute;
  left: 3%;
  top: 47px;
  //margin-bottom: 10px !important;
}

.el-table-statistics {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.el-table-statisticsa {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-top: 0px;
}

::v-deep .el-table .cell {
  //line-height: 1.8;
}

.echatsImg {
  width: 100%;
  min-width: 250px;
  height: 200px;
}

.time-data {
  position: relative;
  right: -24%;
  width: 60%;
}

//.color-item {
//  display: inline-block;
//  width: 10px;
//  height: 10px;
//  margin-right: 5px;
//  vertical-align: middle;
//}
//
//.color-line {
//  font-size: 11px;
//  margin-right: 10px;
//  display: block;
//  padding: 1px 0;
//  width: 100px;
//  white-space: normal;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  vertical-align: middle;
//  span{
//    display: inline-block;
//  }
//  .color-item{
//
//  }
//}
//
//.color-box {
//  line-height: 0.5;
//  //text-align: center;
//  margin-top: 5px;
//  padding: 0 12px;
//}
.color-box {
  line-height: 0.5;
  margin-top: 20px;
  padding: 0 12px;
}

.color-line {
  font-size: 16px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 22px;
  width: 185px;
}

.color-item {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.color-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .reson-table .cell {
  //line-height: 1.5;
  //padding-left: 0 !important;
  //padding-right: 0;
}

::v-deep .reson-table th > .cell {
  //padding: 0 0px;
  //font-size: 10px;
}

::v-deep .reson-table thead tr th {
  //padding: 5px 0;
}

::v-deep .reson-table2 th > .cell {
  //padding: 0 0px;
  //font-size: 10px;
  text-align: center;
}

::v-deep .reson-table2 .el-table_4_column_17 > .cell {
  //padding: 0 2px;
}

::v-deep .reson-table2 .el-table_4_column_20 > .cell {
  //padding: 0 2px;
}

::v-deep .el-table-column {
  width: 30px !important;
}

.reason-name {
  font-size: 18px;
  margin: 0px 0 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.select-time {
  //display: flex;
  //justify-content: space-between;
  font-size: 16px;
  margin: 15px 0 10px 10px;

  div {
    //width: 49%;
    display: inline-block;
    text-align: center;
    padding: 10px 20px;
    background-color: #F4F4F5;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
  }

  div:hover {
    background-color: #FFA800;
    color: #fff;
  }

  div.active {
    background-color: #FFA800;
    color: #fff;
  }
}

::v-deep .search-box2 .el-input--suffix .el-input__inner {
  //border: 0;
}

::v-deep .search-box2 .el-input__suffix {
  //display: none;
}

::v-deep .search-box2 .el-select .el-tag {
  //margin: 0;
}

::v-deep .search-box2 .el-tag.el-tag--info {
  //margin-left: 5px;
  //margin-bottom: 7px;
  //padding: 10px 5px 28px;
  //display: inline-block;
  //border: 0;
}

::v-deep .el-select__tags {
  max-width: 100% !important;
}

.title-first {
  font-size: 28px;
  font-weight: bold;
  margin-top: 30px;
}

::v-deep .table-pad .el-table th > .cell {
  //padding: 0 2px;
  //font-size: 10px;
}

::v-deep .el-table thead.is-group th.el-table__cell {
  width: 25px !important;
}

::v-deep .table-pad .el-table .cell {
  //padding: 0px 0;
}

.service {
  font-size: 18px;
  color: #000;
  padding-top: 15px;
}

.echart-title-week {
  margin-top: 20px;
}

.title-three {
  font-size: 18px;
  font-weight: bold;
}

.title-three2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
}

.div-padding {
  padding: 30px 0 0;
}

.div-padding50 {
  padding: 50px 0 0;
}

.device-div .service {
  font-size: 18px;
}

.device-div {
  font-size: 18px;
}

.device-div .div-padding {
  padding: 20px 0 0;
  font-size: 18px;
}

.device-divNumber:first-child .div-padding {
  padding: 10px 0 0;
  font-size: 18px;
}

.device-div .echarts-div {
  margin-bottom: 15px;
}

.div-margin {
  padding: 30px 0 0;
  position: relative;
}

::v-deep .el-table thead.is-group th.el-table__cell {
  background-color: #FADD8B;
}

::v-deep .el-month-table td .cell:hover {
  color: #666666 !important;
}

::v-deep .month-time .el-input--suffix .el-input__inner {
  //background-color: #f4f4f5;
  margin: 0 10px 10px;
  padding: 0 15px;
}

::v-deep .month-time .el-input__prefix {
  display: none;
}

.point {
  text-align: right;
  font-size: 10px;
  margin-top: 10px;
  color: #acabab;
}

::v-deep .el-table-PQES .el-table td {
  //padding: 3px 0
}

@media (max-width: 390px) { /* 可视化范围较窄时 */
  .el-table-column {
    /* 针对小屏幕调整列宽，例如每列宽度减半 */
    width: calc(35px);
  }
}
</style>
