<template>
  <div>
    <div class="page-main" id="section-box3" style="margin: 0 auto;width: 1000px;"
         :style=" selectShow ? '' : 'padding-top: 0px;'">
      <div style="position: relative;font-size: 0">
        <img class="frontPage" src="@/assets/frontPage.png" alt="">
        <div class="year">{{ year }}</div>
        <div class="title-name">印刷管家数据报告</div>
        <div class="company-name">{{ teamName }}</div>
        <div class="info-box">
          <img src="@/assets/time-icon.png" alt="">
          <img src="@/assets/time-icon2.png" alt="">
          <div>
            <ul>
              <li>数据日期: <span>4个月</span></li>
              <li>{{ dateDay }}</li>
              <li>印工社（青岛）数字科技有限公司</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section">
        <div style="position: relative" class="label-icon">
          <!--          <img class="logo" src="@/assets/logoReport.png" alt="">-->
          <img class="label-icon-img" src="@/assets/label-icon.png" alt="">
        </div>
        <div class="title-first">一、印刷数量</div>
        <div class="title-box">
          <img src="@/assets/title-icon.png" alt="">
          <div>1.印厂总印刷数量</div>
        </div>
        <div class="echart-title" v-if="formDateWeek.yinLiangs.length > 0">
          <div>单位:张/月</div>
          <div class="time-data">实际印刷数量/产能利用率</div>
        </div>
        <div style="display: flex;" v-if="formDateWeek.yinLiangs.length > 0">
          <BarLineChart
              style="width: 100%"
              v-if="ysslWeek.source.length > 0"
              :id="'weekChart'"
              :data="ysslWeek"
              :legend-show="true"
              :grid-left="30"
              :gridLeft="5"
              :height="350"
              :percent="'%'"
              :gridRight="15"
              :left="'center'"
              :smooth="false"
              :gridTop="35"
          />
        </div>
        <div class="div-padding">
          <el-table :data="ysslDataZong" border :row-class-name="tableRowClassName" width="100%">
            <el-table-column prop="name" label="时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
              </template>
            </el-table-column>
            <!--            <el-table-column prop="value" label="月标准印量" width="80px" align="center">-->
            <!--              <template slot-scope="scope">-->
            <!--                {{ parseInt(scope.row.value) }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--      width="80px"       -->
            <el-table-column prop="value1" label="月实际印量" align="center">
              <template slot-scope="scope">
                {{ parseInt(scope.row.value1) }}
              </template>
            </el-table-column>
            <!--     width="100px"       -->
            <el-table-column prop="value2" label="月平均产能利用率"  align="center">
              <template slot-scope="scope">
                {{ scope.row.value2 / 10 }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="title-box">
          <img src="@/assets/title-icon.png" alt="">
          <div>2.单机台(4个月)印刷数量</div>
        </div>

        <el-select v-model="device" multiple placeholder="请选择设备"
                   v-show="selectShow"
                   @change="handleSelectDevice" style="width: 100%;margin-bottom: 25px"
                   :style="formDateWeek.yinLiangs.length < 2 ? 'margin-bottom: 0px' : ''">
          <el-option
              v-for="item in deviceName"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
        <div style="" v-if="formDateWeek.yinLiangs.length > 0">
          <div v-for="(item,key) in chartList" :key="key" class="device-div device-divNumber">
            <div v-if="item.show" class="service div-padding">{{ item.name }}</div>
            <div class="echart-title echart-title-week" v-if="item.show">
              <div>单位:张</div>
              <div class="time-data">实际印刷数量/产能利用率</div>
            </div>
            <BarLineChart
                style="width: 100%"
                class="echarts-div"
                v-if="item.chartData.source.length > 0 && item.chartData && item.show"
                :id="`chart22${key}`"
                :data="item.chartData"
                :legend-show="true"
                :grid-left="30"
                :height="350"
                :gridLeft="5"
                :percent="'%'"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
        </div>
        <div class="table-pad div-padding table-liyonglv">
          <el-table :data="ysslDataWeek" border :row-class-name="tableRowClassName" width="100%">
            <el-table-column prop="name" label="设备" width="130" align="center">
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
              <el-table-column prop="value" label="标准印量" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="月印量" width="65" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value2) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="产能 利用率" width="65" align="center">
                <template slot="header">
                  <span>产能<br>利用率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value3 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
              <el-table-column prop="value" label="标准印量" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value4) }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="月印量" width="65" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value5) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="产能 利用率" width="65" align="center">
                <template slot="header">
                  <span>产能<br>利用率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value6 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
              <el-table-column prop="value" label="标准印量"  align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value7) }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="月印量" width="65" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value8) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="产能 利用率" width="65" align="center">
                <template slot="header">
                  <span>产能<br>利用率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value9 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
              <el-table-column prop="value" label="标准印量" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value10) }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="月印量" width="65" align="center">
                <template slot-scope="scope">
                  {{ parseInt(scope.row.value11) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="产能 利用率" width="65" align="center">
                <template slot="header">
                  <span>产能<br>利用率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value12 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div style="white-space: pre-wrap;">{{ formDateWeek.ylDescribe }}</div>-->
        <!--          </div>-->
        <div class="div-padding">
          <div class="title-first" style="margin-top: 0">二、性能稼动率</div>
          <div class="title-box">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.印厂总性能稼动率</div>
          </div>
          <div>
            <div class="echart-title" v-if="formDateWeek.xingNengs.length > 0">
              <div>单位:印张/小时</div>
              <div class="time-data">实际运行速度 / 性能稼动率</div>
            </div>
            <div style="display: flex;" v-if="formDateWeek.xingNengs.length > 0">
              <BarLineChart
                  style="width: 100%"
                  v-if="xnjdlWeek.source.length > 0"
                  :id="'weekChart4'"
                  :data="xnjdlWeek"
                  :legend-show="false"
                  :grid-left="30"
                  :gridLeft="5"
                  :height="350"
                  :percent="'%'"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
            </div>
          </div>
        </div>
        <div class="div-padding">
          <el-table :data="xnjdlDataZong" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
              </template>
            </el-table-column>
            <!--            <el-table-column prop="bzsd" label="月额定速度" width="65px" align="center">-->
            <!--              <template slot-scope="scope">-->
            <!--                {{ scope.row.value }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--     width="85px"       -->
            <el-table-column prop="value1" label="月平均运行速度"  align="center">
              <template slot-scope="scope">
                {{ scope.row.value1 }}
              </template>
            </el-table-column>
            <!--      width="95px"       -->
            <el-table-column prop="value2" label="月平均性能稼动率" align="center">
              <template slot-scope="scope">
                {{ scope.row.value2 / 10 }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="div-padding">
          <div class="title-box" style="margin-top: 0;" :style="formDateWeek.xingNengs.length < 2 ? 'margin-bottom: 0px' : ''">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.单机台(4个月汇总)平均性能稼动率</div>
          </div>
          <div>
            <div class="echart-title" v-if="formDateWeek.xingNengs.length > 1">
              <div>单位:印张/小时</div>
              <div class="time-data">实际运行速度 / 性能稼动率</div>
            </div>
            <div v-if="formDateWeek.xingNengs.length > 1">
              <div>
                <BarLineChart
                    style="width: 100%"
                    v-if="chartDataZong.source.length > 0"
                    :id="`chart41`"
                    :data="chartDataZong"
                    :legend-show="false"
                    :grid-left="30"
                    :gridLeft="5"
                    :height="350"
                    :percent="'%'"
                    :gridRight="15"
                    :left="'center'"
                    :smooth="false"
                    :gridTop="35"
                />
                <div class="color-box" v-if="formDateWeek.xingNengs.length > 0">
                  <div v-for="(item,index) in nameWeek" :key="index" style="display: inline-block" class="color-line">
                    <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span>{{ item }}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="div-padding">
          <el-table :data="xnjdlDataWeek" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="设备" align="center">
            </el-table-column>
            <el-table-column prop="value" label="额定速度" align="center">
              <template slot-scope="scope">
                {{ scope.row.value }}
              </template>
            </el-table-column>
            <el-table-column prop="value9" label="4个月平均运行速度" align="center">
              <template slot="header">
                <span>4个月平均<br>运行速度</span>
              </template>
              <template slot-scope="scope">
                {{ scope.row.value9 }}
              </template>
            </el-table-column>
            <el-table-column prop="value10" label="4个月平均性能稼动率" align="center">
              <template slot="header">
                <span>4个月平均<br>性能稼动率</span>
              </template>
              <template slot-scope="scope">
                {{ scope.row.value10 / 10 }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="div-padding">
          <div class="title-box" style="margin-top: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>3.单机台(4个月)平均性能稼动率</div>
          </div>
          <el-select v-model="devicexn" multiple placeholder="请选择设备"
                     v-show="selectShow"
                     style="width: 100%;margin-bottom: 40px"
                     :style="formDateWeek.xingNengs.length < 2 ? 'margin-bottom: 0px' : ''"
                     @change="handleSelectxn">
            <el-option
                v-for="item in deviceName"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <div v-if="chartXnjdlList.length > 0" class="device-div">
            <div v-if="chartXnjdlList[0].show" class="service" style="padding-top: 0">{{ chartXnjdlList[0].name }}</div>
            <div class="echart-title echart-title-week" v-if="chartXnjdlList[0].show">
              <div>单位:印张/小时</div>
              <div class="time-data">实际运行速度 / 性能稼动率</div>
            </div>
            <BarLineChart
                style="width: 100%"
                class="echarts-div"
                v-if="chartXnjdlList[0].chartData.source.length > 0 && chartXnjdlList[0].chartData && chartXnjdlList[0].show"
                :id="`chart361`"
                :data="chartXnjdlList[0].chartData"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="5"
                :percent="'%'"
                :height="350"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
        </div>
        <div>
          <div v-if="formDateWeek.xingNengs.length > 1">
            <div v-for="(item,key) in chartXnjdlList.slice(1)" :key="key" class="device-div">
              <div v-if="item.show" class="service div-padding">{{ item.name }}</div>
              <div class="echart-title echart-title-week" v-if="item.show">
                <div>单位:印张/小时</div>
                <div class="time-data">实际运行速度 / 性能稼动率</div>
              </div>
              <BarLineChart
                  style="width: 100%"
                  class="echarts-div"
                  v-if="item.chartData.source.length > 0 && item.chartData && item.show"
                  :id="`chartSm${key}`"
                  :data="item.chartData"
                  :legend-show="false"
                  :grid-left="30"
                  :gridLeft="5"
                  :height="350"
                  :percent="'%'"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
            </div>
          </div>
        </div>

        <div class="table-pad div-padding">
          <el-table :data="xnjdlDataWeek" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="设备" align="center">
            </el-table-column>
            <el-table-column prop="value" label="额定速度"  align="center">
              <template slot-scope="scope">
                {{ scope.row.value }}
              </template>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
              <el-table-column prop="value1" label="运行速度" align="center">

                <template slot-scope="scope">
                  {{ scope.row.value1 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="性能稼动率" align="center">
                <template slot="header">
                  <span>性能<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value2 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
              <el-table-column prop="value1" label="运行速度" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value3 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="性能稼动率" align="center">
                <template slot="header">
                  <span>性能<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value4 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
              <el-table-column prop="value1" label="运行速度" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value5 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="性能稼动率" align="center">
                <template slot="header">
                  <span>性能<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value6 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
              <el-table-column prop="value1" label="运行速度" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value7 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="性能稼动率" align="center">
                <template slot="header">
                  <span>性能<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value8 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div style="white-space: pre-wrap;">{{ formDateWeek.sdDescribe }}</div>-->
        <!--          </div>-->

        <div class="div-padding" style="padding-top: 30px">
          <div class="title-first" style="margin-top: 0;">三、有效时间稼动率</div>
          <div class="title-box">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.印厂总有效时间稼动率</div>
          </div>
          <div>
            <div class="echart-title" v-if="formDateWeek.shiJians.length > 0">
              <div>单位:小时/月</div>
              <div class="time-data">有效印刷时长 / 有效时间稼动率</div>
            </div>
            <div style="display: flex;" v-if="formDateWeek.shiJians.length > 0">
              <BarLineChart
                  style="width: 100%"
                  v-if="sjjdlWeek.source.length > 0"
                  :id="'weekChart6'"
                  :data="sjjdlWeek"
                  :legend-show="false"
                  :grid-left="30"
                  :height="350"
                  :gridLeft="5"
                  :percent="'%'"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
            </div>
          </div>
        </div>
        <div class="div-padding">
          <el-table :data="timeDataWeek" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
              </template>
            </el-table-column>
            <!--            <el-table-column prop="panelName" label="月额定运行时长(小时)" width="85px" align="center">-->
            <!--              <template slot="header">-->
            <!--                <span>月额定运行<br>时长(小时)</span>-->
            <!--              </template>-->
            <!--              <template slot-scope="scope">-->
            <!--                {{ scope.row.value }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--      width="85px"      -->
            <el-table-column prop="value1" label="月有效印刷时长(小时)"  align="center">
              <template slot="header">
                <span>月有效印刷<br>时长(小时)</span>
              </template>
              <template slot-scope="scope">
                {{ scope.row.value1 }}
              </template>
            </el-table-column>
            <!--     width="70px"       -->
            <el-table-column prop="value2" label="月平均有效时间稼动率"  align="center">
              <template slot="header">
                <span>月平均有效<br>时间稼动率</span>
              </template>
              <template slot-scope="scope">
                {{ scope.row.value2 / 10 }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="div-padding">
          <div class="title-box" style="margin-top: 0" :style="formDateWeek.shiJians.length < 2 ? 'margin-bottom: 0px' : ''">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.单机台（4个月汇总）平均有效时间稼动率</div>
          </div>
          <div>
            <div class="echart-title" v-if="formDateWeek.shiJians.length > 1">
              <div>单位:小时/月</div>
              <div class="time-data">有效印刷时长 / 有效时间稼动率</div>
            </div>
            <div v-if="formDateWeek.shiJians.length > 1">
              <BarLineChart
                  style="width: 100%"
                  v-if="chartDataTimeZong.source.length > 0"
                  :id="'weekChart61'"
                  :data="chartDataTimeZong"
                  :legend-show="false"
                  :grid-left="30"
                  :gridLeft="5"
                  :height="350"
                  :percent="'%'"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
              <div class="color-box" v-if="formDateWeek.xingNengs.length > 0">
                <div v-for="(item,index) in nameWeek" :key="index" style="display: inline-block" class="color-line">
                  <span :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span>{{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="div-padding">
          <el-table :data="timeDatazong" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="设备" align="center">
            </el-table-column>
            <!--            <el-table-column prop="panelName" label="4个月额定运行 时长(小时)" width="80px" align="center">-->
            <!--              <template slot="header">-->
            <!--                <span>4个月额定运行<br>时长(小时)</span>-->
            <!--              </template>-->
            <!--              <template slot-scope="scope">-->
            <!--                {{ scope.row.value13 }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--     width="80px"       -->
            <el-table-column prop="value1" label="4个月有效印刷 时长(小时)"  align="center">
              <template slot="header">
                <span>4个月有效印刷<br>时长(小时)</span>
              </template>
              <template slot-scope="scope">
                {{ scope.row.value14 }}
              </template>
            </el-table-column>
            <!--      width="75px"      -->
            <el-table-column prop="value2" label="4个月平均有效时间稼动率"  align="center">
              <template slot="header">
                <span>4个月平均有效<br>时间稼动率</span>
              </template>
              <template slot-scope="scope">
                {{ scope.row.value15 / 10 }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="div-padding">
          <div class="title-box" v-show="selectShow" style="margin-top: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>3.单机台（4个月）平均有效时间稼动率</div>
          </div>
          <el-select v-model="devicesj" multiple placeholder="请选择设备"
                     v-show="selectShow"
                     style="width: 100%;margin-bottom: 40px"
                     :style="formDateWeek.shiJians.length < 2 ? 'margin-bottom: 0px' : ''"
                     @change="handleSelectsj">
            <el-option
                v-for="item in deviceName"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <div v-if="formDateWeek.shiJians.length > 0">
            <div v-for="(item,key) in chartTimeList" :key="key" class="device-div">
              <div class="title-box" v-if="key===0 && !selectShow" style="margin-top: 0;margin-bottom: 0">
                <img src="@/assets/title-icon.png" alt="">
                <div>3.单机台（4个月）平均有效时间稼动率</div>
              </div>
              <div v-if="item.show !== false" class="service div-padding">{{ item.name }}</div>
              <div class="echart-title echart-title-week" v-if="item.show">
                <div>单位:小时/月</div>
                <div class="time-data">有效印刷时长 / 有效时间稼动率</div>
              </div>
              <BarLineChart
                  style="width: 100%"
                  v-if="item.chartData.source.length > 0 && item.chartData && item.show"
                  :id="`chart43${key}`"
                  :data="item.chartData"
                  :legend-show="false"
                  :grid-left="30"
                  :gridLeft="5"
                  :height="350"
                  :percent="'%'"
                  :gridRight="15"
                  :left="'center'"
                  :smooth="false"
                  :gridTop="35"
              />
            </div>
          </div>
        </div>
        <div class="div-padding table-pad">
          <el-table :data="timeDatazong" border :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="设备" align="center">
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
              <!--              <el-table-column prop="panelName" label="额定运行时长" width="50px" align="center">-->
              <!--                <template slot-scope="scope">-->
              <!--                  {{ scope.row.value1 }}-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <el-table-column prop="value1" label="有效印刷时长" align="center">
                <template slot="header">
                  <span>有效印刷<br>时长</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value2 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="时 间稼动率" align="center">
                <template slot="header">
                  <span>有效时间<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value3 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
              <!--              <el-table-column prop="panelName" label="额定运行时长" width="50px" align="center">-->
              <!--                <template slot-scope="scope">-->
              <!--                  {{ scope.row.value4 }}-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <el-table-column prop="value1" label="有效印刷时长" align="center">
                <template slot="header">
                  <span>有效印刷<br>时长</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value5 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="时 间稼动率" align="center">
                <template slot="header">
                  <span>有效时间<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value6 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
              <!--              <el-table-column prop="panelName" label="额定运行时长" width="50px" align="center">-->
              <!--                <template slot="header">-->
              <!--                  <span>额定运行<br>时长</span>-->
              <!--                </template>-->
              <!--                <template slot-scope="scope">-->
              <!--                  {{ scope.row.value7 }}-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <el-table-column prop="value1" label="有效印刷时长" align="center">
                <template slot="header">
                  <span>有效印刷<br>时长</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value8 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="时 间稼动率" align="center">
                <template slot="header">
                  <span>有效时间<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value9 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
              <!--              <el-table-column prop="panelName" label="额定运行时长" width="50px" align="center">-->
              <!--                <template slot="header">-->
              <!--                  <span>额定运行<br>时长</span>-->
              <!--                </template>-->
              <!--                <template slot-scope="scope">-->
              <!--                  {{ scope.row.value10 }}-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <el-table-column prop="value1" label="有效印刷时长" align="center">
                <template slot="header">
                  <span>有效印刷<br>时长</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value11 }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="时 间稼动率" align="center">
                <template slot="header">
                  <span>有效时间<br>稼动率</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value12 / 10 }}%
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div style="white-space: pre-wrap;">{{formDateWeek.timeDescribe}}</div>-->
        <!--          </div>-->

        <div class="div-padding">
          <div class="title-first" style="margin-top: 10px;">四、异常待机(数据)</div>
          <div class="title-box" style="margin-bottom: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.印厂待机情况汇总</div>
          </div>
          <div class="echart-title div-padding" v-if="formDateWeek.yichangs1.length > 0">
            <div>单位:小时</div>
            <div class="time-data time-data2">待机时长/ 待机原因已提交 / 待机原因未提交</div>
          </div>
          <div v-if="formDateWeek.yichangs1.length > 0">
            <BarLineChart
                style="width: 100%"
                v-if="ycdjTotalWeek.source.length > 0"
                :id="'weekChart8'"
                :data="ycdjTotalWeek"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="5"
                :height="350"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
        </div>
        <div class="div-padding">
          <div class="echart-title div-padding" v-if="formDateWeek.yichangs1.length > 0">
            <div>单位:次</div>
            <div class="time-data time-data2">待机次数 / 待机原因已提交 / 待机原因未提交</div>
          </div>
          <div v-if="formDateWeek.yichangs1.length > 0">
            <BarLineChart
                style="width: 100%"
                v-if="ycdjWeek.source.length > 0"
                :id="'weekChart82'"
                :data="ycdjWeek"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="5"
                :height="350"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
          </div>
        </div>

        <div class="div-padding" :style="selectShow ? 'padding-top: 40px' : 'padding-top: 20px'">
          <el-table :data="yichangs1" border :row-class-name="tableRowClassName" width="100%"
                    class="el-table-statisticsa reson-table reson-table2" style="margin-bottom: 0">
            <el-table-column prop="name" label="时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column prop="panelName" style="padding: 2px" label="异常待机状况" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? (Math.round((scope.row.value3 / 60) * 10) / 10 + Math.round((scope.row.value5 / 60) * 10) / 10).toFixed(1) : scope.row.value1.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="次数(次)" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value2 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" label="待机原因已提交" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? Math.round((scope.row.value3 / 60) * 10) / 10 : scope.row.value3.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="次数(次)" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value4 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" label="待机原因未提交" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? Math.round((scope.row.value5 / 60) * 10) / 10 : scope.row.value5.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="次数(次)" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value6 }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <div class="div-padding">
          <div class="title-box" style="margin-top: 0" :style="formDateWeek.yichangs.length < 2 ? 'margin-bottom: 0px' : ''">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.单机台（4个月汇总）异常待机数据</div>
          </div>
          <div class="echart-title" v-if="formDateWeek.yichangs.length > 1">
            <div>单位:小时</div>
            <div class="time-data time-data2">待机时长/ 待机原因已提交 / 待机原因未提交</div>
          </div>
          <div v-if="formDateWeek.yichangs.length > 1">
            <BarLineChart
                style="width: 100%"
                v-if="chartDataYichangZong.source.length > 0"
                :id="'weekChart81'"
                :data="chartDataYichangZong"
                :legend-show="false"
                :grid-left="30"
                :height="350"
                :gridLeft="5"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
            <div class="color-box" v-if="formDateWeek.xingNengs.length > 0">
              <div v-for="(item,index) in nameWeek" :key="index" style="display: inline-block" class="color-line">
                <span :style="{ backgroundColor: colorDevice[index] }" class="color-item"></span><span
                  :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span><span
                  :style="{ backgroundColor: DeepColorArrDevice[index] }" class="color-item"></span>{{ item }}
              </div>
            </div>
          </div>
        </div>
        <div :style="{
  'padding-top': selectShow ? '40px' : '30px',
  'margin-bottom': formDateWeek.shiJians.length < 2 ? '0px' : '',
  'padding-top': formDateWeek.shiJians.length < 2 ? '0px' : ''
}">
          <div class="echart-title " style="margin-top: 25px" v-if="formDateWeek.yichangs.length > 1">
            <div>单位:次</div>
            <div class="time-data time-data2">待机次数 / 待机原因已提交 / 待机原因未提交</div>
          </div>
          <div v-if="formDateWeek.yichangs.length > 1">
            <BarLineChart
                style="width: 100%"
                v-if="chartDataYichangZongCount.source.length > 0"
                :id="'weekChart83'"
                :data="chartDataYichangZongCount"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="5"
                :height="350"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
                :gridTop="35"
            />
            <div class="color-box" v-if="formDateWeek.xingNengs.length > 0">
              <div v-for="(item,index) in nameWeek" :key="index" style="display: inline-block" class="color-line">
                <span :style="{ backgroundColor: colorDevice[index] }" class="color-item"></span><span
                  :style="{ backgroundColor: colorArrPQES[index] }" class="color-item"></span><span
                  :style="{ backgroundColor: DeepColorArrDevice[index] }" class="color-item"></span>{{ item }}
              </div>
            </div>
          </div>
        </div>

        <div class="div-padding" :style="selectShow ? 'padding-top: 40px' : 'padding-top: 30px'">
          <el-table :data="yichangDatazong" border :row-class-name="tableRowClassName" width="100%"
                    class="el-table-statistics reson-table reson-table2" style="margin-bottom: 0">
            <el-table-column prop="name" label="设备" width="200" align="center">
            </el-table-column>
            <el-table-column prop="panelName" style="padding: 2px" label="异常待机状况" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? Math.round((scope.row.value9 / 60) * 10) / 10 : scope.row.value9.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="次数(次)" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value10 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" label="待机原因已提交" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? Math.round((scope.row.value11 / 60) * 10) / 10 : scope.row.value11.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="次数(次)" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value12 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" label="待机原因未提交" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? Math.round((scope.row.value13 / 60) * 10) / 10 : scope.row.value13.toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="次数(次)" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value14 }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <div class="div-padding">
          <div class="title-box" style="margin-top: 0 " v-show="selectShow">
            <img src="@/assets/title-icon.png" alt="">
            <div>3.单机台（4个月）每月待机数据</div>
          </div>
          <el-select v-model="devicedjsj" multiple placeholder="请选择设备"
                     v-show="selectShow"
                     style="width: 100%;margin-bottom: 25px"
                     :style="formDateWeek.yichangs.length < 2 ? 'margin-bottom: 0px' : ''"
                     @change="handleSelectdjsj">
            <el-option
                v-for="item in deviceName"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <div v-if="formDateWeek.yichangs.length > 0">
            <div v-for="(item,indexData) in chartYichangList" :key="indexData"
                 class="device-div">
              <div class="title-box" v-if="indexData===0 && !selectShow" style="margin-top: 0;margin-bottom: 0">
                <img src="@/assets/title-icon.png" alt="">
                <div>3.单机台（4个月）每月待机数据</div>
              </div>
              <div v-if="item.show" :style="selectShow ? 'padding-top: 30px' : 'padding-top: 40px'"
                   class="service div-padding" style="padding-bottom: 10px">{{ item.name }}
              </div>
              <div class="echart-title echart-title-week" v-if="item.show">
                <div>单位:小时 <br>单位:次</div>
                <div class="time-data time-data2">待机时长/ 待机次数</div>
              </div>
              <div v-if="item.show !== false">
                <BarLineChart
                    style="width: 100%"
                    class="echarts-div"
                    v-if="item.chartData.source.length > 0 && item.show"
                    :id="`chart72${indexData}`"
                    :data="item.chartData"
                    :legend-show="false"
                    :grid-left="30"
                    :gridLeft="15"
                    :height="350"
                    :gridRight="0"
                    :left="'center'"
                    :smooth="false"
                    :gridTop="35"
                />
              </div>

            </div>

          </div>
        </div>

        <div :style="selectShow ? 'padding-top: 40px' : 'padding-top: 30px'">
          <el-table :data="yichangDatazong" border :row-class-name="tableRowClassName" width="100%"
                    class="el-table-statistics reson-table reson-table2" style="margin-bottom: 0">
            <el-table-column prop="name" label="设备" width="200" align="center">
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ (scope.row.value1 / 60).toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="次数(次)"  align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value2 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ (scope.row.value3 / 60).toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="次数(次)" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value4 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ (scope.row.value5 / 60).toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="次数(次)" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value6 }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
              <el-table-column prop="value1" label="时长(小时)" align="center">
                <template slot="header">
                  <span>时长<br>(小时)</span>
                </template>
                <template slot-scope="scope">
                  {{ (scope.row.value7 / 60).toFixed(1) }}
                </template>
              </el-table-column>
              <el-table-column prop="value2" label="次数(次)" align="center">
                <template slot="header">
                  <span>次数<br>(次)</span>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.value8 }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div style="white-space: pre-wrap;">{{formDateWeek.abnormalDescribe}}</div>-->
        <!--          </div>-->


        <div :style="selectShow ? 'padding-top: 40px' : 'padding-top: 30px'">
          <div class="title-first" style="margin-top: 0;">五、异常待机(原因)</div>
          <div class="title-box">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.待机原因汇总</div>
          </div>
          <div class="title-three">(1)4个月待机次数主要原因统计</div>
          <div class="div-padding">
            <el-table :data="reasonCount" border :row-class-name="tableRowClassName" width="100%"
                      class="el-table-statistics reson-table reson-table2 reson-table3"
                      style="margin-bottom: 0;margin-top: 0">
              <el-table-column prop="name" label="时间" align="center">
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="总次数" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value1 }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="未填写" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value2 }}
                </template>
              </el-table-column>
              <el-table-column v-for="(item, index) in formDateWeek.countList" :key="index"
                               :label="`第${index + 1}多`">
                <el-table-column :prop="`value${index + 3}`" :label="item" align="center">

                </el-table-column>
              </el-table-column>
              <el-table-column prop="value9" label="前6占比" align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.value1 === 0 ? 0 : parseInt((scope.row.value3 + scope.row.value4 + scope.row.value5 + scope.row.value6 + scope.row.value7 + scope.row.value8) * 100 / scope.row.value1)
                  }}%
                </template>
              </el-table-column>
            </el-table>

          </div>
        </div>

        <div class="copywriter" style="margin-bottom: 0">
          <div>材料包括:</div>
          <div>印版、纸张、油墨、胶辊、橡皮布、润版液、喷粉、光油</div>
        </div>
        <div :style="selectShow ? 'padding-top: 40px' : 'padding-top: 30px'">
          <div class="title-three">(2)4个月待机时间主要原因统计</div>
          <div class="div-padding">
            <el-table :data="reasonTime" border :row-class-name="tableRowClassName" width="100%"
                      class="el-table-statistics reson-table reson-table2 reson-table3" style="margin-bottom: 0;">
              <el-table-column prop="name" label="时间" align="center">
                <template slot-scope="scope">
                  {{ scope.row.name !== '合计' ? scope.row.name.match(/\((.*?)\)/)[0] : scope.row.name }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="总时长" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value1 }}
                </template>
              </el-table-column>
              <el-table-column prop="value1" label="未填写" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value2 }}
                </template>
              </el-table-column>
              <el-table-column v-for="(item, index) in formDateWeek.timeList" :key="index"
                               :label="`第${index + 1}多`">
                <el-table-column :prop="`value${index + 3}`" :label="item" align="center">

                </el-table-column>
              </el-table-column>
              <el-table-column prop="value9" label="前6占比" align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.value1 === 0 ? 0 : parseInt((scope.row.value3 + scope.row.value4 + scope.row.value5 + scope.row.value6 + scope.row.value7 + scope.row.value8) * 100 / scope.row.value1)
                  }}%
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="copywriter">
          <div>材料包括:</div>
          <div>印版、纸张、油墨、胶辊、橡皮布、润版液、喷粉、光油</div>
        </div>
        <div :style="selectShow ? 'padding-top: 0px' : 'padding-top: 10px'">
          <div class="title-box" v-show="selectShow" style="margin-top: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.单印刷机待机原因</div>
          </div>
          <el-select v-model="devicedjyy" placeholder="请选择设备" v-show="selectShow"
                     style="width: 100%;margin-bottom: 25px" multiple
                     @change="handleSelectdjyy">
            <el-option
                v-for="item in deviceName"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <!--<div v-if="formDateWeek.abnormalInfos.length > 0" class="device-div">
            <div v-if="formDateWeek.abnormalInfos[0].show" class="">{{ formDateWeek.abnormalInfos[0].name }}</div>
            <div style="display: flex;justify-content: space-between">
              <div v-if="formDateWeek.abnormalInfos[0].show" class="title-three2">待机时间原因-次数</div>
              <div v-if="formDateWeek.abnormalInfos[0].show" class="title-three2">该6项原因占总次数的{{
                  formDateWeek.abnormalInfos[0].valueCount
                }}%
              </div>
            </div>
            <BarLineChart2
                class="echarts-div"
                v-if="formDateWeek.abnormalInfos[0].chartData.source.length > 0 && formDateWeek.abnormalInfos[0].show "
                :id="`chartsMonthTime`"
                :data="formDateWeek.abnormalInfos[0].chartData"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="15"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
            />
            <div style="display: flex;justify-content: space-between">
              <div class="title-three2">待机时间原因-时间</div>
              <div class="title-three2">该6项原因占总时间{{
                  formDateWeek.abnormalInfos[0].valueTime
                }}%
              </div>
            </div>
            <BarLineChart2
                class="echarts-div"
                v-if="formDateWeek.abnormalInfos[0].chartDataTime.source.length > 0 && formDateWeek.abnormalInfos[0].show"
                :id="`chart132` + 'month'"
                :data="formDateWeek.abnormalInfos[0].chartDataTime"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="15"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
            />
          </div>-->
        </div>
        <div>
          <div v-for="(item, index) in formDateWeek.abnormalInfos" :key="index" class="device-div">
            <div class="title-box" v-if="index===0 && !selectShow" style="margin-top: 0;margin-bottom: 0">
              <img src="@/assets/title-icon.png" alt="">
              <div>2.单印刷机待机原因</div>
            </div>
            <div v-if="item.show" class="device-div-name ">{{ item.name }}</div>
            <div v-if="item.show" style="display: flex;justify-content: space-between">
              <div class="title-three2">待机时间原因-次数</div>
              <div class="title-three2">该6项原因占总次数的{{
                  item.countRate
                }}%
              </div>
            </div>
            <BarLineChart2
                v-if="item.chartData.source.length > 0 && item.show "
                :id="`chart131${index}`"
                :data="item.chartData"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="15"
                :height="350"
                :gridRight="15"
                :left="'center'"
                :smooth="false"
            />
            <div v-if="item.show" style="display: flex;justify-content: space-between" class="div-padding">
              <div class="title-three2">待机时间原因-时间</div>
              <div class="title-three2">该6项原因占总时间{{
                  item.timeRate
                }}%
              </div>
            </div>
            <BarLineChart2
                v-if="item.chartDataTime.source.length > 0 && item.show"
                :id="`chart132` + index"
                :data="item.chartDataTime"
                :legend-show="false"
                :grid-left="30"
                :gridLeft="15"
                :gridRight="15"
                :height="350"
                :left="'center'"
                :smooth="false"
            />
          </div>
        </div>


        <!--          <div v-for="(item,key) in formDateWeek.abnormalInfos" :key="key">-->
        <!--            <div class="title-three">{{ item.name }}</div>-->
        <!--           -->
        <!--          </div>-->

        <div :style="selectShow ? '' : 'padding-top: 0px'">
          <div style="position: relative" class="label-icon">
            <!--          <img class="logo" src="@/assets/logoReport.png" alt="">-->
            <img class="label-icon-img" src="@/assets/label-icon2.png" alt="">
          </div>
          <div class="title-box" style="margin-top:15px">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.印刷设备维保</div>
          </div>
          <div class="title-box" style="font-weight: normal;margin-top: 0;margin-bottom: 15px">半月保</div>
          <div class="table-pad table-liyonglv">
            <el-table :data="formDateWeek.ysInfoHalf" border :row-class-name="tableRowClassName" width="100%"
                      class="el-table-statistics" style="margin-bottom: 0">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
                <el-table-column prop="value1" label="保养半月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 半月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value2 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="半月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>半月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value3 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
                <el-table-column prop="value1" label="保养 半月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value4 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 半月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value5 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="半月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>半月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value6 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
                <el-table-column prop="value1" label="保养 半月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value7 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 半月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value8 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="半月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>半月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value9 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
                <el-table-column prop="value1" label="保养 半月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value10 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 半月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value11 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="半月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>半月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value12 }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="">
          <div class="title-box" style="font-weight: normal">月保</div>
          <div class="table-pad">
            <el-table :data="formDateWeek.ysInfo" border :row-class-name="tableRowClassName" width="100%"
                      class="el-table-statistics" style="margin-bottom: 0">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
                <el-table-column prop="value1" label="保养 月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value2 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value3 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
                <el-table-column prop="value1" label="保养 月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value4 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value5 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value6 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
                <el-table-column prop="value1" label="保养 月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value2 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value3 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
                <el-table-column prop="value1" label="保养 月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value4 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value5 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value6 }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="div-padding">
          <div class="title-box" style="margin-top: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>2.印前设备维保</div>
          </div>
          <div class="title-box" style="font-weight: normal">半月保</div>
          <div class="table-pad table-liyonglv">
            <el-table :data="formDateWeek.yqInfoHalf" border :row-class-name="tableRowClassName" width="100%">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
                <el-table-column prop="value1" label="保养 半月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 半月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value2 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="半月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>半月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value3 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
                <el-table-column prop="value1" label="保养 半月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value4 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 半月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value5 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="半月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>半月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value6 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
                <el-table-column prop="value1" label="保养 半月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value7 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 半月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value8 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="半月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>半月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value9 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
                <el-table-column prop="value1" label="保养 半月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value10 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 半月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>半月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value11 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="半月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>半月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value12 }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="div-padding">
          <div class=" title-box" style="font-weight: normal;margin-top: 0;padding-top: 0">月保</div>
          <div class="table-pad">
            <el-table :data="formDateWeek.yqInfo" border :row-class-name="tableRowClassName" width="100%">
              <el-table-column prop="name" label="设备" align="center">
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
                <el-table-column prop="value1" label="保养 月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value2 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value3 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
                <el-table-column prop="value1" label="保养 月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value4 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value5 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value6 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
                <el-table-column prop="value1" label="保养 月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value7 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value8 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value9 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
                <el-table-column prop="value1" label="保养 月保" width="70" align="center">
                  <template slot="header">
                    <span>保养<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value10 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="点检 月保" width="70" align="center">
                  <template slot="header">
                    <span>点检<br>月保</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value11 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="月保养完成率" width="70" align="center">
                  <template slot="header">
                    <span>月保养<br>完成率</span>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.value12 }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!--          <div class="copywriter">-->
        <!--            <div>数据描述:</div>-->
        <!--            <div style="white-space: pre-wrap;">{{formDateWeek.maintainDescribe}}</div>-->
        <!--          </div>-->
        <div>


          <div style="position: relative" class="label-icon">
            <!--          <img class="logo" src="@/assets/logoReport.png" alt="">-->
            <img class="label-icon-img" src="@/assets/label-icon3.png" alt="" style="margin-top: 0">
          </div>
          <div class="title-box" :style="selectShow ? '' : 'padding-top: 0px;margin-top: 0'"
               style="margin-bottom: 0;margin-top: 0">
            <img src="@/assets/title-icon.png" alt="">
            <div>1.PQES报告上传</div>
          </div>
          <div class="table-pad el-table-PQES" :style="selectShow ? 'padding-top:20px' : 'padding-top: 20px'">
            <el-table :data="formDateWeek.pqesInfos" border :row-class-name="tableRowClassName"
                      :span-method="objectSpanMethodWeek" class="el-table-statistics" style="margin-bottom: 0;margin-top: 0">
              <el-table-column prop="name" label="设备" width="200" align="center">
              </el-table-column>
              <el-table-column prop="panelName" label="班组" align="center">
                <template slot-scope="scope">
                  {{ scope.row.groupName }}
                </template>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[3]})`" align="center">
                <el-table-column prop="value1" label="应该上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="实际上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value2 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[2]})`" align="center">
                <el-table-column prop="value1" label="应该上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value3 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="实际上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value4 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[1]})`" align="center">
                <el-table-column prop="value1" label="应该上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value5 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="实际上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value6 }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="panelName" :label="`(${timeTable[0]})`" align="center">
                <el-table-column prop="value1" label="应该上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value7 }}
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="实际上传次数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.value8 }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div style="background: #fff;padding-top: 30px">
        <img class="frontPage" src="@/assets/frontPage2.jpg" alt="">
      </div>

    </div>
  </div>
</template>

<script>
// import BarLineChartTotal from "@/components/Charts/BarLineChartTotal";
import BarLineChart from "@/components/Charts/BarLineChart";
import BarLineChart2 from "@/components/Charts/BarLineChart2";
// import {getInfo} from "@/api/printingLogin";
// import {teamDayMonthReport} from "@/api/report";
import API from "@/api";
import moment from "moment";

export default {
  props: {
    month: {
      type: [String, Array],
      default: '',
    },
    months: {
      type: [String, Array],
      default: '',
    },
    timeTables: {
      type: Array,
      // 同理，如果需要的话，可以设置默认值
      default: () => []
    },
    selectShow: {
      type: Boolean,
      default: true
    }
  },
  name: "dataReportMonth.vue",
  components: {BarLineChart, BarLineChart2},
  data() {
    return {
      timeMonths: '',
      timeTable: [],
      selectTime: 3,
      year: '',
      dateDay: '',
      teamName: '',
      formDateWeek: {
        abnormalDescribe: '',
        sdDescribe: '',
        timeDescribe: '',
        ylDescribe: '',
        maintainDescribe: '',
        ysInfo: [],
        yqInfo: [],
        pqesInfos: [],
        yinLiangs: [],
        xingNengs: [],
        shiJians: [],
        yichangs: [],
        yichangs1: [],
        countList: [],
        timeList: [],
        abnormalInfos: [],
        ysInfoHalf: [],
        yqInfoHalf: [],
      },
      yinLiangs: [],
      ysslInfo: [],
      yichangs1: [],
      chartList: [],
      chartXnjdlList: [],
      chartTimeList: [],
      chartYichangList: [],
      device: [],
      devicexn: [],
      devicesj: [],
      devicedjsj: [],
      devicedjyy: [],
      deviceName: [],
      width: '',
      loading: false,
      // 月
      ysslDataZong: [],
      xnjdlDataZong: [],
      timeDatazong: [],
      reasonCount: [],
      reasonTime: [],
      yichangDatazong: [],
      ysslTotalWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 11, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            // maxBarWidth: 15,
            barWidth: 10,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: '#5470c6',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  // show: true, //开启显示
                  // position: 'top', //在上方显示
                  // textStyle: { //数值样式
                  //   color: '#555555',
                  //   fontSize: 10
                  // },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#5470c6',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 10
                  },
                  formatter: function (res) {
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      ysslWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            },
            interval: 0,
            formatter: function (value) {
              // 使用正则表达式分割文本
              if (value) {
                return value.match(/\((\d+(?:\.\d+)?)\)/)[1];
              } else {
                return value;
              }
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  //{{a}}{abg|}{b}{c}
                  // formatter:'{{c}}',
                  formatter: function (res) {
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            },
            lineStyle: {
              width: 0 // 设置线宽为0，不显示线
            }
          }
        ],
      },
      chartDataZong: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  formatter: function (res) {
                    // console.log(res)
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      chartDataTimeZong: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  formatter: function (res) {
                    // console.log(res)
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      chartDataYichangZong: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            connectNulls: true,
            barMinWidth: 30,
            barMaxWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#AAE581', '#FFD279', '#FF8177', '#80DFFF', '#FF9669', '#82FAAA', '#FFCA6E', '#8A84FA', '#FFFD71', '#7EE2BC', '#FFA182', '#FF79A8', '#7EA8F1', '#FFB46E', '#7EEEE7', '#C3FF74', '#A184EA', '#6D88F7', '#7A80EB', '#F7E28E']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'bar',
            barMinWidth: 30,
            barMaxWidth: 30,
            connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  // formatter: function (res) {
                  //   console.log(res)
                  //   if (res.value) {
                  //     return res.value[2] + '%'
                  //   } else {
                  //     return 0
                  //   }
                  // },
                }
              },

            }
          },
          {
            type: 'bar',
            barMinWidth: 30,
            barMaxWidth: 30,
            // barWidth: 10,
            connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#7EAD5E', '#EEB951', '#CF6158', '#69B0C8', '#E87340', '#6CCC8C', '#D2965C', '#6964C2', '#D2D15B', '#67B999', '#DC7F61', '#D65C87', '#6689C5', '#D98B42', '#65C1BB', '#9BCE58', '#725AAF', '#5166B8', '#5559A5', '#BBA962']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }
        ],
      },
      chartDataYichangZongCount: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 1, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            connectNulls: true,
            barMinWidth: 30,
            barMaxWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#AAE581', '#FFD279', '#FF8177', '#80DFFF', '#FF9669', '#82FAAA', '#FFCA6E', '#8A84FA', '#FFFD71', '#7EE2BC', '#FFA182', '#FF79A8', '#7EA8F1', '#FFB46E', '#7EEEE7', '#C3FF74', '#A184EA', '#6D88F7', '#7A80EB', '#F7E28E']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'bar',
            barMinWidth: 30,
            barMaxWidth: 30,
            connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  // formatter: function (res) {
                  //   // console.log(res)
                  //   if (res.value) {
                  //     return res.value[2] + '%'
                  //   } else {
                  //     return 0
                  //   }
                  // },
                }
              },

            }
          },
          {
            type: 'bar',
            barMinWidth: 30,
            barMaxWidth: 30,
            connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#7EAD5E', '#EEB951', '#CF6158', '#69B0C8', '#E87340', '#6CCC8C', '#D2965C', '#6964C2', '#D2D15B', '#67B999', '#DC7F61', '#D65C87', '#6689C5', '#D98B42', '#65C1BB', '#9BCE58', '#725AAF', '#5166B8', '#5559A5', '#BBA962']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }
        ],
      },
      ysslDataWeek: [],
      xnjdlTotalWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [{
          type: 'bar',
          yAxisIndex: 0, connectNulls: true,
          barWidth: 30,
          color: '#ffc553',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              // borderWidth: 7,
              label: {
                // show: true, //开启显示
                // position: 'top', //在上方显示
                // textStyle: { //数值样式
                //   color: '#555555',
                //   fontSize: 10
                // },

              }
            },

          }
        }, {
          type: 'line',
          // barWidth: 30,
          yAxisIndex: 1, connectNulls: true,
          color: '#F6BB17',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 14
                },
                formatter: function (res) {
                  if (res.value) {
                    return res.value[2] + '%'
                  } else {
                    return 0
                  }
                },
              }
            },

          }
        }],
      },
      xnjdlWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            },
            interval: 0,
            formatter: function (value) {
              // 使用正则表达式分割文本
              if (value) {
                return value.match(/\((\d+(?:\.\d+)?)\)/)[1];
              } else {
                return value;
              }
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  formatter: function (res) {
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      xnjdlDataWeek: [],
      sjjdlTotalWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            },
            interval: 0,
            formatter: function (value) {
              if (value) {
                return value.match(/\((\d+(?:\.\d+)?)\)/)[1];
              } else {
                return value;
              }
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [{
          type: 'bar',
          yAxisIndex: 0, connectNulls: true,
          // maxBarWidth: 10,
          barWidth: 30,
          color: '#ffc553',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              // borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'inside', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 14
                },

              }
            },

          }
        }, {
          type: 'line',
          // barWidth: 30,
          yAxisIndex: 1, connectNulls: true,
          color: '#F6BB17',
          itemStyle: {
            normal: {
              color: '#5470c6',
              // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
              borderWidth: 7,
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#555555',
                  fontSize: 14
                },
                formatter: function (res) {
                  if (res.value) {
                    return res.value[2] + '%'
                  } else {
                    return 0
                  }
                },
              }
            },

          }
        }],
      },
      sjjdlWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            },
            interval: 0,
            formatter: function (value) {
              // 使用正则表达式分割文本
              if (value) {
                return value.match(/\((\d+(?:\.\d+)?)\)/)[1];
              } else {
                return value;
              }
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true},
            position: 'left',
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'right',
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar',
            yAxisIndex: 0, connectNulls: true,
            maxBarWidth: 30,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: function (params) {
                  // 根据params的
                  const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                  //返回对应的颜色
                  return colorsMap[params.dataIndex]
                },
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'inside', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'line',
            // barWidth: 30,
            yAxisIndex: 1, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#F6BB17',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                  formatter: function (res) {
                    // console.log(res)
                    if (res.value) {
                      return res.value[2] + '%'
                    } else {
                      return 0
                    }
                  },
                }
              },

            }
          }
        ],
      },
      ycdjTotalWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            },
            interval: 0,
            formatter: function (value) {
              if (value) {
                return value;
              } else {
                return value;
              }
            }
          },
          data: null
        }],
        yAxis: [
          {
            type: 'value',
            splitLine: {show: false},
            position: 'left',
            // max: 120,
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
              // textStyle: { color: '#E96D63', },
              // show: true
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar', connectNulls: true,
            // maxBarWidth: 15,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: '#97CE71',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            },
          },
          {
            type: 'bar',
            // barWidth: 30, connectNulls: true,
            color: '#F6BB17',
            barWidth: 30,
            itemStyle: {
              normal: {
                color: '#FFC553',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          },
          {
            type: 'bar',
            barWidth: 30, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#E96D63',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }],
      },
      ycdjWeek: {
        // 数据集
        source: [],
        xAxis: [{
          type: 'category',
          // name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 14, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        }],
        // 系列
        yAxis: [
          {
            type: 'value',
            splitLine: {show: false},
            position: 'left',
            // max: 120,
            offset: 0,
            axisLine: {
              // show: true,
              lineStyle: {
                color: '#9E9E9E',
              },
            },
            axisLabel: {
              formatter: '{value}',
              // textStyle: { color: '#E96D63', },
            },
          },
        ],
        // 系列
        series: [
          {
            type: 'bar', connectNulls: true,
            // maxBarWidth: 15,
            barWidth: 30,
            color: '#ffc553',
            itemStyle: {
              normal: {
                color: '#97CE71',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                // borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },

                }
              },

            }
          },
          {
            type: 'bar',
            barWidth: 30, connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#FFC553',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          },
          {
            type: 'bar',
            barWidth: 30,
            connectNulls: true,
            color: '#F6BB17',
            itemStyle: {
              normal: {
                color: '#E96D63',
                // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                borderWidth: 7,
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#555555',
                    fontSize: 14
                  },
                }
              },

            }
          }
        ],
      },
      timeDataWeek: [],
      yichangData: [],
      name: [],
      nameWeek: [],
      namePQES: [],
      colorDevice: ['#AAE581', '#FFD279', '#FF8177', '#80DFFF', '#FF9669', '#82FAAA', '#FFCA6E', '#8A84FA', '#FFFD71', '#7EE2BC', '#FFA182', '#FF79A8', '#7EA8F1', '#FFB46E', '#7EEEE7', '#C3FF74', '#A184EA', '#6D88F7', '#7A80EB', '#F7E28E'],
      colorArrPQES: ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583'],
      colorArr: ['#5470c6', '#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583'],
      DeepColorArrDevice: ['#7EAD5E', '#EEB951', '#CF6158', '#69B0C8', '#E87340', '#6CCC8C', '#D2965C', '#6964C2', '#D2D15B', '#67B999', '#DC7F61', '#D65C87', '#6689C5', '#D98B42', '#65C1BB', '#9BCE58', '#725AAF', '#5166B8', '#5559A5', '#BBA962'],
      DeepColorArr: ['#4A63B3', '#7EAD5E', '#EEB951', '#CF6158', '#69B0C8', '#E87340', '#6CCC8C', '#D2965C', '#6964C2', '#D2D15B', '#67B999', '#DC7F61', '#D65C87', '#6689C5', '#D98B42', '#65C1BB', '#9BCE58', '#725AAF', '#5166B8', '#5559A5', '#BBA962'],
    }
  },
  watch: {
    timeTables(value) {
      this.timeTable = []
      value.map(v => {
        this.timeTable.push(v)
      })
    },
    months(newVal) {
      this.timeMonths = []
      if (newVal.length === 4) {
        this.timeMonths.push(...newVal)
        this.getListWeek()
      }
    },
  },
  created() {
    this.timeMonths = []
    this.timeTable = []
    if (this.months && this.timeTables) {
      this.timeMonths.push(...this.months)
      this.timeTables.map(v => {
        this.timeTable.push(this.dateFmtYmTable(v))
      })
    }
    // console.log(this.$store.getters.userInfo.teamName.includes('有限公司'))
    if (this.$store.getters.userInfo.teamName.includes('股份有限公司')) {
      this.teamName = this.$store.getters.userInfo.teamName.split('股份有限公司')[0]
    } else if (this.$store.getters.userInfo.teamName.includes('有限责任公司')) {
      this.teamName = this.$store.getters.userInfo.teamName.split('有限责任公司')[0]
    } else if (this.$store.getters.userInfo.teamName.includes('责任有限公司')) {
      this.teamName = this.$store.getters.userInfo.teamName.split('责任有限公司')[0]
    } else if (this.$store.getters.userInfo.teamName.includes('产业园有限公司')) {
      this.teamName = this.$store.getters.userInfo.teamName.split('产业园有限公司')[0]
    } else if (this.$store.getters.userInfo.teamName.includes('管理有限公司')) {
      this.teamName = this.$store.getters.userInfo.teamName.split('管理有限公司')[0]
    } else if (this.$store.getters.userInfo.teamName.includes('有限公司')) {
      this.teamName = this.$store.getters.userInfo.teamName.split('有限公司')[0]
    } else {
      this.teamName = this.$store.getters.userInfo.teamName
    }
    this.getDate()
    this.getListWeek()
  },
  mounted() {
    window.goBack = this.goBack
  },
  methods: {
    handleSelectDevice() {
      this.chartList.forEach(item => {
        if (this.device.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    handleSelectxn() {
      this.chartXnjdlList.forEach(item => {
        if (this.devicexn.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    handleSelectdjsj() {
      this.chartYichangList.forEach(item => {
        if (this.devicedjsj.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    handleSelectdjyy() {
      this.formDateWeek.abnormalInfos.forEach(item => {
        if (this.devicedjyy.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    handleSelectsj() {
      this.chartTimeList.forEach(item => {
        if (this.devicesj.includes(item.name)) {
          item.show = true; // 标记需要显示
        } else {
          item.show = false; // 标记不需要显示
        }
      });
    },
    // 日期
    getDate() {
      const date = new Date()
      const timestampDay = date.getTime()
      const year = date.getFullYear() // 年
      let month = date.getMonth() + 1 // 月
      let day2 = new Date(timestampDay).getDate()
      if ((date.getDate() - 1) === 0) {
        month = month - 1
      }
      month = month < 10 ? `0${month}` : month;
      this.year = `${year}`
      this.dateDay = `${year}年${month}月${day2}日`

    },
    dateFmtYm(timestamp) {
      return moment(timestamp).format('YYYY-MM')
    },
    dateFmtYmTable(timestamp) {
      return moment(timestamp).format('YYYY.MM')
    },
    getListWeek() {
      this.xnjdlDataWeek = []
      this.timeDataWeek = []
      this.ysslDataWeek = []
      this.timeDatazong = []
      this.chartList = []
      // this.chartDataYichangZong = []
      // this.chartDataYichangZongCount = []
      this.yichangDatazong = []
      this.chartYichangList = []
      this.reasonCount = []
      this.reasonTime = []
      this.deviceName = []
      this.device = []
      this.deviceyy = []
      this.devicexn = []
      this.devicesj = []
      this.devicedjsj = []
      this.devicedjyy = []
      this.chartXnjdlList = []
      this.chartTimeList = []
      this.nameWeek = []
      this.yichangs1 = []
      this.formDateWeek.countList = []
      this.formDateWeek.timeList = []
      this.formDateWeek.abnormalInfos = []
      API.teamDayMonthReport({
        time1: this.dateFmtYm(this.timeMonths[0]),
        time2: this.dateFmtYm(this.timeMonths[1]),
        time3: this.dateFmtYm(this.timeMonths[2]),
        time4: this.dateFmtYm(this.timeMonths[3])
      }).then(res => {
        this.formDateWeek = res.message.data
        //   // 印刷数量
        const listDeviceTotal = [];
        const listDevice = []
        for (const item of this.formDateWeek.yinLiangs) {
          if (item.name !== '合计') {
            this.chartList.push(item)
            this.nameWeek.push(item.name)
          }
          // 印刷数量
          this.ysslDataWeek.push({
            name: item.name,
            value: item.value,
            value1: item.value1,
            value2: item.value2,
            value3: item.value3,
            value4: item.value4,
            value5: item.value5,
            value6: item.value6,
            value7: item.value7,
            value8: item.value8,
            value9: item.value9,
            value10: item.value10,
            value11: item.value11,
            value12: item.value12,
          })
        }
        // console.log(this.nameWeek)
        this.ysslDataZong = [
          {
            name: '合计',
            value: Number(this.ysslDataWeek[0].value1) + Number(this.ysslDataWeek[0].value4) + Number(this.ysslDataWeek[0].value7) + Number(this.ysslDataWeek[0].value10),
            value1: Number(this.ysslDataWeek[0].value2) + Number(this.ysslDataWeek[0].value5) + Number(this.ysslDataWeek[0].value8) + Number(this.ysslDataWeek[0].value11),
            value2: Math.round(((Number(this.ysslDataWeek[0].value3) + Number(this.ysslDataWeek[0].value6) + Number(this.ysslDataWeek[0].value9) + Number(this.ysslDataWeek[0].value12)) / 4)),
          },
          {
            name: '本月' + '(' + (this.timeTable[3]) + ')',
            value: Number(this.ysslDataWeek[0].value1),
            value1: this.ysslDataWeek[0].value2,
            value2: this.ysslDataWeek[0].value3,
          },
          {
            name: '上月' + '(' + this.timeTable[2] + ')',
            value: this.ysslDataWeek[0].value4,
            value1: this.ysslDataWeek[0].value5,
            value2: this.ysslDataWeek[0].value6,
          },
          {
            name: '上上月' + '(' + (this.timeTable[1]) + ')',
            value: this.ysslDataWeek[0].value7,
            value1: this.ysslDataWeek[0].value8,
            value2: this.ysslDataWeek[0].value9,
          },
          {
            name: '上数三月' + '(' + (this.timeTable[0]) + ')',
            value: this.ysslDataWeek[0].value10,
            value1: this.ysslDataWeek[0].value11,
            value2: this.ysslDataWeek[0].value12,
          }
        ]
        this.ysslDataZong.forEach(item => {
          if (item.name === '合计') {
            listDeviceTotal.push([
              item.name.split('(')[0],
              Number(item.value1),
              Number(item.value2),
            ]);
          } else {
            listDevice.push([
              item.name,
              Number(item.value1),
              Number(item.value2 / 10),
            ]);
          }
        })
        // console.log(this.ysslDataZong)
        this.deviceName = []
        this.chartList.forEach((item) => {
          if (item.name !== '合计') {
            this.deviceName.push(item.name)
            this.device.push(item.name)
            this.deviceyy.push(item.name)
            this.devicexn.push(item.name)
            this.devicesj.push(item.name)
            this.devicedjsj.push(item.name)
            this.devicedjyy.push(item.name)
            item.chartData = {
              // 数据集
              source: [],
              xAxis: [{
                type: 'category',
                name: '', // 设置为空字符串
                // nameTextStyle: {
                //   show: false // 设置不显示名称
                // },
                axisLabel: {
                  textStyle: {
                    fontSize: 14, // 字体大小
                    fontFamily: 'Arial' // 字体类型
                  },
                  interval: 0,
                  formatter: function (value) {
                    // 使用正则表达式分割文本
                    // var ret = value.split('\n');
                    if (value) {
                      return value.match(/\((\d+(?:\.\d+)?)\)/)[1];
                    } else {
                      return value;
                    }
                  }
                },
                data: null
              }],
              // 系列
              yAxis: [
                {
                  type: 'value',
                  splitLine: {show: true},
                  position: 'left',
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
                {
                  type: 'value',
                  splitLine: {show: false},
                  position: 'right',
                  offset: 0,
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}%',
                  },
                },
              ],
              // 系列
              series: [
                {
                  type: 'bar',
                  yAxisIndex: 0, connectNulls: true,
                  // maxBarWidth: 15,
                  barWidth: 30,
                  color: '#ffc553',
                  itemStyle: {
                    normal: {
                      color: function (params) {
                        // 根据params的
                        const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                        //返回对应的颜色
                        return colorsMap[params.dataIndex]
                      },
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      // borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'inside', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },

                      }
                    },

                  }
                },
                {
                  type: 'line',
                  // barWidth: 30,
                  yAxisIndex: 1, connectNulls: true,
                  color: '#F6BB17',
                  itemStyle: {
                    normal: {
                      color: '#F6BB17',
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                        formatter: function (res) {
                          if (res.value) {
                            return res.value[2] + '%'
                          } else {
                            return 0
                          }
                        },
                      }
                    },

                  }
                }
              ],
            }
            item.show = true
            const listDeviceEchart = [];
            this.ysslDataZong.forEach(line => {
              if (line.name.split('(')[0] === '本月') {
                listDeviceEchart.push([
                  line.name,
                  item.value2,
                  item.value3 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上月') {
                listDeviceEchart.push([
                  line.name,
                  item.value5,
                  item.value6 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上上月') {
                listDeviceEchart.push([
                  line.name,
                  item.value8,
                  item.value9 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上数三月') {
                listDeviceEchart.push([
                  line.name,
                  item.value11,
                  item.value12 / 10,
                ]);
              }
            })
            item.chartData.source = [
              ['product', '月实际印量', '月平均产能利用率'],
              ...listDeviceEchart,
            ]
          }
        })
        //   // 印刷数量
        this.ysslTotalWeek.source = [
          ['product', '月实际印量', '月平均产能利用率'],
          ...listDeviceTotal,
        ]
        this.ysslWeek.source = [
          ['product', '月实际印量', '月平均产能利用率'],
          ...listDevice,
        ];

        // 性能稼动率
        const listXnjdlTotal = [];
        const listXnjdlDevice = []
        const listDeviceEchartzong = [];
        for (const item of this.formDateWeek.xingNengs) {
          if (item.name !== '合计') {
            this.chartXnjdlList.push(item)
          }
          this.xnjdlDataWeek.push({
            name: item.name,
            value: item.value,
            value1: item.value1,
            value2: item.value2,
            value3: item.value3,
            value4: item.value4,
            value5: item.value5,
            value6: item.value6,
            value7: item.value7,
            value8: item.value8,
            value9: item.value9,
            value10: item.value10,
          })
        }
        this.xnjdlDataZong = [
          {
            name: '合计',
            value: this.xnjdlDataWeek[0].value * 4,
            value1: Math.round((Number(this.xnjdlDataWeek[0].value1) + Number(this.xnjdlDataWeek[0].value3) + Number(this.xnjdlDataWeek[0].value5) + Number(this.xnjdlDataWeek[0].value7)) / 4),
            value2: Math.round(((Number(this.xnjdlDataWeek[0].value2) + Number(this.xnjdlDataWeek[0].value4) + Number(this.xnjdlDataWeek[0].value6) + Number(this.xnjdlDataWeek[0].value8)) / 4)),
          },
          {
            name: '本月' + '(' + (this.timeTable[3]) + ')',
            value: this.xnjdlDataWeek[0].value,
            value1: this.xnjdlDataWeek[0].value1,
            value2: this.xnjdlDataWeek[0].value2,
          },
          {
            name: '上月' + '(' + (this.timeTable[2]) + ')',
            value: this.xnjdlDataWeek[0].value,
            value1: this.xnjdlDataWeek[0].value3,
            value2: this.xnjdlDataWeek[0].value4,
          },
          {
            name: '上上月' + '(' + (this.timeTable[1]) + ')',
            value: this.xnjdlDataWeek[0].value,
            value1: this.xnjdlDataWeek[0].value5,
            value2: this.xnjdlDataWeek[0].value6,
          },
          {
            name: '上数三月' + '(' + (this.timeTable[0]) + ')',
            value: this.xnjdlDataWeek[0].value,
            value1: this.xnjdlDataWeek[0].value7,
            value2: this.xnjdlDataWeek[0].value8,
          }
        ]
        this.xnjdlDataZong.forEach(item => {
          if (item.name === '合计') {
            listXnjdlTotal.push([
              item.name.split('(')[0],
              Number(item.value1),
              Number(item.value2),
            ]);
          } else {
            listXnjdlDevice.push([
              item.name,
              Number(item.value1),
              Number(item.value2 / 10),
            ]);
          }
        })
        this.chartXnjdlList.forEach((item) => {
          if (item.name !== '合计') {
            item.show = true
            item.chartData = {
              // 数据集
              source: [],
              xAxis: [{
                type: 'category',
                name: '', // 设置为空字符串
                // nameTextStyle: {
                //   show: false // 设置不显示名称
                // },
                axisLabel: {
                  textStyle: {
                    fontSize: 14, // 字体大小
                    fontFamily: 'Arial' // 字体类型
                  },
                  interval: 0,
                  formatter: function (value) {
                    // 使用正则表达式分割文本
                    if (value) {
                      return value.match(/\((\d+(?:\.\d+)?)\)/)[1];
                    } else {
                      return value;
                    }
                  }
                },
                data: null
              }],
              // 系列
              yAxis: [
                {
                  type: 'value',
                  splitLine: {show: true},
                  position: 'left',
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
                {
                  type: 'value',
                  splitLine: {show: false},
                  position: 'right',
                  offset: 0,
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}%',
                  },
                },
              ],
              // 系列
              series: [
                {
                  type: 'bar',
                  yAxisIndex: 0, connectNulls: true,
                  // maxBarWidth: 15,
                  barWidth: 30,
                  color: '#ffc553',
                  itemStyle: {
                    normal: {
                      color: function (params) {
                        // 根据params的
                        const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                        //返回对应的颜色
                        return colorsMap[params.dataIndex]
                      },
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      // borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'inside', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                      }
                    },

                  }
                },
                {
                  type: 'line',
                  // barWidth: 30,
                  yAxisIndex: 1, connectNulls: true,
                  color: '#F6BB17',
                  itemStyle: {
                    normal: {
                      color: '#F6BB17',
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                        formatter: function (res) {
                          if (res.value) {
                            return res.value[2] + '%'
                          } else {
                            return 0
                          }
                        },
                      }
                    },

                  }
                }
              ],
            }
            item.show = true

            const listDeviceEchart = [];
            if (item.name !== '合计') {
              listDeviceEchartzong.push([
                item.name,
                item.value9,
                item.value10 / 10,
              ])
            }
            this.xnjdlDataZong.forEach(line => {
              if (line.name.split('(')[0] === '本月') {
                listDeviceEchart.push([
                  line.name,
                  item.value1,
                  item.value2 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上月') {
                listDeviceEchart.push([
                  line.name,
                  item.value3,
                  item.value4 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上上月') {
                listDeviceEchart.push([
                  line.name,
                  item.value5,
                  item.value6 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上数三月') {
                listDeviceEchart.push([
                  line.name,
                  item.value7,
                  item.value8 / 10,
                ]);
              }
            })
            item.chartData.source = [
              ['product', '实际运行速度', '性能稼动率'],
              ...listDeviceEchart,
            ]

          }
        })
        //
        this.xnjdlTotalWeek.source = [
          ['product', '4个月实际运行速度', '4个月平均性能稼动率'],
          ...listXnjdlTotal,
        ]
        this.xnjdlWeek.source = [
          ['product', '实际运行速度', '平均性能稼动率'],
          ...listXnjdlDevice,
        ];
        this.chartDataZong.source = [
          ['product', '4个月实际运行速度', '4个月平均性能稼动率'],
          ...listDeviceEchartzong,
        ]
        // 有效时间稼动率
        const listTimeTotal = [];
        const listTimeDevice = []
        const listTimeEchartzong = [];
        for (const item of this.formDateWeek.shiJians) {
          if (item.name !== '合计') {
            this.chartTimeList.push(item)
          }
          this.timeDatazong.push({
            name: item.name,
            value: item.value,
            value1: item.value1,
            value2: item.value2,
            value3: item.value3,
            value4: item.value4,
            value5: item.value5,
            value6: item.value6,
            value7: item.value7,
            value8: item.value8,
            value9: item.value9,
            value10: item.value10,
            value11: item.value11,
            value12: item.value12,
            value13: item.value13,
            value14: item.value14,
            value15: item.value15,
          })
        }
        this.timeDataWeek = [
          {
            name: '合计',
            value: this.timeDatazong[0].value1 + this.timeDatazong[0].value4 + this.timeDatazong[0].value7 + this.timeDatazong[0].value10,
            value1: Number(this.timeDatazong[0].value2) + Number(this.timeDatazong[0].value5) + Number(this.timeDatazong[0].value8) + Number(this.timeDatazong[0].value11),
            value2: Math.round(((Number(this.timeDatazong[0].value3) + Number(this.timeDatazong[0].value6) + Number(this.timeDatazong[0].value9) + Number(this.timeDatazong[0].value12)) / 4)),
          },
          {
            name: '本月' + '(' + (this.timeTable[3]) + ')',
            value: this.timeDatazong[0].value1,
            value1: this.timeDatazong[0].value2,
            value2: this.timeDatazong[0].value3,
          },
          {
            name: '上月' + '(' + this.timeTable[2] + ')',
            value: this.timeDatazong[0].value4,
            value1: this.timeDatazong[0].value5,
            value2: this.timeDatazong[0].value6,
          },
          {
            name: '上上月' + '(' + this.timeTable[1] + ')',
            value: this.timeDatazong[0].value7,
            value1: this.timeDatazong[0].value8,
            value2: this.timeDatazong[0].value9,
          },
          {
            name: '上数三月' + '(' + this.timeTable[0] + ')',
            value: this.timeDatazong[0].value10,
            value1: this.timeDatazong[0].value11,
            value2: this.timeDatazong[0].value12,
          }
        ]
        this.timeDataWeek.forEach(item => {
          if (item.name === '合计') {
            listTimeTotal.push([
              item.name.split('(')[0],
              Number(item.value1),
              Number(item.value2),
            ]);
          } else {
            listTimeDevice.push([
              item.name,
              Number(item.value1),
              Number(item.value2 / 10),
            ]);
          }
        })
        this.chartTimeList.forEach((item) => {
          if (item.name !== '合计') {
            item.show = true
            item.chartData = {
              // 数据集
              source: [],
              xAxis: [{
                type: 'category',
                name: '', // 设置为空字符串
                // nameTextStyle: {
                //   show: false // 设置不显示名称
                // },
                axisLabel: {
                  textStyle: {
                    fontSize: 14, // 字体大小
                    fontFamily: 'Arial' // 字体类型
                  },
                  interval: 0,
                  formatter: function (value) {
                    // 使用正则表达式分割文本
                    if (value) {
                      return value.match(/\((\d+(?:\.\d+)?)\)/)[1];
                    } else {
                      return value;
                    }
                  }
                },
                data: null
              }],
              // 系列
              yAxis: [
                {
                  type: 'value',
                  splitLine: {show: true},
                  position: 'left',
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
                {
                  type: 'value',
                  splitLine: {show: false},
                  position: 'right',
                  offset: 0,
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}%',
                  },
                },
              ],
              // 系列
              series: [
                {
                  type: 'bar',
                  yAxisIndex: 0, connectNulls: true,
                  // maxBarWidth: 15,
                  barWidth: 30,
                  color: '#ffc553',
                  itemStyle: {
                    normal: {
                      color: function (params) {
                        // 根据params的
                        const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                        //返回对应的颜色
                        return colorsMap[params.dataIndex]
                      },
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      // borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'inside', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                      }
                    },

                  }
                },
                {
                  type: 'line',
                  // barWidth: 30,
                  yAxisIndex: 1, connectNulls: true,
                  color: '#F6BB17',
                  itemStyle: {
                    normal: {
                      color: '#F6BB17',
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                        formatter: function (res) {
                          if (res.value) {
                            return res.value[2] + '%'
                          } else {
                            return 0
                          }
                        },
                      }
                    },

                  }
                }
              ],
            }
            item.show = true

            const listDeviceEchart = [];
            if (item.name !== '合计') {
              listTimeEchartzong.push([
                item.name,
                item.value14,
                item.value15 / 10,
              ])
            }
            this.timeDataWeek.forEach(line => {
              if (line.name.split('(')[0] === '本月') {
                listDeviceEchart.push([
                  line.name,
                  item.value2,
                  item.value3 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上月') {
                listDeviceEchart.push([
                  line.name,
                  item.value5,
                  item.value6 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上上月') {
                listDeviceEchart.push([
                  line.name,
                  item.value8,
                  item.value9 / 10,
                ]);
              } else if (line.name.split('(')[0] === '上数三月') {
                listDeviceEchart.push([
                  line.name,
                  item.value11,
                  item.value12 / 10,
                ]);
              }
            })
            item.chartData.source = [
              ['product', '月有效印刷时长', '月平均有效时间稼动率'],
              ...listDeviceEchart,
            ]

          }
        })
        //
        this.sjjdlTotalWeek.source = [
          ['product', '月有效印刷时长', '月平均有效时间稼动率'],
          ...listTimeTotal,
        ]
        this.sjjdlWeek.source = [
          ['product', '月有效印刷时长', '月平均有效时间稼动率'],
          ...listTimeDevice,
        ];
        this.chartDataTimeZong.source = [
          ['product', '4个有效印刷时长', '4个月平均有效时间稼动率'],
          ...listTimeEchartzong,
        ]

        // 四、异常待机数据
        const listYichangTotal = [];
        const listYichangDevice = []
        const listYichangEchartzong = [];
        const listYichangEchartzongCount = []
        let valuedj1 = 0
        let valuedj3 = 0
        let valuedj5 = 0
        for (const item of this.formDateWeek.yichangs) {
          if (item.name !== '合计') {
            this.chartYichangList.push(item)
            valuedj1 += Math.round((item.value11 / 60) * 10) / 10 + Math.round((item.value13 / 60) * 10) / 10 ;
            valuedj3 += Math.round((item.value11 / 60) * 10) / 10;
            valuedj5 += Math.round((item.value13 / 60) * 10) / 10;
            this.yichangDatazong.push({
              name: item.name,
              value: item.value,
              value1: item.value1,
              value2: item.value2,
              value3: item.value3,
              value4: item.value4,
              value5: item.value5,
              value6: item.value6,
              value7: item.value7,
              value8: item.value8,
              value9: item.value9,
              value10: item.value10,
              value11: item.value11,
              value12: item.value12,
              value13: item.value13,
              value14: item.value14,
            })
          }

        }
        this.yichangDatazong.unshift({
          name: '合计',
          value: this.formDateWeek.yichangs[0].value,
          value1: this.formDateWeek.yichangs[0].value1,
          value2: this.formDateWeek.yichangs[0].value2,
          value3: this.formDateWeek.yichangs[0].value3,
          value4: this.formDateWeek.yichangs[0].value4,
          value5: this.formDateWeek.yichangs[0].value5,
          value6: this.formDateWeek.yichangs[0].value6,
          value7: this.formDateWeek.yichangs[0].value7,
          value8: this.formDateWeek.yichangs[0].value8,
          value9: valuedj1,
          value10: this.formDateWeek.yichangs[0].value10,
          value11: valuedj3,
          value12: this.formDateWeek.yichangs[0].value12,
          value13: valuedj5,
          value14: this.formDateWeek.yichangs[0].value14,
        })
        this.yichangData = [
          {
            name: '合计',
            value: this.yichangDatazong[0].value,
            value1: Number(this.yichangDatazong[0].value1) + Number(this.yichangDatazong[0].value3) + Number(this.yichangDatazong[0].value5) + Number(this.yichangDatazong[0].value7),
            value2: Number(this.yichangDatazong[0].value2) + Number(this.yichangDatazong[0].value4) + Number(this.yichangDatazong[0].value6) + Number(this.yichangDatazong[0].value8),
          },
          {
            name: '本月',
            value: this.yichangDatazong[0].value,
            value1: this.yichangDatazong[0].value1,
            value2: this.yichangDatazong[0].value2,
          },
          {
            name: '上月',
            value: this.yichangDatazong[0].value,
            value1: this.yichangDatazong[0].value3,
            value2: this.yichangDatazong[0].value4,
          },
          {
            name: '上上月',
            value: this.yichangDatazong[0].value,
            value1: this.yichangDatazong[0].value5,
            value2: this.yichangDatazong[0].value6,
          },
          {
            name: '上数三月',
            value: this.yichangDatazong[0].value,
            value1: this.yichangDatazong[0].value7,
            value2: this.yichangDatazong[0].value8,
          }
        ]
        this.yichangData.forEach(item => {
          if (item.name === '合计') {
            listYichangTotal.push([
              item.name,
              Number(item.value1),
              Number(item.value2),
            ]);
          } else {
            listYichangDevice.push([
              item.name,
              Number(item.value1),
              Number(item.value2),
            ]);
          }
        })
        this.chartYichangList.forEach((item) => {
          if (item.name !== '合计') {
            item.show = true
            item.showZong = false
            item.chartData = {
              // 数据集
              source: [],
              xAxis: [{
                type: 'category',
                name: '', // 设置为空字符串
                // nameTextStyle: {
                //   show: false // 设置不显示名称
                // },
                axisLabel: {
                  textStyle: {
                    fontSize: 14, // 字体大小
                    fontFamily: 'Arial' // 字体类型
                  },
                  interval: 0,
                  formatter: function (value) {
                    if (value) {
                      return value;
                    } else {
                      return value;
                    }
                  }
                },
                data: null
              }],
              // 系列
              yAxis: [
                {
                  type: 'value',
                  splitLine: {show: true},
                  position: 'left',
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
                {
                  type: 'value',
                  splitLine: {show: false},
                  position: 'right',
                  offset: 0,
                  axisLine: {
                    // show: true,
                    lineStyle: {
                      color: '#9E9E9E',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
              ],
              // 系列
              series: [
                {
                  type: 'bar',
                  yAxisIndex: 0, connectNulls: true,
                  // maxBarWidth: 15,
                  barWidth: 30,
                  color: '#ffc553',
                  itemStyle: {
                    normal: {
                      color: function (params) {
                        // 根据params的
                        const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                        //返回对应的颜色
                        return colorsMap[params.dataIndex]
                      },
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      // borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'inside', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                      }
                    },

                  }
                },
                {
                  type: 'line',
                  // barWidth: 30,
                  yAxisIndex: 1, connectNulls: true,
                  color: '#F6BB17',
                  itemStyle: {
                    normal: {
                      color: '#F6BB17',
                      // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                      borderWidth: 7,
                      label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                          color: '#555555',
                          fontSize: 14
                        },
                        // formatter: function (res) {
                        //   if (res.value) {
                        //     return res.value[2] + '%'
                        //   } else {
                        //     return 0
                        //   }
                        // },
                      }
                    },

                  }
                }
              ],
            }

            const listDeviceEchart = [];
            if (item.name !== '合计') {
              listYichangEchartzong.push([
                item.name,
                (Math.round((item.value11 / 60) * 10) / 10 + Math.round((item.value13 / 60) * 10) / 10).toFixed(1),
                Math.round((item.value11 / 60) * 10) / 10,
                Math.round((item.value13 / 60) * 10) / 10,
              ])
              listYichangEchartzongCount.push([
                item.name,
                item.value10,
                item.value12,
                item.value14,
              ])
            }
            this.yichangData.forEach(line => {
              if (line.name === '本月') {
                listDeviceEchart.push([
                  this.timeTable[3],
                  (item.value1 / 60).toFixed(1),
                  item.value2,
                ]);
              } else if (line.name === '上月') {
                listDeviceEchart.push([
                  this.timeTable[2],
                  (item.value3 / 60).toFixed(1),
                  item.value4,
                ]);
              } else if (line.name === '上上月') {
                listDeviceEchart.push([
                  this.timeTable[1],
                  (item.value5 / 60).toFixed(1),
                  item.value6,
                ]);
              } else if (line.name === '上数三月') {
                listDeviceEchart.push([
                  this.timeTable[0],
                  (item.value7 / 60).toFixed(1),
                  item.value8,
                ]);
              }
            })
            item.chartData.source = [
              ['product', '待机时长', '待机次数'],
              ...listDeviceEchart,
            ]
          }
        })
        this.chartDataYichangZong.source = [
          ['product', '异常待机时长', '待机原因已提交时长', '待机原因未提交时长'],
          ...listYichangEchartzong,
        ]
        this.chartDataYichangZongCount.source = [
          ['product', '异常待机次数', '待机原因已提交次数', '待机原因未提交次数'],
          ...listYichangEchartzongCount,
        ]
        const weekHourEchart = []
        const weekCountEchart = []
        //
        let value1 = 0
        let value3 = 0
        let value5 = 0
        this.formDateWeek.yichangs1.forEach((item, index) => {
          if (item.name !== '合计') {
            value1 += Math.round((item.value3 / 60) * 10) / 10 + Math.round((item.value5 / 60) * 10) / 10 ;
            value3 += Math.round((item.value3 / 60) * 10) / 10;
            value5 += Math.round((item.value5 / 60) * 10) / 10;
          }
          if (item.name !== '合计') {
            this.yichangs1.push({
              name: item.name + '(' + (this.timeTable[4 - index]) + ')',
              value1: item.value1,
              value2: item.value2,
              value3: item.value3,
              value4: item.value4,
              value5: item.value5,
              value6: item.value6,
            })
            weekHourEchart.push([
              this.timeTable[4 - index],
              (Math.round((item.value3 / 60) * 10) / 10 + Math.round((item.value5 / 60) * 10) / 10).toFixed(1),
              Math.round((item.value3 / 60) * 10) / 10,
              Math.round((item.value5 / 60) * 10) / 10,
            ]);
            weekCountEchart.push([
              this.timeTable[4 - index],
              item.value2,
              item.value4,
              item.value6,
            ]);
          }
        })
        this.yichangs1.unshift({
          name: '合计',
          value1: value1,
          value2: this.formDateWeek.yichangs1[0].value2,
          value3: value3,
          value4: this.formDateWeek.yichangs1[0].value4,
          value5: value5,
          value6: this.formDateWeek.yichangs1[0].value6,
        })
        this.ycdjTotalWeek.source = [
          ['product', '异常待机时长', '待机原因已提交时长', '待机原因未提交时长'],
          ...weekHourEchart,
        ]
        this.ycdjWeek.source = [
          ['product', '异常待机次数', '待机原因已提交次数', '待机原因未提交次数'],
          ...weekCountEchart,
        ];

        // 异常待机原因
        const arr = []
        const arrTime = []
        this.formDateWeek.abnormalInfos.forEach(item => {
          let sumValues = Array.from({length: 10}, () => 0); // 初始化一个长度为10的数组，用于存储每个value的和
          let sumTime = Array.from({length: 10}, () => 0); // 初始化一个长度为10的数组，用于存储每个value的和
          item.ycCounts.forEach((line, index) => {
            arr.push({
              name: line.name + '(' + (this.timeTable[3 - index]) + ')',
              value1: line.value1,
              value2: line.value2,
              value3: line.value3,
              value4: line.value4,
              value5: line.value5,
              value6: line.value6,
              value7: line.value7,
              value8: line.value8,
              value9: line.value9,
              value10: line.value10,
            })
            for (let i = 0; i < 10; i++) {
              sumValues[i] += line[`value${i + 1}`];
            }
          })
          // 添加合计对象到arr数组中
          arr.unshift({
            name: '合计',
            value1: sumValues[0],
            value2: sumValues[1],
            value3: sumValues[2],
            value4: sumValues[3],
            value5: sumValues[4],
            value6: sumValues[5],
            value7: sumValues[6],
            value8: sumValues[7],
            value9: sumValues[8],
            value10: sumValues[9],
          });
          item.ycTimes.forEach((line, index) => {
            arrTime.push({
              name: line.name + '(' + (this.timeTable[3 - index]) + ')',
              value1: line.value1,
              value2: line.value2,
              value3: line.value3,
              value4: line.value4,
              value5: line.value5,
              value6: line.value6,
              value7: line.value7,
              value8: line.value8,
              value9: line.value9,
              value10: line.value10,
            })
            for (let i = 0; i < 10; i++) {
              sumTime[i] += line[`value${i + 1}`];
            }
          })
          arrTime.unshift({
            name: '合计',
            value1: sumTime[0],
            value2: sumTime[1],
            value3: sumTime[2],
            value4: sumTime[3],
            value5: sumTime[4],
            value6: sumTime[5],
            value7: sumTime[6],
            value8: sumTime[7],
            value9: sumTime[8],
            value10: sumTime[9],
          });
        })
        let temp = {};

        arr.forEach(obj => {
          if (temp[obj.name]) {
            temp[obj.name].value1 += Number(obj.value1);
            temp[obj.name].value2 += obj.value2;
            temp[obj.name].value3 += obj.value3;
            temp[obj.name].value4 += obj.value4;
            temp[obj.name].value5 += obj.value5;
            temp[obj.name].value6 += obj.value6;
            temp[obj.name].value7 += obj.value7;
            temp[obj.name].value8 += obj.value8;
            temp[obj.name].value9 += obj.value9;
            temp[obj.name].value10 += obj.value10;
          } else {
            temp[obj.name] = {...obj};
          }
        });
        for (const key in temp) {
          this.reasonCount.push(temp[key]);
        }
        let tempTime = {};

        arrTime.forEach(obj => {
          if (tempTime[obj.name]) {
            tempTime[obj.name].value1 += Number(obj.value1);
            tempTime[obj.name].value2 += obj.value2;
            tempTime[obj.name].value3 += obj.value3;
            tempTime[obj.name].value4 += obj.value4;
            tempTime[obj.name].value5 += obj.value5;
            tempTime[obj.name].value6 += obj.value6;
            tempTime[obj.name].value7 += obj.value7;
            tempTime[obj.name].value8 += obj.value8;
            tempTime[obj.name].value9 += obj.value9;
            tempTime[obj.name].value10 += obj.value10;
          } else {
            tempTime[obj.name] = {...obj};
          }
        });
        for (const key in tempTime) {
          this.reasonTime.push(tempTime[key]);
        }

        this.formDateWeek.abnormalInfos.map(item => {
          const countEchart = []
          item.show = true
          item.valueCount = 0
          item.valueTime = 0
          item.showTime = true
          item.chartData = {
            // 数据集
            source: [],
            xAxis: [{
              type: 'category',
              name: '', // 设置为空字符串
              // nameTextStyle: {
              //   show: false // 设置不显示名称
              // },
              axisLabel: {
                textStyle: {
                  fontSize: 14, // 字体大小
                  fontFamily: 'Arial' // 字体类型
                },
                interval: 0,
                formatter: function (value) {
                  if (value) {
                    return value;
                  } else {
                    return value;
                  }
                }
              },
              data: null
            }],
            // 系列
            yAxis: [
              {
                type: 'value',
                splitLine: {show: true},
                position: 'left',
                axisLine: {
                  // show: true,
                  lineStyle: {
                    color: '#9E9E9E',
                  },
                },
                axisLabel: {
                  formatter: '{value}',
                },
              },
              {
                type: 'value',
                splitLine: {show: false},
                position: 'right',
                offset: 0,
                axisLine: {
                  // show: true,
                  lineStyle: {
                    color: '#9E9E9E',
                  },
                },
                axisLabel: {
                  formatter: '{value}%',
                },
              },
            ],
            series: [
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#97CE71',
                    // color: function (params) {
                    //   // 根据params的
                    //   const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                    //   //返回对应的颜色
                    //   return colorsMap[params.dataIndex]
                    // },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#FFC553',
                    // color: function (params) {
                    //   // 根据params的
                    //   const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                    //   //返回对应的颜色
                    //   return colorsMap[params.dataIndex]
                    // },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#E96D63',
                    // color: function (params) {
                    //   // 根据params的
                    //   const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                    //   //返回对应的颜色
                    //   return colorsMap[params.dataIndex]
                    // },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#71C1DC',
                    // color: function (params) {
                    //   // 根据params的
                    //   const colorsMap = ['#97CE71', '#FFC553', '#E96D63', '#71C1DC', '#FE814B', '#71DC95', '#E9B863', '#7671DC', '#E9E763', '#71CEAA', '#F78F6D', '#F16898', '#7198DC', '#FEA24B', '#71DCD5', '#AFE963', '#8F71DC', '#637EE9', '#6E73D9', '#EAD583']
                    //   //返回对应的颜色
                    //   return colorsMap[params.dataIndex]
                    // },
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#FE814B',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#71DC95',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
            ]
          };
          item.ycCounts.forEach((line, index) => {
            countEchart.push([
              this.timeTable[3 - index],
              line.value3,
              line.value4,
              line.value5,
              line.value6,
              line.value7,
              line.value8,
            ]);
            item.valueCount += Number(line.value9)
          })
          item.chartData.source = [
            ['product', ...this.formDateWeek.countList],
            ...countEchart,
          ]

          const timeEchart = []
          item.chartDataTime = {
            // 数据集
            source: [],
            xAxis: [{
              type: 'category',
              name: '', // 设置为空字符串
              // nameTextStyle: {
              //   show: false // 设置不显示名称
              // },
              axisLabel: {
                textStyle: {
                  fontSize: 14, // 字体大小
                  fontFamily: 'Arial' // 字体类型
                },
                interval: 0,
                formatter: function (value) {
                  if (value) {
                    return value;
                  } else {
                    return value;
                  }
                }
              },
              data: null
            }],
            // 系列
            yAxis: [
              {
                type: 'value',
                splitLine: {show: true},
                position: 'left',
                axisLine: {
                  // show: true,
                  lineStyle: {
                    color: '#9E9E9E',
                  },
                },
                axisLabel: {
                  formatter: '{value}',
                },
              },
              {
                type: 'value',
                splitLine: {show: false},
                position: 'right',
                offset: 0,
                axisLine: {
                  // show: true,
                  lineStyle: {
                    color: '#9E9E9E',
                  },
                },
                axisLabel: {
                  formatter: '{value}%',
                },
              },
            ],
            series: [
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#97CE71',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#FFC553',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#E96D63',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#71C1DC',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#FE814B',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
              {
                type: 'bar',
                yAxisIndex: 0, connectNulls: true,
                maxBarWidth: 30,
                barWidth: 30,
                color: '#ffc553',
                itemStyle: {
                  normal: {
                    color: '#71DC95',
                    // borderColor: 'rgba(246, 133, 110, 0.5)',  //圆点透明 边框
                    // borderWidth: 7,
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                        color: '#555555',
                        fontSize: 14
                      },

                    }
                  },

                }
              },
            ]
          };
          item.ycTimes.forEach((line, index) => {
            timeEchart.push([
              this.timeTable[3 - index],
              line.value3,
              line.value4,
              line.value5,
              line.value6,
              line.value7,
              line.value8,
            ]);
            item.valueTime += Number(line.value9)
          })
          item.chartDataTime.source = [
            ['product', ...this.formDateWeek.timeList],
            ...timeEchart,
          ]
        });
        this.$forceUpdate()
        console.log(this.formDateWeek.abnormalInfos)
        // arrDate = Object.values(temp);
      })
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      }
    },
    // 日期转换
    dateFmt(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD')
    },
    test2(index) {
      const arr4 = JSON.parse(JSON.stringify(this.formDateWeek.pqesInfos));
      arr4.forEach((v) => {
        v.rowspan = 1;
      });
      for (let i = 0; i < arr4.length; i++) {
        // 内层循环，上面已经给所有的行都加了v.rowspan = 1
        // 这里进行判断
        // 如果当前行的id和下一行的id相等
        // 就把当前v.rowspan + 1
        // 下一行的v.rowspan - 1
        for (let j = i + 1; j < arr4.length; j++) {
          // console.log(arr4[i].order)
          if (arr4[i].name === arr4[j].name) {
            arr4[i].rowspan++;
            arr4[j].rowspan--;
          }
          // return
        }
        // 这里跳过已经重复的数据
        i = (i + arr4[i].rowspan - 1);
      }
      return arr4[index].rowspan
    },
    objectSpanMethodWeek({rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        return {
          rowspan: this.test2(rowIndex),
          colspan: 1
        };
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
//#section-box {
//  width: 390px;
//}
.page-container .page-main {
  //background-color: #fff;
  //padding-top: 76px
}
.section {
  padding: 0 10px;
}
::v-deep .van-nav-bar__title {
  color: #ffffff;
  font-weight: bold;
}

::v-deep .van-nav-bar__text {
  color: #ffffff;
}

::v-deep .van-nav-bar__arrow {
  color: #ffffff;
}

::v-deep .van-nav-bar__content {
  background-color: #212c64;
}

::v-deep .van-dropdown-menu__title {
  font-weight: bold;
}

.search-box {
  position: relative;
  z-index: 9;
  margin: 15px 0px;
  background-color: #F4F4F5;
  box-shadow: none !important;
  width: 31%;

  .search-item {
    color: #737373;
    font-weight: normal !important;
  }
}

.frontPage {
  width: 100%;
}

.year {
  position: absolute;
  font-size: 80px;
  color: #332E2F;
  top: 23%;
  left: 4%;
  font-weight: bold;
}

.title-name {
  position: absolute;
  color: #F3BC16;
  font-size: 70px;
  top: 24%;
  right: 10%;
  font-weight: bold;
}

.company-name {
  position: absolute;
  font-size: 55px;
  color: #000000;
  top: 30%;
  right: 10%;
  font-weight: bold;
}

.info-box {
  position: absolute;
  font-size: 25px;
  color: #707070;
  bottom: 8%;
  left: 5%;

  ul {
    margin-top: 8px;
  }

  ul li {
    line-height: 45px;
    list-style-type: disc;
    list-style-position: inside; /* 将标记放置在内容内部，可选 */
    list-style-color: #E7B021; /* 设置标记颜色为黑色，可选 */
  }

  ul li::marker {
    //font-size: 16px; /* 根据需要调整字体大小 */
    fill: #E7B021; /* 设置填充颜色为黑色 */
    color: #E7B021;
  }

  img {
    width: 35px;
    margin-right: 8px;
  }
}

.logo {
  width: 100px;
}

.label-icon {
  padding-top: 25px;
}

.label-icon-img {
  position: relative;
  width: 350px;
  right: -65.5%;
}

.title-box {
  display: flex;
  font-size: 26px;
  font-weight: bold;
  align-items: center;
  margin-top: 20px;
  padding-top: 10px;
  margin-bottom: 30px;

  img {
    width: 40px;
    margin-right: 5px;

  }
}

::v-deep .el-table__header-wrapper {
  border-bottom: 0rem solid #ffb500;
}

::v-deep .custom-table-header .el-table__header-wrapper {
  background-color: #f0f0f0; /* 你想要的表头颜色 */
}

::v-deep .el-table thead tr th {
  background-color: #FADD8B;
  color: #333333;
  //padding: 7px 0;
}

::v-deep .el-table--border th, ::v-deep .el-table--border td {
  border-bottom: 0.01rem solid #d1caca;
}

::v-deep .el-table--border th, ::v-deep .el-table--border td {
  border-right: 0.01rem solid #d1caca;
}

::v-deep .el-table td {
  //border-bottom: 0.02667rem solid #d1caca;
  //padding: 7px 0;
}

::v-deep .el-table th > .cell {
  //padding: 0 5px;
}

::v-deep .el-table {
  //margin-top: 10px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  font-size: 14px;
}

::v-deep .el-table .warning-row {
  background: #FFF3D2;
  // color:red;
}

.copywriter {
  border-radius: 6px;
  margin-top: 15px;
  padding: 15px;
  background-color: #FFF3D2;
  color: #333333;
  font-size: 16px;
  margin-bottom: 20px;

  div:nth-child(2) {
    word-wrap: break-word; /* 允许在边界内折行长单词 */
    overflow-wrap: break-word;
    margin-top: 10px;
  }
}

.statistics {
  position: relative;

  div:nth-child(1) {
    color: #9E9E9E;
    font-size: 10px;
    position: absolute;
    left: 3%;
  }

  div:nth-child(2) {
    font-size: 12px;
    color: #000000;
    text-align: center;
  }
}

.statistics2 {
  position: relative;

  div {
    font-size: 12px;
    color: #000000;
    text-align: center;
  }
}

.echart-title {
  position: relative;
  top: -5%;
  //font-size: 10px;
  div:nth-child(1) {
    color: #9E9E9E;
    font-size: 18px;
    position: absolute;
    left: 3%;
    bottom: 0;
  }

  div:nth-child(2) {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }
}

.echart-title2 {
  position: relative;
  top: -5%;
  //font-size: 10px;
  div:nth-child(1) {
    color: #9E9E9E;
    font-size: 18px;
    position: absolute;
    left: 3%;
    bottom: 0;
  }

  div:nth-child(2) {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }

  .time-data2 {
    position: relative;
    right: -24%;
    width: 58%;
  }
}

.echarts-title {
  margin-top: 25px !important;

  div:nth-child(2) {
    font-size: 12px;
    color: #000000;
    font-weight: bold;
    text-align: right;
  }
}

.echarts-title2 {
  div:nth-child(2) {
    font-size: 11px;
    color: #000000;
    font-weight: bold;
    text-align: right;
  }
}

.el-table-statistics {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.el-table-statisticsa {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-top: 0px;
}

::v-deep .el-table .cell {
  line-height: 1.8;
}

.echatsImg {
  width: 100%;
  min-width: 250px;
  height: 200px;
}

.time-data {
  position: relative;
  right: -24%;
  width: 78%;
}

//.color-item {
//  display: inline-block;
//  width: 10px;
//  height: 10px;
//  margin-right: 5px;
//}

//.color-line {
//  font-size: 11px;
//  margin-right: 10px;
//  padding: 1px 0;
//}

.color-box {
  line-height: 0.5;
  //text-align: center;
  margin-top: 20px;
  padding: 0 12px;
}

.color-line {
  font-size: 16px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 22px;
  width: 185px;
}

.color-item {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

::v-deep .reson-table .cell {
  //line-height: 1.5;
  //padding-left: 0;
  //padding-right: 0;
}

::v-deep .reson-table th > .cell {
  //padding: 0 0px;
  //font-size: 10px;
}

::v-deep .reson-table thead tr th {
  //padding: 5px 0;
}

::v-deep .reson-table2 th > .cell {
  //padding: 0 0px;
  //font-size: 10px;
  text-align: center;
}

::v-deep .reson-table2 .el-table_4_column_17 > .cell {
  //padding: 0 2px;
}

::v-deep .reson-table2 .el-table_4_column_20 > .cell {
  //padding: 0 2px;
}

.reason-name {
  font-size: 12px;
  margin: 10px 0 0;
  display: flex;
  justify-content: space-between;
}

.select-time {
  //display: flex;
  //justify-content: space-between;
  font-size: 16px;
  margin: 15px 0 10px 10px;

  div {
    //width: 49%;
    display: inline-block;
    text-align: center;
    padding: 10px 20px;
    background-color: #F4F4F5;
    border-radius: 4px;
    margin-right: 8px;
  }

  div:hover {
    background-color: #FFA800;
    color: #fff;
  }

  div.active {
    background-color: #FFA800;
    color: #fff;
  }
}

//::v-deep .search-box2 .el-input--suffix .el-input__inner {
//  border: 0;
//}

//::v-deep .search-box2 .el-input__suffix {
//  display: none;
//}
//
//::v-deep .search-box2 .el-select .el-tag {
//  margin: 0;
//}
//
//::v-deep .search-box2 .el-tag.el-tag--info {
//  margin-left: 10px;
//  margin-bottom: 7px;
//  padding: 10px 10px 28px;
//  display: inline-block;
//  border: 0;
//}

::v-deep .el-select__tags {
  max-width: 100% !important;
}

.title-first {
  font-size: 28px;
  font-weight: bold;
  margin-top: 30px;
}

::v-deep .table-pad .el-table th > .cell {
  //padding: 0 2px;
  //font-size: 9px;
}
::v-deep .table-liyonglv .el-table .cell{
  padding: 0;
}

::v-deep .table-pad .el-table .cell {
  //padding: 0px 0;
}

.service {
  font-size: 18px;
  color: #000;
  padding-top: 15px;
  padding-bottom: 15px !important;
}

.echart-title-week {
  margin-top: 20px;
}

.title-three {
  font-size: 18px;
  font-weight: bold;
}

.title-three2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
}

.div-padding50 {
  padding: 50px 0 0;
}

.device-div .service {
  font-size: 18px;
}

.device-div {
  font-size: 18px;
}
.device-div .device-div-name{
  margin-top: 20px;
}
.device-div .div-padding {
  padding: 20px 0 0;
  font-size: 18px;
}

.device-divNumber:first-child .div-padding {
  padding: 10px 0 0;
  font-size: 18px;
}

.device-div .echarts-div {
  margin-bottom: 15px;
}

.div-margin {
  padding: 10px 0 0;
}

::v-deep .el-date-editor.el-input {
  width: 100%;
}

::v-deep .el-month-table td .cell:hover {
  color: #666666 !important;
}

.div-padding {
  padding: 30px 0 0;
}

.div-margin {
  padding: 30px 0 0;
}

.title-box2 {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 20px;

  img {
    width: 25px;
    margin-right: 5px;

  }
}

.title-box3 {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 20px;

  img {
    width: 25px;
    margin-right: 5px;

  }
}

.point {
  text-align: right;
  font-size: 10px;
  margin-bottom: 10px;
  color: #acabab;
}

::v-deep .el-table thead.is-group th.el-table__cell {
  background-color: #FADD8B;
}
::v-deep .el-table-PQES .el-table td {
  padding: 3px 0
}
</style>

